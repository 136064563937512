/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 目的ジャンル
 */
export enum MoodCategory {
    ACTIVE = 'active',
    EXPERIENCE = 'experience',
    SHOPPING = 'shopping',
    FOOD = 'food',
    RELAX = 'relax',
}
