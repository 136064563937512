/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 違反報告のステータス
 */
export enum ViolationReportStatus {
    OPENED = 'opened',
    CLOSED = 'closed',
    RECOVERED = 'recovered',
}
