import {
  Box,
  Button,
  Chip,
  Divider,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import { getJstFromUnixtime } from "../utils";

export type SpotItemRevisionListProps = {
  selectedRevisionId?: string;
  onSelectRevision?: (revisionId: string) => void;
  publishedRevisionId?: string;
  latestRevisionId?: string;
};

export const SpotItemRevisionList = (props: SpotItemRevisionListProps) => {
  const { spotId } = useParams();

  const {
    data: spotRevisions,
    isLoading: loading,
    mutate: reloadRevisions,
  } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItemRevisions",
    requestParameters: { spotId: spotId ?? "", limit: 100 },
  });

  const {
    data: dataDeleted,
    sender: deleteSpotRevision,
    isLoading: isDataDeleting,
    error: isDataDeleteError,
  } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "deleteSpotRevision",
  });

  useEffect(() => {
    if (props.selectedRevisionId !== undefined) return;
    if (spotRevisions === undefined) return;
    if (spotRevisions.items.length === 0) return;
    props.onSelectRevision &&
      props.onSelectRevision(spotRevisions.items[0]?.revision_id);
  }, [spotRevisions]);

  const handleDeleteSpotRevision = async (revisionId: string) => {
    if (window.confirm("本当に削除しますか？") === false) return;
    await deleteSpotRevision({ spotId: spotId ?? "", revisionId });
    await reloadRevisions();
    spotRevisions &&
      props.onSelectRevision &&
      props.onSelectRevision(
        spotRevisions.items.length > 0
          ? spotRevisions.items[0]?.revision_id
          : ""
      );
  };

  const [hoveredRevisionId, setHoveredRevisionId] = useState<string | null>(
    null
  );

  return (
    <>
      <Stack
        divider={<Divider />}
        border={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box p={1} bgcolor={(theme) => theme.palette.grey[100]}>
          <Typography>リビジョン一覧</Typography>
          {isDataDeleteError && (
            <Typography variant="body2" color="error">
              リビジョンの削除に失敗しました。（公開中のものは削除できません）
            </Typography>
          )}
        </Box>
        {loading && <Box p={2}>読み込み中...</Box>}
        {spotRevisions &&
          spotRevisions.items.length > 0 &&
          spotRevisions.items.map((revision, rkey) => (
            <Stack
              key={rkey}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              onClick={() =>
                props.onSelectRevision &&
                props.onSelectRevision(revision.revision_id)
              }
              onMouseEnter={() => setHoveredRevisionId(revision.revision_id)}
              onMouseLeave={() => setHoveredRevisionId(null)}
            >
              <Box>
                <Radio
                  onClick={() =>
                    props.onSelectRevision &&
                    props.onSelectRevision(revision.revision_id)
                  }
                  checked={props.selectedRevisionId === revision.revision_id}
                />
              </Box>
              <Stack py={1} width="100%">
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="body1" fontWeight="bold">
                    {revision.revision_id}
                  </Typography>
                  {revision.revision_id === props.publishedRevisionId && (
                    <Chip label="公開中" size="small" color="success" />
                  )}
                  {revision.revision_id === props.latestRevisionId && (
                    <Chip label="最新" size="small" color="info" />
                  )}
                </Stack>
                <Typography variant="body2">
                  {getJstFromUnixtime(revision.timestamp_created / 1000)}
                </Typography>
              </Stack>
              <Box
                p={1}
                sx={{
                  opacity: hoveredRevisionId == revision.revision_id ? 1 : 0,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => {
                    handleDeleteSpotRevision(revision.revision_id);
                  }}
                  disabled={isDataDeleting}
                >
                  削除
                </Button>
              </Box>
            </Stack>
          ))}
        {spotRevisions && spotRevisions.items.length === 0 && (
          <Box p={2}>リビジョンがありません</Box>
        )}
      </Stack>
    </>
  );
};
