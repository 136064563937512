import { atomWithStorage } from "jotai/utils";
import { NotificationItem } from "./generatedApi/notificationManagerApi";


export const callbackDraftPathAtom = atomWithStorage<string>("callbackDraftPath", "");
export const callbackDraftPageAtom = atomWithStorage<string>("callbackDraftPage", "");
export const callbackPublicPathAtom = atomWithStorage<string>("callbackPublicPath", "");
export const callbackPublicPageAtom = atomWithStorage<string>("callbackPublicPage", "");
export const callbackTabAtom = atomWithStorage<string>("callbackTab", "");

export const editingNotificationItemAtom = atomWithStorage<NotificationItem | null>("notificationItem", null);

type SpotItemAtomType = {
    id: string;
    jsonStrinify: string;
};
export const editingSpotItemAtom = atomWithStorage<SpotItemAtomType | null>("spotItem", null);
