import { Person, Place as PlaceIcon } from "@mui/icons-material";
import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { targetEnv } from "../api";

const categories = [
  {
    id: "スポット管理",
    children: [
      {
        id: "下書き",
        path: "/spots",
        icon: <PlaceIcon />,
        active: true,
      },
      {
        id: "公開済",
        path: "/spots/public",
        icon: <PlaceIcon />,
        active: true,
      },
    ],
  },
  {
    id: "お知らせ管理",
    children: [
      {
        id: "お知らせ一覧",
        path: "/notifications",
        icon: <PlaceIcon />,
        active: true,
      },
    ],
  },
  {
    id: "お問い合わせ管理",
    children: [
      {
        id: "お問い合わせ一覧",
        path: "/inquiries",
        icon: <PlaceIcon />,
        active: true,
      },
    ],
  },
  {
    id: "ユーザ管理",
    children: [
      {
        id: "ユーザ一覧",
        path: "/users",
        icon: <Person />,
        active: true,
      },
    ],
  },
  {
    id: "ユーザリクエスト管理",
    children: [
      {
        id: "リクエスト一覧",
        path: "/user_requests",
        icon: <Person />,
        active: true,
      },
    ],
  },
  {
    id: "推薦系モックアップ管理",
    children: [
      {
        id: "モックアップ",
        path: "/recommendation_mockup",
        icon: <Person />,
        active: true,
      },
    ],
  },
  {
    id: "ユーザ行動記録",
    children: [
      {
        id: "行動記録一覧",
        path: "/interactions",
        icon: <Person />,
        active: true,
      },
    ],
  },
  {
    id: "ジョブ管理",
    children: [
      {
        id: "ジョブ一覧",
        path: "/user_data_importer",
        icon: <Person />,
        active: true,
      },
      {
        id: "登録",
        path: "/user_data_importer/upload",
        icon: <Person />,
        active: true,
      },
    ],
  },
  {
    id: "メディア管理",
    children: [
      {
        id: "メディア一覧",
        path: "/media",
        icon: <Person />,
        active: true,
      },
      {
        id: "登録",
        path: "/media/upload",
        icon: <Person />,
        active: true,
      },
    ],
  },
  {
    id: "クチコミ管理",
    children: [
      {
        id: "違反報告一覧",
        path: "/violation_reports",
        icon: <Person />,
        active: true,
      },
    ],
  },
];

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export const Navigator = (props: DrawerProps) => {
  const { ...other } = props;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          <ListItemButton
            onClick={() => navigate("/")}
            sx={{ p: 0, m: 0 }}
            disableRipple
          >
            Refloop &nbsp;
            <Chip
              label={targetEnv}
              variant="outlined"
              size="small"
              sx={{ color: "#fff", borderColor: "#fff" }}
            />
          </ListItemButton>
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "rgba(255, 255, 255, 0.75)" }}>
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, path, icon, active }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton
                  selected={location.pathname === path}
                  sx={item}
                  onClick={() => navigate(path)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText sx={{ "* span": { color: "#fff" } }}>
                    {childId}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
