/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * インタラクションのタイプを表現する列挙型クラスです。
 */
export enum InteractionType {
    VIEWED = 'viewed',
    SAVED = 'saved',
    DISLIKED = 'disliked',
    PINNED = 'pinned',
    VISITED = 'visited',
    SHARED = 'shared',
    IMPORTED = 'imported',
}
