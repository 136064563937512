import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import Template from "./Template";
import RecommendationMockupListTab from "./RecommendationMockupListTab";
import { MoodCategory, RecommendationResult } from "../generatedApi/spotSearchEngineApi";

const mood_category = [
  { title: "運動（公園、テーマパーク、スポーツなど）", value: MoodCategory.ACTIVE },
  { title: "見る学ぶ体験（観光地、動物園、神社など）", value: MoodCategory.EXPERIENCE },
  { title: "買う（ショッピングモール）", value: MoodCategory.SHOPPING },
  { title: "食べる（食全般）", value: MoodCategory.FOOD },
  { title: "癒し（銭湯、サウナ、ホテル、自炊型施設）", value: MoodCategory.RELAX },
]

// 47都道府県名のリスト
const prefectureList = [
  // "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県",
  // "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県",
  // "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県",
  "岐阜県", "愛知県", "三重県", "静岡県",
  // "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県",
  // "鳥取県", "島根県", "岡山県", "広島県", "山口県",
  // "徳島県", "香川県", "愛媛県", "高知県",
  // "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"
];


const RecommendationMockManagerPage = () => {
  // デフォルトを管理者用のID("refloop_1705403019672")に設定
  const [userId, setUserId] = useState<string | null>("refloop_1705403019672");

  const [postalCode, setPostalCode] = useState<string | null>(null);
  const [queryPrefecture, setQueryPrefecture] = useState<Array<string> | null>(null);
  const [queryMoodCategory, setQueryMoodCategory] = useState<Array<MoodCategory> | null>(null);

  // utilProviderのAPIを利用して、郵便番号から住所を取得する
  const {
    data: location,
    isLoading: locationLoading,
    error: locationError,
    mutate: reload
  } = useAdminFetcherWithSwr({
    apiName: "util_provider",
    serviceName: "default",
    operationId: "getLocation",
    requestParameters: { postalCode: postalCode ?? "" },
  });


  const [recommendation, setRecommendation] = useState<RecommendationResult | null>(null);
  const { sender: getRecommend, isLoading } = useAdminSender({
    apiName: "spot_search_engine",
    serviceName: "default",
    operationId: "getRecommendation",
  });

  const handleSubmit = async () => {
    let res: RecommendationResult | undefined = undefined;
    res = await getRecommend({
      requestBody: {
        user_id: userId, location: location,
        address_pref: queryPrefecture, mood_category: queryMoodCategory
      }
    });
    if (res) setRecommendation(res);
  };

  const getMoreRecommend = async () => {
    let res: RecommendationResult | undefined = undefined;
    res = await getRecommend({
      requestBody: {
        user_id: userId, location: location,
        address_pref: queryPrefecture, mood_category: queryMoodCategory
      },
      recommendationId: recommendation?.recommendation_id
    });
  if (!recommendation
  ) {
    return;
  }
  let totalResult: RecommendationResult | undefined = recommendation;
  if (res) {
    totalResult = {
      ...recommendation,
      results: [...recommendation.results, ...res.results],
    };
    setRecommendation(totalResult);
  }
}

return (
  <Template title="推薦系モックアップ">
    <AppBar
      component="div"
      position="static"
      elevation={0}
      sx={{ zIndex: 0 }}
    >
      <Tabs value={"mockup"} textColor="inherit">
        <Tab label="モックアップ" onClick={() => { }} value="mockup" />
      </Tabs>
    </AppBar>
    <Box component="main" sx={{ flex: 1, px: 2, bgcolor: "#eaeff1" }}>
      <Stack height="100%" direction="row" spacing={2} pt={2}>
        <Stack width={400} spacing={1}>
          <Stack border={(theme) => `1px solid ${theme.palette.divider}`}>
            <Stack
              p={1}
              bgcolor={(theme) => theme.palette.grey[100]}
              spacing={1}
              divider={<Divider />}
            >
              <Stack p={1} spacing={1}>
                <Typography>推薦ユーザ</Typography>
              </Stack>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Stack p={1} spacing={1}>
                  <TextField
                    label="ID"
                    placeholder={`user_id を入力...`}
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    disabled={isLoading}
                  />
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      disabled={isLoading || locationLoading}
                    >
                      推薦を開始
                    </Button>
                  </Box>
                </Stack>
              </form>
              <Stack p={1} spacing={1}>
                <TextField
                  label="郵便番号"
                  placeholder={`郵便番号 を入力...`}
                  value={postalCode}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    // 数字のみかつ7桁以内の場合のみ更新
                    if (/^\d{0,7}$/.test(newValue)) {
                      setPostalCode(newValue);
                    }
                  }}
                  inputProps={{
                    maxLength: 7, // 最大7文字
                    inputMode: 'numeric', // モバイルデバイスで数字キーボードを表示
                  }}
                />
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={prefectureList}
                  size="small"
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  sx={{ pt: 1 }}
                  onChange={(e, v) => {
                    setQueryPrefecture(v.map((pref) => pref));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="都道府県"
                      placeholder="都道府県を選択..."
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={mood_category}
                  size="small"
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  sx={{ pt: 1 }}
                  onChange={(e, v) => {
                    setQueryMoodCategory(v.map((mood) => mood.value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="目的ジャンル"
                      placeholder="ジャンルを選択..."
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack width="100%">
          <Stack
            border={(theme) => `1px solid ${theme.palette.divider}`}
            bgcolor={(theme) => theme.palette.background.default}
          >
            {!recommendation && "推薦を開始ボタンを押してください"}
            {recommendation && (
              <Box>
                <RecommendationMockupListTab
                  RecommendationItems={recommendation.results}
                  getMoreRecommend={getMoreRecommend}
                  isLoading
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  </Template>
);
};

export default RecommendationMockManagerPage;
