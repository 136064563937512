const config = {
  Auth: {
    region: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:81e507f7-b5f0-4a97-81b8-ea1e7f0ed5b6',
    userPoolId: 'ap-northeast-1_sHx418kiR',
    userPoolWebClientId: '7762punpv76mfdepp2gibfm8op',
  },
    Storage: {
      AWSS3: {
        region: "ap-northeast-1",
      },
    },
  };

  export default config;
