/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SpotItemMetadata } from '../models/SpotItemMetadata';
import type { SpotItemMetadataCreate } from '../models/SpotItemMetadataCreate';
import type { SpotItemMetadataQueryResponse } from '../models/SpotItemMetadataQueryResponse';
import type { SpotItemRevision } from '../models/SpotItemRevision';
import type { SpotItemRevisionCreate } from '../models/SpotItemRevisionCreate';
import type { SpotItemRevisionQueryResponse } from '../models/SpotItemRevisionQueryResponse';
import type { ValidationErrorItem } from '../models/ValidationErrorItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Openapi Json
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOpenapiJson(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/openapi.json',
        });
    }

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * スポットを新規作成します。
     * 同じスポットIDが存在する場合は、409を返します。
     * @returns SpotItemMetadata Successful Response
     * @throws ApiError
     */
    public createSpotItem({
        requestBody,
    }: {
        requestBody: SpotItemMetadataCreate,
    }): CancelablePromise<SpotItemMetadata> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/spots',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットの一覧を返します。
     * @returns SpotItemMetadataQueryResponse Successful Response
     * @throws ApiError
     */
    public getSpotItems({
        limit = 100,
        lastEvaluatedKey,
    }: {
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<SpotItemMetadataQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots',
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットを取得します。
     * @returns SpotItemMetadata Successful Response
     * @throws ApiError
     */
    public getSpotItem({
        spotId,
    }: {
        spotId: string,
    }): CancelablePromise<SpotItemMetadata> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots/{spot_id}',
            path: {
                'spot_id': spotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットを削除します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteSpotItem({
        spotId,
    }: {
        spotId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/spots/{spot_id}',
            path: {
                'spot_id': spotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリビジョンを作成します。
     * @returns SpotItemRevision Successful Response
     * @throws ApiError
     */
    public createSpotRevision({
        spotId,
        requestBody,
    }: {
        spotId: string,
        requestBody: SpotItemRevisionCreate,
    }): CancelablePromise<SpotItemRevision> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/spots/{spot_id}/revisions',
            path: {
                'spot_id': spotId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリビジョンの一覧を返します。
     * @returns SpotItemRevisionQueryResponse Successful Response
     * @throws ApiError
     */
    public getSpotItemRevisions({
        spotId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        spotId: string,
        limit?: number,
        lastEvaluatedKey?: string,
    }): CancelablePromise<SpotItemRevisionQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots/{spot_id}/revisions',
            path: {
                'spot_id': spotId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリビジョンをチェックします。
     * @returns any Successful Response
     * @throws ApiError
     */
    public validateSpotRevision({
        requestBody,
    }: {
        requestBody: any,
    }): CancelablePromise<(Array<ValidationErrorItem> | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/utils/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリビジョンを返します。
     * @returns SpotItemRevision Successful Response
     * @throws ApiError
     */
    public getSpotItemRevision({
        spotId,
        revisionId,
    }: {
        spotId: string,
        revisionId: string,
    }): CancelablePromise<SpotItemRevision> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots/{spot_id}/revisions/{revision_id}',
            path: {
                'spot_id': spotId,
                'revision_id': revisionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリビジョンを削除します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteSpotRevision({
        spotId,
        revisionId,
    }: {
        spotId: string,
        revisionId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/spots/{spot_id}/revisions/{revision_id}',
            path: {
                'spot_id': spotId,
                'revision_id': revisionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリビジョンを公開します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public publishSpotRevision({
        spotId,
        revisionId,
    }: {
        spotId: string,
        revisionId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/spots/{spot_id}/revisions/{revision_id}/publish',
            path: {
                'spot_id': spotId,
                'revision_id': revisionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリビジョンを非公開にします。
     * @returns any Successful Response
     * @throws ApiError
     */
    public unpublishSpotRevision({
        spotId,
        revisionId,
    }: {
        spotId: string,
        revisionId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/spots/{spot_id}/revisions/{revision_id}/unpublish',
            path: {
                'spot_id': spotId,
                'revision_id': revisionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
