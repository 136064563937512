import { useAuthenticator } from "@aws-amplify/ui-react";
import { ExitToApp as ExitToAppIcon } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Chip, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import * as React from "react";

const lightColor = "rgba(255, 255, 255, 0.7)";

interface HeaderProps {
  onDrawerToggle: () => void;
  title?: string | undefined;
}

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;
  const { user, signOut } = useAuthenticator();
  const [email, setEmail] = React.useState<string | undefined>();

  const getEmail = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const { email } = currentUser.attributes;
      setEmail(email);
    } catch (error) {
      console.error("Error getting user email:", error);
    }
  };

  React.useEffect(() => {
    getEmail();
  }, [user]);

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Chip id='template-header-email' label={email} />
            </Grid>
            <Grid item sx={{ml: 1}}>
              <Tooltip title='ログアウト'>
                <IconButton
                  color="inherit"
                  size="small"
                  aria-label="sign out"
                  onClick={signOut}
                  edge="start"
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {props.title}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
