/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportJobType } from '../models/ImportJobType';
import type { UserDataUploadReference } from '../models/UserDataUploadReference';
import type { UserImportJob } from '../models/UserImportJob';
import type { UserImportJobQueryResponse } from '../models/UserImportJobQueryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * ファイルのアップロード先を作成します。
     * アップロード先のS3情報を返します。その情報を元にS3クライアントを使ってファイルをアップロードします。
     * @returns UserDataUploadReference Successful Response
     * @throws ApiError
     */
    public prepareDataUpload({
        userId,
        jobType,
        requestBody,
    }: {
        userId: string,
        jobType: ImportJobType,
        requestBody: Array<string>,
    }): CancelablePromise<Array<UserDataUploadReference>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{user_id}/prepare',
            path: {
                'user_id': userId,
            },
            query: {
                'job_type': jobType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザに紐づくインポートジョブの一覧を返します。
     * 確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns UserImportJobQueryResponse Successful Response
     * @throws ApiError
     */
    public getUserImportJobsByUserId({
        userId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        userId: string,
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<UserImportJobQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/jobs',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザに紐づくインポートジョブの詳細を返します。
     * @returns UserImportJob Successful Response
     * @throws ApiError
     */
    public getUserImportJob({
        userId,
        jobId,
    }: {
        userId: string,
        jobId: string,
    }): CancelablePromise<UserImportJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/jobs/{job_id}',
            path: {
                'user_id': userId,
                'job_id': jobId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザに紐づくインポートジョブの結果URLを返します。
     * @returns string Successful Response
     * @throws ApiError
     */
    public getUserImportJobArtifact({
        userId,
        jobId,
    }: {
        userId: string,
        jobId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/jobs/{job_id}/artifact',
            path: {
                'user_id': userId,
                'job_id': jobId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 月毎のインポートジョブの一覧を返します。
     * @returns UserImportJobQueryResponse Successful Response
     * @throws ApiError
     */
    public getUserImportJobsByMonthId({
        monthId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        monthId?: (string | null),
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<UserImportJobQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/jobs',
            query: {
                'month_id': monthId,
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * インポートジョブの詳細を返します。
     * @returns UserImportJob Successful Response
     * @throws ApiError
     */
    public adminGetUserImportJob({
        jobId,
    }: {
        jobId: string,
    }): CancelablePromise<UserImportJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/jobs/{job_id}',
            path: {
                'job_id': jobId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * インポートジョブを削除します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteUserImportJob({
        jobId,
    }: {
        jobId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/jobs/{job_id}',
            path: {
                'job_id': jobId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
