/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationItem } from '../models/NotificationItem';
import type { NotificationItemCreate } from '../models/NotificationItemCreate';
import type { NotificationStatus } from '../models/NotificationStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * お知らせを作成します。
     * @returns NotificationItem Successful Response
     * @throws ApiError
     */
    public createAdminNotificationItem({
        requestBody,
    }: {
        requestBody: NotificationItemCreate,
    }): CancelablePromise<NotificationItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/notifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お知らせを更新します。
     * @returns NotificationItem Successful Response
     * @throws ApiError
     */
    public updateNotificationItem({
        notificationId,
        requestBody,
    }: {
        notificationId: string,
        requestBody: NotificationItemCreate,
    }): CancelablePromise<NotificationItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/notifications/{notification_id}',
            path: {
                'notification_id': notificationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お知らせを削除します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteAdminNotificationItem({
        notificationId,
    }: {
        notificationId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/notifications/{notification_id}',
            path: {
                'notification_id': notificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お知らせのステータスを変更します。
     * @returns NotificationItem Successful Response
     * @throws ApiError
     */
    public updateAdminNotificationItemStatus({
        notificationId,
        status,
    }: {
        notificationId: string,
        status: NotificationStatus,
    }): CancelablePromise<NotificationItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/notifications/{notification_id}/status/{status}',
            path: {
                'notification_id': notificationId,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
