import { AppBar, Box, Tab, Tabs } from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { JumboDrawer } from "./JumboDrawer";
import SpotItemCreatePanel from "./SpotItemCreatePanel";
import SpotItemDetailPanel from "./SpotItemDetailPanel";
import { SpotItemPageDraftTab } from "./SpotItemPageDraftTab";
import { SpotITemPagePublicTab } from "./SpotItemPagePublicTab";
import Template from "./Template";
import { callbackDraftPathAtom, callbackPublicPathAtom, callbackTabAtom } from "../atom";
import { useState } from "react";
import { useAtom } from "jotai/react";

const SpotItemPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { spotId } = useParams();

  const [callbackDraftPath] = useAtom(callbackDraftPathAtom);
  const [callbackPublicPath] = useAtom(callbackPublicPathAtom);
  const [callbackTab] = useAtom(callbackTabAtom);

  const isCreatePage = location.pathname
    .split("/")
    .slice(-1)
    .includes("create");

  const isPublicPage = location.pathname
    .split("/")
    .slice(-1)
    .includes("public");

  const isDraftPage = location.pathname.split("/").slice(-1).includes("spots");

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <Template title="スポット管理">
        <AppBar
          component="div"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Tabs
            value={isDraftPage ? "draft" : isPublicPage ? "public" : ""}
            textColor="inherit"
            aria-label="spot item page tabs"
          >
            <Tab
              label="下書き"
              onClick={() => {
                if (callbackDraftPath !== "") {
                  navigate(`/spots?${callbackDraftPath}`);
                  return;
                }
                navigate("/spots")
              }}
              value="draft"
            />
            <Tab
              label="公開済"
              onClick={() => {
                if (callbackPublicPath !== "") {
                  navigate(`/spots/public?${callbackPublicPath}`);
                  return;
                }
                navigate("/spots/public")
              }}
              value="public"
            />
          </Tabs>
        </AppBar>
        <Box component="main" sx={{ flex: 1, px: 2, bgcolor: "#eaeff1" }}>
          {isDraftPage && (
            <SpotItemPageDraftTab />
          )}
          {isPublicPage && (
            <SpotITemPagePublicTab />
          )}
        </Box>
      </Template>
      <JumboDrawer
        open={isCreatePage}
        anchor="right"
        onClose={() => navigate("/spots")}
      >
        <Box p={2}>
          <SpotItemCreatePanel />
        </Box>
      </JumboDrawer>
      <JumboDrawer
        open={spotId !== undefined}
        anchor="right"
        onClose={() => {
          if (callbackTab === "draft") {
            navigate(`/spots?${callbackDraftPath}`);
            return;
          }
          if (callbackTab === "public") {
            navigate(`/spots/public?${callbackPublicPath}`);
            return;
          }
          navigate("/spots");
        }}
      >
        <Box>
          <SpotItemDetailPanel isEditing={isEditing} setIsEditing={setIsEditing} />
        </Box>
      </JumboDrawer>
    </>
  );
};

export default SpotItemPage;
