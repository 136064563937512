import { Check } from "@mui/icons-material";
import { Box, Button, Chip, Stack, TextField, Typography } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { useMemo, useState } from "react";
import ReactJson from "react-json-view";
import { Link, useParams } from "react-router-dom";
import { useAdminSender } from "../api";
import { InteractionType } from "../generatedApi/userUserInteractionLoggerApi";

export const SpotItemInteractionsTab = () => {
  const { spotId } = useParams();

  const {
    sender: createInteractionItem,
    data,
    isLoading,
  } = useAdminSender({
    apiName: "user_interaction_logger",
    serviceName: "default",
    operationId: "createInteractionItem",
  });
  const [queryInteractionType, setQueryInteractionType] =
    useState<InteractionType>(InteractionType.VIEWED);

  const [queryUserId, setQueryUserId] = useState<string>("admin_user");
  const debouncedQueryUserId = useDebounce(queryUserId, 500);

  const queryInteraction = useMemo(() => {
    return {
      spot_id: spotId,
      interaction_type: queryInteractionType,
      user_id: debouncedQueryUserId,
    };
  }, [queryInteractionType, debouncedQueryUserId]);

  return (
    <>
      <Stack spacing={1}>
        <Typography>インタラクションタイプ</Typography>
        <Stack spacing={1} direction="row">
          {Object.values(InteractionType).map((interactionType, key) => (
            <Chip
              key={key}
              variant={
                queryInteractionType === interactionType ? "filled" : "outlined"
              }
              label={interactionType}
              onClick={() => setQueryInteractionType(interactionType)}
              icon={
                queryInteractionType === interactionType ? <Check /> : <></>
              }
            />
          ))}
        </Stack>
        <Typography>user_id</Typography>
        <TextField
          value={queryUserId}
          onChange={(e) => setQueryUserId(e.target.value)}
        />
        <Typography>送信する値</Typography>
        <ReactJson src={queryInteraction} />
        <Box>
          <Button
            variant="contained"
            onClick={() =>
              createInteractionItem({ requestBody: queryInteraction })
            }
            disabled={isLoading}
          >
            行動記録を作成
          </Button>
        </Box>
        {data && (
          <Typography>
            <Link
              to={`/interactions?partition=spot_id&id=${spotId}`}
              target="_blank"
            >
              行動記録
            </Link>
            を作成しました
          </Typography>
        )}
      </Stack>
    </>
  );
};
