import { Launch } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import { getJstFromUnixtime } from "../utils";
import MediaUploadTab from "./MediaUploadTab";
import { SpotItemEditTab } from "./SpotItemEditTab";
import { SpotItemInteractionsTab } from "./SpotItemInteractionsTab";
import { SpotItemMediaTab } from "./SpotItemMediaTab";
import { SpotRevisionTab } from "./SpotRevisionTab";
import { Dispatch, SetStateAction } from "react";

type SpotItemDetailPanelProps = {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
};
const SpotItemDetailPanel = (props: SpotItemDetailPanelProps) => {
  const location = useLocation();
  const { spotId } = useParams();
  const tab = location.pathname.split("/").slice(3)[0] ?? "revisions";
  const navigate = useNavigate();

  const {
    data: spotMetadata,
    mutate: reloadSpotMetadata,
    error: spotMetadataError,
    isLoading,
  } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItem",
    requestParameters: { spotId: spotId ?? "" },
  });

  const {
    data: dataDeleted,
    sender: deleteSpotItem,
    isLoading: isDataDeleting,
    error: isDataDeleteError,
  } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "deleteSpotItem",
  });

  const handleSpotItemDelete = async () => {
    if (window.confirm("本当に削除しますか？") === false) return;
    await deleteSpotItem({ spotId: spotId ?? "" });
    reloadSpotMetadata();
  };

  return (
    <>
      {!isLoading && !spotMetadata && (
        <Box p={2}>スポットがみつかりません。</Box>
      )}
      <Stack p={2} spacing={1} direction="row" justifyContent="space-between">
        <Stack>
          <Box>
            {spotMetadata ? (
              <Typography variant="h5">{spotMetadata.name}</Typography>
            ) : (
              <Skeleton variant="text" width={200} />
            )}
          </Box>
          <Box>
            {spotMetadata ? (
              <Stack
                direction="row"
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
                flexWrap="wrap"
              >
                <Typography variant="body1">
                  {spotMetadata.published_revision_id
                    ? `🟢 公開中(${spotMetadata.published_revision_id})`
                    : "🔴 公開停止中"}
                </Typography>
                <Typography variant="body1">
                  {!spotMetadata.latest_revision_id
                    ? "-"
                    : spotMetadata.has_unpublished_changes
                    ? "⚠️ 未公開の更新あり"
                    : "✅ 最新を公開済み"}
                </Typography>
                <Typography variant="body1">
                  {spotMetadata.spot_id &&
                    `スポットID: ${spotMetadata.spot_id}`}
                </Typography>
                <Typography variant="body1">
                  {spotMetadata.latest_revision_id
                    ? `最新リビジョンID: ${spotMetadata.latest_revision_id}`
                    : "リビジョン未作成"}
                </Typography>
                <Typography variant="body1">
                  作成日時:
                  {getJstFromUnixtime(spotMetadata.timestamp_created / 1000)}
                </Typography>
              </Stack>
            ) : (
              <Skeleton variant="text" width={200} />
            )}
          </Box>
        </Stack>
        <Box>
          <Stack textAlign="right" direction="row" spacing={1} flexWrap="wrap">
            <Button
              variant="contained"
              startIcon={<Launch />}
              component={Link}
              to={`/user_requests?partition=spot_id&id=${spotId}`}
              target="_blank"
            >
              ユーザリクエスト
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => handleSpotItemDelete()}
              disabled={isDataDeleting}
            >
              削除
            </Button>
            {isDataDeleteError && (
              <Typography>
                削除に失敗しました(リビジョンが残っている場合は削除できません。)
              </Typography>
            )}
          </Stack>
        </Box>
      </Stack>
      <Box borderBottom={1} borderColor="divider">
        <Tabs
          value={tab}
          onChange={(e, newValue: string) => {
            if (!spotId) return;
            navigate(`/spots/${spotId}/${newValue}`);
          }}
        >
          <Tab label="リビジョン" value="revisions" />
          <Tab label="編集" value="edit" />
          <Tab label="行動記録" value="interactions" />
          <Tab label="メディア" value="media" />
          <Tab label="メディア登録" value="media_upload" />
        </Tabs>
      </Box>
      <Box p={2} height="100%">
        {tab === "revisions" && <SpotRevisionTab />}
        {tab === "edit" && <SpotItemEditTab isEditing={props.isEditing} setIsEditing={props.setIsEditing} />}
        {tab === "interactions" && <SpotItemInteractionsTab />}
        {tab === "media" && <SpotItemMediaTab />}
        {tab === "media_upload" && <MediaUploadTab />}
      </Box>
    </>
  );
};

export default SpotItemDetailPanel;
