import { ArrowDropDown, Check } from "@mui/icons-material";
import FlagIcon from "@mui/icons-material/Flag";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminSender } from "../api";
import { MediaItem } from "../generatedApi/mediaManagerApi";
import { MediaStatusJapanese } from "./MediaManagerPage";

type MediaItemCardProps = {
  mediaItem: MediaItem;
  handleSubmit: () => void;
  isLoading: boolean;
  selectionMedia: MediaItem[];
  setSelectionMedia: (mediaItem: MediaItem[]) => void;
};
const MediaItemCard = (props: MediaItemCardProps) => {
  const { sender: reportMedia } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "reportMediaItem",
  });
  const reportMediaItem = async () => {
    await reportMedia({
      mediaId: props.mediaItem.media_id,
      reason: reportReason,
    });
    await props.handleSubmit();
  };
  const [reportReason, setReportReason] = useState<string>("");
  const [reportOpen, setReportOpen] = useState(false);
  const reportHandleClickOpen = () => {
    setReportOpen(true);
  };
  const reportHandleClose = () => {
    setReportOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { sender: restoreMedia } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "restoreMediaItem",
  });
  const restoreMediaItem = async () => {
    const res = await restoreMedia({
      mediaId: props.mediaItem.media_id,
      reason: restoreReason,
    });
    await props.handleSubmit();
  };
  const [restoreReason, setRestoreReason] = useState<string>("");
  const [restoreOpen, setRestoreOpen] = useState(false);
  const restoreHandleClickOpen = () => {
    setRestoreOpen(true);
  };
  const restoreHandleClose = () => {
    setRestoreOpen(false);
  };

  const { sender: deleteMedia } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "deleteMediaItem",
  });
  const deleteMediaItem = async () => {
    await deleteMedia({
      mediaId: props.mediaItem.media_id,
      reason: deleteReason,
    });
    await props.handleSubmit();
  };
  const [deleteReason, setDeleteReason] = useState<string>("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const deleteHandleClickOpen = () => {
    setDeleteOpen(true);
  };
  const deleteHandleClose = () => {
    setDeleteOpen(false);
  };

  const { sender: archiveMedia } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "archiveMediaItem",
  });
  const archiveMediaItem = async () => {
    const res = await archiveMedia({
      mediaId: props.mediaItem.media_id,
      reason: archiveReason,
    });
    await props.handleSubmit();
  };
  const [archiveReason, setArchiveReason] = useState<string>("");
  const [archiveOpen, setArchiveOpen] = useState(false);
  const archiveHandleClickOpen = () => {
    setArchiveOpen(true);
  };
  const archiveHandleClose = () => {
    setArchiveOpen(false);
  };

  const { sender: banMedia } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "banMediaItem",
  });
  const banMediaItem = async () => {
    const res = await banMedia({
      mediaId: props.mediaItem.media_id,
      reason: banReason,
    });
    await props.handleSubmit();
  };
  const [banReason, setBanReason] = useState<string>("");
  const [banOpen, setBanOpen] = useState(false);
  const banHandleClickOpen = () => {
    setBanOpen(true);
  };
  const banHandleClose = () => {
    setBanOpen(false);
  };

  const [note, setNote] = useState<string>(props.mediaItem.note ?? "");
  const [citeUrl, setCiteUrl] = useState<string>(
    props.mediaItem.cite_url ?? ""
  );
  const { sender: updateNote } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "updateMediaNote",
  });
  const updateMediaNote = async () => {
    if (!window.confirm("本当にキャプションを更新しますか?")) return;
    await updateNote({
      mediaId: props.mediaItem.media_id,
      note: note,
      citeUrl: citeUrl,
    });
    await props.handleSubmit();
  };

  const addSelectMedia = (mediaItem: MediaItem) => {
    const newSelectionMedia = [...props.selectionMedia, mediaItem];
    props.setSelectionMedia(newSelectionMedia);
  };
  const removeSelectMedia = (mediaItem: MediaItem) => {
    const newSelectionMedia = props.selectionMedia.filter(
      (item) => item.media_id !== mediaItem.media_id
    );
    props.setSelectionMedia(newSelectionMedia);
  };

  const handleOnChanged = (mediaItem: MediaItem) => {
    if (
      props.selectionMedia.some((item) => item.media_id === mediaItem.media_id)
    ) {
      removeSelectMedia(mediaItem);
    } else {
      addSelectMedia(mediaItem);
    }
  };

  return (
    <Stack
      width={300}
      border={(theme) => `1px solid ${theme.palette.divider}`}
      p={1}
      spacing={1}
    >
      <Box>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Checkbox onChange={() => handleOnChanged(props.mediaItem)} />
        </Stack>
      </Box>
      <img
        src={props.mediaItem.sources ? props.mediaItem.sources["im480"] : ""}
      />
      <Stack direction="row" alignItems="center" spacing={1}>
        <Chip
          label={MediaStatusJapanese[props.mediaItem.status]}
          size="small"
        />
        <Typography>{props.mediaItem.filename}</Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={0.5}
        divider={<Divider flexItem orientation="vertical" />}
        flexWrap="wrap"
      >
        {props.mediaItem.sources &&
          Object.keys(props.mediaItem.sources).map((key, skey) => (
            <a
              href={props.mediaItem.sources ? props.mediaItem.sources[key] : ""}
              target="_blank"
              key={skey}
            >
              {key}
            </a>
          ))}
      </Stack>
      <Box>
        {props.mediaItem.media_id && (
          <Typography variant="body2">
            メディアID: {props.mediaItem.media_id}
          </Typography>
        )}
        {props.mediaItem.user_id && (
          <Typography variant="body2">
            ユーザID:{" "}
            <Link target="_blank" to={`/users/${props.mediaItem.user_id}`}>
              {props.mediaItem.user_id}
            </Link>
          </Typography>
        )}
        {props.mediaItem.spot_id && (
          <Typography variant="body2">
            スポットID:{" "}
            <Link target="_blank" to={`/spots/${props.mediaItem.spot_id}`}>
              {props.mediaItem.spot_id}
            </Link>
          </Typography>
        )}
      </Box>
      <Stack spacing={0.5}>
        {props.mediaItem.note && (
          <Typography variant="body2" pb={1}>
            キャプション:{" "}
            {props.mediaItem.cite_url ? (
              <a
                href={props.mediaItem.cite_url}
                target="_blank"
                key={props.mediaItem.cite_url}
              >
                {props.mediaItem.note}
              </a>
            ) : (
              props.mediaItem.note
            )}
          </Typography>
        )}
        <TextField
          label="キャプション"
          placeholder={`キャプションを入力...`}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          size="small"
        />
        {props.mediaItem.cite_url && (
          <Typography variant="body2" pb={1}>
            引用元URL: {props.mediaItem.cite_url}
          </Typography>
        )}
        <TextField
          label="引用元URL"
          placeholder={`引用元URLを入力...`}
          value={citeUrl}
          onChange={(e) => setCiteUrl(e.target.value)}
          size="small"
        />
      </Stack>
      <Box>
        <Button
          variant="contained"
          size="small"
          disabled={props.isLoading}
          onClick={updateMediaNote}
        >
          クレジット情報を更新
        </Button>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Tooltip
          title={
            props.mediaItem.status === "deleted"
              ? "削除されています"
              : "" || props.mediaItem.status === "reported"
              ? "報告済みです"
              : "" || props.mediaItem.status !== "ready"
              ? "報告できるのは公開状態の物のみです"
              : ""
          }
        >
          <div>
            <Button
              startIcon={<FlagIcon />}
              variant="contained"
              size="small"
              disabled={props.mediaItem.status !== "ready"}
              onClick={reportHandleClickOpen}
            >
              報告
            </Button>
            <Dialog
              open={reportOpen}
              onClose={reportHandleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle id="alert-dialog-title">
                {"本当に報告しますか?"}
              </DialogTitle>
              <DialogContent>
                <Typography>報告理由を入力してください</Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="報告理由"
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    setReportReason(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={reportHandleClose} variant="outlined">
                  キャンセル
                </Button>
                <Button
                  onClick={() => {
                    reportMediaItem();
                    reportHandleClose();
                  }}
                  variant="contained"
                  autoFocus
                >
                  報告
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Tooltip>
        <Tooltip
          title={props.mediaItem.status === "deleted" ? "削除されています" : ""}
        >
          <div>
            <ButtonGroup variant="contained" color="error">
              <Button
                variant="contained"
                size="small"
                color="error"
                disabled={
                  props.mediaItem.status === "deleted" ||
                  props.mediaItem.status === "prepared" ||
                  props.mediaItem.status === "processing"
                }
                onClick={deleteHandleClickOpen}
              >
                削除
              </Button>
              <Button
                size="small"
                color="error"
                disabled={props.mediaItem.status === "deleted"}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
          </div>
        </Tooltip>
        <Dialog
          open={deleteOpen}
          onClose={deleteHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            {"本当に削除しますか?"}
          </DialogTitle>
          <DialogContent>
            <Typography>削除理由を入力してください</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="削除理由"
              type="text"
              fullWidth
              onChange={(e) => {
                setDeleteReason(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteHandleClose} variant="outlined">
              キャンセル
            </Button>
            <Button
              onClick={() => {
                deleteMediaItem();
                deleteHandleClose();
              }}
              variant="contained"
              autoFocus
            >
              削除
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
        >
          <Tooltip
            title={
              props.mediaItem.status === "archived"
                ? "アーカイブされています"
                : ""
            }
          >
            <div>
              <MenuItem
                disabled={
                  props.mediaItem.status !== "ready" &&
                  props.mediaItem.status !== "reported" &&
                  props.mediaItem.status !== "banned"
                }
                onClick={archiveHandleClickOpen}
              >
                アーカイブ
              </MenuItem>
            </div>
          </Tooltip>
          <Dialog
            open={archiveOpen}
            onClose={archiveHandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title">
              {"本当にアーカイブしますか?"}
            </DialogTitle>
            <DialogContent>
              <Typography>アーカイブ理由を入力してください</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="アーカイブ理由"
                type="text"
                fullWidth
                onChange={(e) => {
                  setArchiveReason(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={archiveHandleClose} variant="outlined">
                キャンセル
              </Button>
              <Button
                onClick={() => {
                  archiveMediaItem();
                  archiveHandleClose();
                }}
                variant="contained"
                autoFocus
              >
                アーカイブ
              </Button>
            </DialogActions>
          </Dialog>
          <MenuItem
            disabled={
              props.mediaItem.status !== "ready" &&
              props.mediaItem.status !== "archived" &&
              props.mediaItem.status !== "reported"
            }
            onClick={banHandleClickOpen}
          >
            公開停止
          </MenuItem>
          <Dialog
            open={banOpen}
            onClose={banHandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title">
              {"本当に公開停止しますか?"}
            </DialogTitle>
            <DialogContent>
              <Typography>公開停止理由を入力してください</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="公開停止理由"
                type="text"
                fullWidth
                onChange={(e) => {
                  setBanReason(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={banHandleClose} variant="outlined">
                キャンセル
              </Button>
              <Button
                onClick={() => {
                  banMediaItem();
                  banHandleClose();
                }}
                variant="contained"
                autoFocus
              >
                公開停止
              </Button>
            </DialogActions>
          </Dialog>
        </Menu>
      </Stack>
      {props.mediaItem.status !== "ready" ? (
        <Alert severity="warning">
          <AlertTitle>{MediaStatusJapanese[props.mediaItem.status]}</AlertTitle>
          {props.mediaItem.status_change_note && (
            <>
              理由： <strong>{props.mediaItem.status_change_note}</strong>
            </>
          )}
        </Alert>
      ) : null}

      {props.mediaItem.status_change_note &&
      props.mediaItem.status === "ready" ? (
        <Alert severity="success">
          <AlertTitle>復旧済</AlertTitle>
          復旧理由： <strong>{props.mediaItem.status_change_note}</strong>
        </Alert>
      ) : null}

      {(props.mediaItem.status === "reported" ||
        props.mediaItem.status === "banned") && (
        <Stack direction="row" spacing={1}>
          <Button
            startIcon={<Check />}
            variant="contained"
            size="small"
            onClick={restoreHandleClickOpen}
          >
            復元
          </Button>
          <Dialog
            open={restoreOpen}
            onClose={restoreHandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title">
              {"本当に復元しますか?"}
            </DialogTitle>
            <DialogContent>
              <Typography>復元理由を入力してください</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="復元理由"
                type="text"
                fullWidth
                onChange={(e) => {
                  setRestoreReason(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={restoreHandleClose} variant="outlined">
                キャンセル
              </Button>
              <Button
                onClick={() => {
                  restoreMediaItem();
                  restoreHandleClose();
                }}
                variant="contained"
                autoFocus
              >
                復元
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      )}
    </Stack>
  );
};

type MediaListTabProps = {
  MediaItems: Array<MediaItem>;
  handleSubmit: () => void;
  isLoading: boolean;
};
export const MediaListTab = (props: MediaListTabProps) => {
  const { MediaItems, handleSubmit } = props;

  const [selectionMedia, setSelectionMedia] = useState<MediaItem[]>([]);
  const [bulkMode, setBulkMode] =
    useState<"report" | "restore" | "archive" | "ban" | "delete">("report");
  const bulkModeJapanese = {
    report: "一括報告",
    restore: "一括復元",
    archive: "一括アーカイブ",
    ban: "一括公開停止",
    delete: "一括削除",
  };
  const [bulkMenuOpen, setBulkMenuOpen] = useState(false);
  const bulkMenuHandleClickOpen = () => {
    setBulkMenuOpen(true);
  };
  const bulkMenuHandleClose = () => {
    setBulkMenuOpen(false);
  };
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [bulkOpen, setBulkOpen] = useState(false);
  const bulkHandleClickOpen = () => {
    setBulkOpen(true);
  };
  const bulkHandleClose = () => {
    setBulkOpen(false);
  };

  const [bulkResult, setBulkResult] = useState<
    Array<{
      mediaId: string;
      filename: string;
      result: boolean;
    }>
  >([]);

  const [bulkLength, setBulkLength] = useState<number>(0);
  const [bulkSuccessCount, setBulkSuccessCount] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number | null>(null);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const progressModalHandleClickOpen = () => {
    setProgressModalOpen(true);
  };
  const progressModalHandleClose = () => {
    setProgressModalOpen(false);
  };

  const { sender: reportMediaItem } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "reportMediaItem",
  });
  const { sender: restoreMediaItem } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "restoreMediaItem",
  });
  const { sender: archiveMediaItem } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "archiveMediaItem",
  });
  const { sender: banMediaItem } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "banMediaItem",
  });
  const { sender: deleteMediaItem } = useAdminSender({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "deleteMediaItem",
  });
  const onClickBulkOperation = async (
    mode: "report" | "restore" | "archive" | "ban" | "delete"
  ) => {
    if (!selectionMedia) return;
    setBulkLength(selectionMedia.length);
    setProgressValue(null);
    setBulkSuccessCount(0);
    let successCount = 0;
    let totalBulkResult = bulkResult;
    totalBulkResult = [];
    setBulkResult(totalBulkResult);
    let bulkSuccessCount = 0;
    for (let i = 0; i < selectionMedia.length; i++) {
      const mediaId = selectionMedia[i].media_id;
      const filename = selectionMedia[i].filename;

      let res: MediaItem | undefined = undefined;
      switch (mode) {
        case "report":
          res = await reportMediaItem({ mediaId: mediaId });
          break;
        case "archive":
          res = await archiveMediaItem({ mediaId: mediaId });
          break;
        case "ban":
          res = await banMediaItem({ mediaId: mediaId });
          break;
        case "restore":
          res = await restoreMediaItem({ mediaId: mediaId });
          break;
        case "delete":
          res = await deleteMediaItem({ mediaId: mediaId });
          break;
      }
      if (res === undefined) {
        totalBulkResult.unshift({
          mediaId: mediaId,
          filename: filename,
          result: false,
        });
        totalBulkResult = [...totalBulkResult];
        setBulkResult(totalBulkResult);
        setProgressValue(i + 1);
        continue;
      }
      totalBulkResult.unshift({
        mediaId: mediaId,
        filename: filename,
        result: true,
      });
      totalBulkResult = [...totalBulkResult];
      setBulkResult(totalBulkResult);
      setProgressValue(i + 1);
      successCount++;
    }
    setBulkSuccessCount(successCount);
  };

  return (
    <Stack
      spacing={1}
      border={(theme) => `1px solid ${theme.palette.divider}`}
      width="100%"
      height="100%"
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        p={1}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Button
          variant="contained"
          size="small"
          color="primary"
          endIcon={<ArrowDropDown />}
          disabled={
            selectionMedia?.length === 0 || selectionMedia === undefined
          }
          ref={anchorEl}
          onClick={bulkMenuHandleClickOpen}
        >
          一括操作
        </Button>
        <Menu
          id="bulk-menu"
          anchorEl={anchorEl.current}
          open={bulkMenuOpen}
          onClose={() => bulkMenuHandleClose()}
        >
          <MenuItem
            onClick={() => {
              setBulkMode("report");
              bulkMenuHandleClose();
              bulkHandleClickOpen();
            }}
          >
            一括報告
          </MenuItem>
          <MenuItem
            onClick={() => {
              setBulkMode("archive");
              bulkMenuHandleClose();
              bulkHandleClickOpen();
            }}
          >
            一括アーカイブ
          </MenuItem>
          <MenuItem
            onClick={() => {
              setBulkMode("ban");
              bulkMenuHandleClose();
              bulkHandleClickOpen();
            }}
          >
            一括公開停止
          </MenuItem>
          <MenuItem
            onClick={() => {
              setBulkMode("restore");
              bulkMenuHandleClose();
              bulkHandleClickOpen();
            }}
          >
            一括復元
          </MenuItem>
          <MenuItem
            onClick={() => {
              setBulkMode("delete");
              bulkMenuHandleClose();
              bulkHandleClickOpen();
            }}
          >
            一括削除
          </MenuItem>
        </Menu>
        <Dialog
          open={bulkOpen}
          onClose={bulkHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            {bulkModeJapanese[bulkMode]}
            {"しますか?"}
            <br />
            <Typography pl={1} variant="body1">
              対象メディア数: {selectionMedia?.length ?? 0}
              <br />
              対象一覧は以下の通りです。
            </Typography>
          </DialogTitle>
          <DialogContent>
            {selectionMedia?.map((row) => (
              <Box pl={1} pb={1}>
                <Typography key={row.spot_id} pl={1}>
                  {row.spot_id}: {row.user_id} からの {row.filename}
                  <Chip
                    sx={{ ml: 1 }}
                    label={MediaStatusJapanese[row.status]}
                    size="small"
                  />
                </Typography>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={bulkHandleClose} variant="outlined">
              キャンセル
            </Button>
            <Button
              onClick={() => {
                onClickBulkOperation(bulkMode);
                progressModalHandleClickOpen();
                bulkHandleClose();
              }}
              variant="contained"
              autoFocus
            >
              {bulkModeJapanese[bulkMode]}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={progressModalOpen}
          aria-labelledby="progres-dialog-title"
          aria-describedby="progress-dialog-description"
          fullWidth
          maxWidth="sm"
          sx={{ maxHeight: "80%" }}
        >
          <DialogTitle id="progress-dialog-title">
            {progressValue === bulkLength ? (
              <Typography fontSize="body1">
                完了({bulkLength}件中 {bulkSuccessCount}件 成功)
              </Typography>
            ) : (
              <Typography fontSize="body1">
                {bulkModeJapanese[bulkMode]}
                {"中..."}
              </Typography>
            )}
            <Stack>
              {progressValue && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={(progressValue / bulkLength) * 100}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${Math.round(
                      (progressValue / bulkLength) * 100
                    )}%`}</Typography>
                  </Box>
                </Box>
              )}
            </Stack>
          </DialogTitle>
          <DialogContent>
            {bulkResult.map((result) => (
              <Box key={result.mediaId} p={1}>
                <Alert severity={result.result ? "success" : "error"}>
                  {result.result ? "成功" : "失敗"} : {result.filename}
                </Alert>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" width="100%">
              {progressValue !== bulkLength ? (
                <Typography pl={4} fontSize="body1">
                  処理が完了するまで閉じないでください。
                </Typography>
              ) : (
                <Box></Box>
              )}
              <Button
                onClick={() => {
                  handleSubmit();
                  progressModalHandleClose();
                }}
                variant="outlined"
              >
                閉じる
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
      <Stack direction="row" flexWrap="wrap" height="100%" width="100%" p={1}>
        {MediaItems.map((mediaItem, key) => (
          <MediaItemCard
            key={key}
            mediaItem={mediaItem}
            handleSubmit={handleSubmit}
            isLoading={props.isLoading}
            selectionMedia={selectionMedia}
            setSelectionMedia={setSelectionMedia}
          />
        ))}
      </Stack>
    </Stack>
  );
};
export default MediaListTab;
