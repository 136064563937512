/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 営業状態.
 */
export enum SpotBusinessStatus {
    OPERATIONAL = 'operational',
    CLOSED_TEMPORARILY = 'closed_temporarily',
    CLOSED_PERMANENTLY = 'closed_permanently',
}
