import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { AdminSearchEngineApiClient } from "./generatedApi/adminSearchEngineApi";
import { InquiryManagerApiClient } from "./generatedApi/inquiryManagerApi";
import { MediaManagerApiClient } from "./generatedApi/mediaManagerApi";
import { NotificationManagerApiClient } from "./generatedApi/notificationManagerApi";
import {
  OpenAPIConfig,
  SpotManagerApiClient,
} from "./generatedApi/spotManagerApi";
import { SpotProviderApiClient } from "./generatedApi/spotProviderApi";
import { SpotSearchEngineApiClient } from "./generatedApi/spotSearchEngineApi";
import { UserManagerApiClient } from "./generatedApi/userManagerApi";
import { UserRequestManagerApiClient } from "./generatedApi/userRequestManagerApi";
import { UserDataImporterApiClient } from "./generatedApi/userUserDataImporterApi";
import { UserInteractionLoggerApiClient } from "./generatedApi/userUserInteractionLoggerApi";
import { UtilProviderApiClient } from "./generatedApi/utilProviderApi";
import { ViolationReportManagerApiClient } from "./generatedApi/violationReportManagerApi";

export const targetEnv = process.env.REACT_APP_ENV || "dev";

export const apiHost: Record<string, string> = {
  test: "https://api-admin.test.core.refloop.nicprdcts.com/v0",
  dev: "https://api-admin.dev.core.refloop.nicprdcts.com/v0",
  alpha: "https://api-admin.alpha.core.refloop.nicprdcts.com/v0",
  stg: "https://api-admin.stg.core.refloop.nicprdcts.com/v0",
  prd: "https://api-admin.core.refloop.nicprdcts.com/v0",
};

type Constructor<T = any> = new (...args: any[]) => T;

export const apiNameConstructorMap = {
  spot_manager: SpotManagerApiClient,
  spot_provider: SpotProviderApiClient,
  spot_search_engine: SpotSearchEngineApiClient,
  admin_search_engine: AdminSearchEngineApiClient,
  notification_manager: NotificationManagerApiClient,
  inquiry_manager: InquiryManagerApiClient,
  user_manager: UserManagerApiClient,
  user_request_manager: UserRequestManagerApiClient,
  user_interaction_logger: UserInteractionLoggerApiClient,
  user_data_importer: UserDataImporterApiClient,
  util_provider: UtilProviderApiClient,
  media_manager: MediaManagerApiClient,
  violation_report_manager: ViolationReportManagerApiClient,
};

export type ApiName = keyof typeof apiNameConstructorMap;

export type NonNullableData<T> = T extends void | undefined | null
  ? "__fetchSuccess__"
  : T;

export type ClientClassConstructor<S extends ApiName> =
  typeof apiNameConstructorMap[S] extends Constructor
    ? typeof apiNameConstructorMap[S]
    : never;

export type ClientServiceName<
  S extends ApiName,
  T extends ClientClassConstructor<S>
> = keyof InstanceType<T>;

export type ClientService<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>
> = InstanceType<T>[U];

export type ClientOperationId<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>
> = keyof V;

export type Fetcher = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>(
  // ClientConfiguration: ClientConfigurationConstructor,
  apiName: S,
  serviceName: U,
  operationId: W,
  requestParameters: Parameters<V[W]>[0]
  // initOverrides?: Parameters<InstanceType<T>[U]>[1]
) => Promise<{
  data: NonNullableData<Awaited<ReturnType<V[W]>>> | undefined;
  error: any;
}>;

export const useAdminFetcher = () => {
  const fetcher: Fetcher = async (
    apiName,
    serviceName,
    operationId,
    requestParameters
  ) => {
    const basePath = apiName;

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const params: Partial<OpenAPIConfig> = {
      BASE: `${apiHost[targetEnv]}/${basePath}`,
      TOKEN: token,
    };

    const cli = new apiNameConstructorMap[apiName](params);
    try {
      // @ts-expect-error
      const res = await cli[serviceName][operationId](requestParameters);
      return { data: res, error: null };
    } catch (e) {
      return { data: undefined, error: e };
    }
  };
  return fetcher;
};

export type Sender = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>(
  // ClientConfiguration: ClientConfigurationConstructor,
  apiName: S,
  serviceName: U,
  operationId: W
  // initOverrides?: Parameters<InstanceType<T>[U]>[1]
) => Promise<{
  data: NonNullableData<Awaited<ReturnType<V[W]>>> | undefined;
  error: any;
  sender: (
    args: Parameters<V[W]>[0]
  ) => Promise<NonNullableData<Awaited<ReturnType<V[W]>>>>;
}>;

export type useAdminSenderProps<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
> = {
  apiName: S;
  serviceName: U;
  operationId: W;
};

export const useAdminSender = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>({
  apiName,
  serviceName,
  operationId,
}: useAdminSenderProps<S, T, U, V, W>): {
  data: NonNullableData<Awaited<ReturnType<V[W]>>> | undefined;
  error: any;
  isLoading: boolean;
  sender: (
    args: Parameters<V[W]>[0]
  ) => Promise<NonNullableData<ReturnType<V[W]>> | undefined>;
} => {
  const [data, setData] = useState<
    NonNullableData<Awaited<ReturnType<V[W]>>> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const fetcher = useAdminFetcher();

  const sender = useCallback(
    async (
      requestData: Parameters<V[W]>[0]
    ): Promise<NonNullableData<ReturnType<V[W]>> | undefined> => {
      setIsLoading(true);
      setError(null);
      setData(undefined);
      const { data: res, error: err } = await fetcher(
        apiName,
        serviceName,
        operationId,
        requestData
      );
      if (err) {
        setError(err);
      } else {
        setData(res);
      }
      setIsLoading(false);
      return res;
    },
    []
  );

  return { data, isLoading, error, sender };
};

export type useAdminFetcherWithSrwProps<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
> = {
  apiName: S;
  serviceName: U;
  operationId: W;
  requestParameters: Parameters<V[W]>[0];
  swrConfiguration?: SWRConfiguration;
};

export const useAdminFetcherWithSwr = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>({
  apiName,
  serviceName,
  operationId,
  requestParameters,
  swrConfiguration,
}: useAdminFetcherWithSrwProps<S, T, U, V, W>): SWRResponse<
  NonNullableData<Awaited<ReturnType<V[W]>>>,
  any,
  any
> & { cacheKey: string } => {
  const cacheKey = `${apiName}.${serviceName as string}.${
    operationId as string
  }.${JSON.stringify(requestParameters)}`;
  const fetcher = useAdminFetcher();
  const apiCall = async () => {
    const { data: res, error: err } = await fetcher(
      apiName,
      serviceName,
      operationId,
      requestParameters
    );
    if (err) {
      throw err;
    }
    return res;
  };
  const swrRes = useSWR(cacheKey, apiCall, swrConfiguration);
  // @ts-expect-error TS2322
  return { ...swrRes, cacheKey };
};
