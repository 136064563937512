import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Clear } from "@mui/icons-material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useAdminFetcherWithSwr } from "../api";
import {
  SpotSearchQueryParams,
  SpotSearchQuerySortableField,
  SpotSortableOrder,
} from "../generatedApi/spotSearchEngineApi";
import { objectToQueryString, queryStringToObject } from "../utils";
import { callbackPublicPageAtom, callbackPublicPathAtom, callbackTabAtom } from "../atom";
import { useAtom, useSetAtom } from "jotai/react";

export const SpotITemPagePublicTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryName, setQueryName] = useState<string | null>(null);
  const [queryPhoneticName, setQueryPhoneticName] = useState<string | null>(
    null
  );
  const [queryLat, setQueryLat] = useState<number | null>(null);
  const [queryLon, setQueryLon] = useState<number | null>(null);
  const [querySortField, setQuerySortField] =
    useState<SpotSearchQuerySortableField | null>(null);
  const [querySortOrder, setQuerySortOrder] =
    useState<SpotSearchQuerySortableField | null>(null);

  const setCallbackPublicPath = useSetAtom(callbackPublicPathAtom);
  const [callbackPublicPage, setCallbackPublicPage] = useAtom(callbackPublicPageAtom);
  const setCallbackTab = useSetAtom(callbackTabAtom);

  const [querySize, setQuerySize] = useState<number>(10);
  const [queryPage, setQueryPage] = useState<number>(() => {
    const res = queryStringToObject(searchParams.toString());
    // queryPageがない場合は0を返す
    // Navigator などでページ遷移した場合にqueryPageがない場合があるため
    if (!res.page) {
      setCallbackPublicPath("");
      setCallbackPublicPage("0");
      return 0;
    }
    return callbackPublicPage ? Number(callbackPublicPage) : 0
  });
  useEffect(() => {
    setCallbackPublicPath(objectToQueryString(queryParams));
    setCallbackPublicPage(queryPage.toString());
  }, [queryPage]);

  useEffect(() => {
    const res = queryStringToObject(searchParams.toString());
    setQueryName(res.name ?? null);
    setQueryPhoneticName(res.phonetic_name ?? null);
    setQueryLat(res.location?.lat ?? null);
    setQueryLon(res.location?.lon ?? null);
    setQuerySortField(res.sort?.[0]?.field ?? null);
    setQuerySortOrder(res.sort?.[0]?.order ?? null);
    setQuerySize(res.size ?? 10);
    setQueryPage(res.page ?? 0);
  }, []);

  const queryParams = useMemo((): SpotSearchQueryParams => {
    let res: any = { size: querySize, page: queryPage };
    if (queryName) {
      res = { ...res, name: queryName };
    }
    if (queryPhoneticName) {
      res = { ...res, phonetic_name: queryPhoneticName };
    }
    if (queryLat && queryLon) {
      res = { ...res, location: { lat: queryLat, lon: queryLon } };
    }
    if (querySortField && querySortOrder) {
      res = {
        ...res,
        sort: [{ field: querySortField, order: querySortOrder }],
      };
    }
    const searchParams = objectToQueryString(res);
    setSearchParams(searchParams);

    return res;
  }, [
    queryName,
    queryPhoneticName,
    queryLat,
    queryLon,
    querySortField,
    querySortOrder,
    queryPage,
    querySize,
  ]);
  const debouncedQueryParams = useDebounce<SpotSearchQueryParams>(
    queryParams,
    500
  );

  const { data, isLoading } = useAdminFetcherWithSwr({
    apiName: "spot_search_engine",
    serviceName: "default",
    operationId: "searchSpots",
    requestParameters: { requestBody: debouncedQueryParams },
  });

  return (
    <Stack height="100%" direction="row" spacing={2}>
      <Stack width={300} py={1} spacing={1}>
        <Stack spacing={1}>
          <Typography variant="body1" fontWeight="bold">
            検索
          </Typography>
        </Stack>
        <TextField
          variant="filled"
          label="スポット名"
          value={queryName}
          onChange={(e) => setQueryName(e.target.value)}
        />
        <TextField
          variant="filled"
          label="読み仮名"
          value={queryPhoneticName}
          onChange={(e) => setQueryPhoneticName(e.target.value)}
        />
        <TextField
          variant="filled"
          label="緯度"
          value={queryLat}
          onChange={(e) => setQueryLat(Number(e.target.value))}
          type="number"
        />
        <TextField
          variant="filled"
          label="経度"
          value={queryLon}
          onChange={(e) => setQueryLon(Number(e.target.value))}
          type="number"
        />
        <InputLabel id="sort-field-label">ソート名</InputLabel>
        <Select
          labelId="sort-field-label"
          label="ソート名"
          variant="filled"
          value={querySortField === null ? "" : querySortField}
          onChange={(e) => setQuerySortField(e.target.value as any)}
          endAdornment={
            <>
              <IconButton
                sx={{ display: querySortField ? "" : "none" }}
                onClick={() => setQuerySortField(null)}
              >
                <Clear />
              </IconButton>
            </>
          }
        >
          <MenuItem value={SpotSearchQuerySortableField.TIMESTAMP_PUBLISHED}>
            公開日時
          </MenuItem>
          <MenuItem value={SpotSearchQuerySortableField.TIMESTAMP_CREATED}>
            作成日時
          </MenuItem>
          <MenuItem value={SpotSearchQuerySortableField.DISTANCE}>
            距離
          </MenuItem>
        </Select>
        <InputLabel id="sort-order-label">ソート順</InputLabel>
        <Select
          labelId="sort-order-label"
          label="ソート順"
          variant="filled"
          value={querySortOrder === null ? "" : querySortOrder}
          onChange={(e) => setQuerySortOrder(e.target.value as any)}
          endAdornment={
            <>
              <IconButton
                sx={{ display: querySortOrder ? "" : "none" }}
                onClick={() => setQuerySortOrder(null)}
              >
                <Clear />
              </IconButton>
            </>
          }
        >
          <MenuItem value={SpotSortableOrder.ASC}>昇順</MenuItem>
          <MenuItem value={SpotSortableOrder.DESC}>降順</MenuItem>
        </Select>
      </Stack>
      <Box sx={{ width: "100%", height: "100%", py: 1 }}>
        <DataGrid
          loading={isLoading}
          sx={{ bgcolor: "#fff" }}
          rows={data?.results ?? []}
          page={queryPage}
          rowCount={data?.total ?? 0}
          paginationMode="server"
          disableColumnFilter
          pageSize={querySize}
          rowsPerPageOptions={[5, 10, 20, 100]}
          onPageChange={(page, details) => {
            setQueryPage(page);
          }}
          onPageSizeChange={(newPageSize) => setQuerySize(newPageSize)}
          columns={[
            {
              field: "spot_id",
              width: 200,
              renderCell: (params) => (
                <Link
                  to={`/spots/${params.value}`}
                  onClick={() => {
                    setCallbackPublicPath(objectToQueryString(queryParams));
                    setCallbackPublicPage(queryPage.toString());
                    setCallbackTab("public");
                  }}
                >
                  {params.value}
                </Link>
              ),
              sortable: false,
            },
            {
              field: "name",
              headerName: "スポット名",
              width: 150,
              sortable: false,
            },
            {
              field: "phonetic_name",
              headerName: "読み仮名",
              width: 150,
              sortable: false,
            },
            {
              field: "revision_id",
              headerName: "v",
              width: 50,
              sortable: false,
            },
            {
              field: "timestamp_published",
              headerName: "公開日時",
              width: 160,
              sortable: false,
            },
            {
              field: "timestamp_updated",
              headerName: "作成日時",
              width: 160,
              sortable: false,
            },
            {
              field: "distance",
              headerName: "距離",
              width: 100,
              sortable: false,
              renderCell: (params) => {
                return (
                  <Typography variant="body2">
                    {params.value == null
                      ? "-"
                      : `${Math.floor(params.value * 100) / 100}km`}
                  </Typography>
                );
              },
            },
            {
              field: "location",
              headerName: "位置情報",
              width: 180,
              sortable: false,
              renderCell: (params) => {
                return (
                  params.row.location && (
                    <a
                      href={`https://www.google.co.jp/maps/@${params.row.location?.lat},${params.row.location?.lon},14z`}
                      target="_blank"
                    >
                      {params.row.location?.lat},{params.row.location?.lon}
                    </a>
                  )
                );
              },
            },
          ]}
          getRowId={(row) => row.spot_id}
          checkboxSelection
          components={{
            Toolbar: () => (
              <GridToolbarContainer
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Stack direction="row">
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                  <GridToolbarExport />
                </Stack>
                <Stack direction="row" spacing={1}></Stack>
              </GridToolbarContainer>
            ),
          }}
        />
      </Box>
    </Stack>
  );
};
