/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * メディアのステータス
 */
export enum MediaStatus {
    PREPARED = 'prepared',
    PROCESSING = 'processing',
    FAILED = 'failed',
    READY = 'ready',
    DELETED = 'deleted',
    ARCHIVED = 'archived',
    REPORTED = 'reported',
    BANNED = 'banned',
}
