import { Launch, Search } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Radio,
  Stack,
  Tab,
  Tabs,
  Typography,
  colors,
} from "@mui/material";
import { useList } from "@uidotdev/usehooks";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import {
  UserQueryParams,
  UserQueryResponse,
} from "../generatedApi/userManagerApi";
import { getJstFromUnixtime } from "../utils";
import Template from "./Template";

type UserDetailPanelProps = {
  userId: string;
};
const UserDetailPanel = ({ userId, ...props }: UserDetailPanelProps) => {
  const {
    data: user,
    isLoading,
    mutate: reloadUser,
  } = useAdminFetcherWithSwr({
    apiName: "user_manager",
    serviceName: "default",
    operationId: "getUser",
    requestParameters: { userId },
  });
  const displayUserValue = (userKey: string, userValue: any) => {
    if (userValue === null) {
      return "データなし";
    }
    if (userKey.startsWith("timestamp") || userKey === "create_at") {
      return getJstFromUnixtime(userValue / 1000);
    }
    return userValue;
  };
  const { sender: disableUser, isLoading: isUserDisabling } = useAdminSender({
    apiName: "user_manager",
    serviceName: "default",
    operationId: "disableUser",
  });
  const handleDisableUser = async () => {
    await disableUser({ userId });
    await reloadUser();
  };
  const { sender: enableUser, isLoading: isUserEnabling } = useAdminSender({
    apiName: "user_manager",
    serviceName: "default",
    operationId: "cancelDisabledUser",
  });
  const handleEnableUser = async () => {
    await enableUser({ userId });
    await reloadUser();
  };
  const errorFields = useMemo(() => {
    if (!user) {
      return [];
    }
    return user.error_fields ?? [];
  }, [user]);
  const { data: similarUserIdList, isLoading: isGettingSimilarUsers } =
    useAdminFetcherWithSwr({
      apiName: "spot_search_engine",
      serviceName: "default",
      operationId: "experimentalGetSimilarUsers",
      requestParameters: { userId },
    });
  return (
    <Box width="100%">
      <Stack
        border={(theme) => `1px solid ${theme.palette.divider}`}
        bgcolor={(theme) => theme.palette.background.default}
        width="100%"
        divider={<Divider />}
      >
        <Stack
          p={1}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Typography variant="h6">{userId}</Typography>
          {user && (
            <Stack direction="row" spacing={1}>
              <Chip label={user.account_status ?? "ステータスなし"} />
              <Button
                size="small"
                variant="contained"
                startIcon={<Launch />}
                component={Link}
                to={`/user_requests?partition=user_id&id=${user.user_id}`}
                target="_blank"
              >
                ユーザリクエスト一覧
              </Button>
            </Stack>
          )}
        </Stack>
        {isLoading && <CircularProgress />}
        {user && (
          <Stack p={1} direction="row" spacing={4}>
            <Stack>
              {user.error_fields && user.error_fields.length > 0 && (
                <Alert severity="error">
                  このユーザには仕様外の値が入っているため、全体ユーザ集計で失敗する可能性があります。データベース運用者に問い合わせてください。
                </Alert>
              )}
              <Stack spacing={0.5}>
                {Object.entries(user).map(([userKey, userValue], key) => (
                  <Stack key={key}>
                    <Typography variant="body2">{userKey}</Typography>
                    <Typography variant="body1">
                      {errorFields.includes(userKey) && (
                        <Alert severity="error">
                          仕様外の値が入っています。
                        </Alert>
                      )}
                      {displayUserValue(userKey, userValue)}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Stack maxWidth={300} minWidth={150}>
              <Typography variant="h6">類似ユーザ(β)</Typography>
              {similarUserIdList?.map((userId) => (
                <>
                  <Link to={`/users/${userId}`} key={userId}>
                    <Typography>{userId}</Typography>
                  </Link>
                </>
              ))}
            </Stack>
          </Stack>
        )}
        {user && (
          <Stack p={1} bgcolor={colors.red["50"]} alignItems="start">
            {!user.timestamp_stopped_by_admin && (
              <Button
                variant="contained"
                disableElevation
                color="error"
                onClick={() => handleDisableUser()}
                disabled={isUserDisabling || isUserEnabling}
              >
                ユーザを停止
              </Button>
            )}
            {user.timestamp_stopped_by_admin && (
              <Button
                variant="contained"
                disableElevation
                color="error"
                onClick={() => handleEnableUser()}
                disabled={isUserDisabling || isUserEnabling}
              >
                ユーザの停止を取り消し
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

const UserManagerPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [lastEvaluatedKey, setLastEvaluatedKey] =
    useState<string | undefined>();

  const [userResults, { push: pushUserResults, clear }] =
    useList<UserQueryResponse>([]);

  const { sender, isLoading } = useAdminSender({
    apiName: "user_manager",
    serviceName: "default",
    operationId: "getUsers",
  });

  const fetchUsers = async () => {
    if (lastEvaluatedKey === undefined) {
      clear();
    }
    const userResult = await sender({
      limit: 100,
      lastEvaluatedKey,
      requestBody: queryParams,
    });
    if (userResult) {
      pushUserResults(userResult);
      setLastEvaluatedKey(userResult.last_evaluated_key ?? undefined);
    }
  };

  const users = useMemo(() => {
    return userResults.map((userResult) => userResult.items).flat();
  }, [userResults]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const [queryUserId, setQueryUserId] = useState<string | null>(null);

  const queryParams = useMemo((): UserQueryParams => {
    let res: UserQueryParams = {};
    if (queryUserId) {
      res.user_id = queryUserId;
    }
    return res;
  }, [queryUserId]);

  useEffect(() => {
    setLastEvaluatedKey(undefined);
  }, [queryParams]);

  const { data: apiHealth, error: tableConnectionError } =
    useAdminFetcherWithSwr({
      apiName: "user_manager",
      serviceName: "default",
      operationId: "getHealth",
      requestParameters: undefined,
    });

  useEffect(() => {
    console.log("tableConnectionError", tableConnectionError);
    if (tableConnectionError) {
      console.warn("table connection error", tableConnectionError);
    }
  }, [tableConnectionError]);

  useEffect(() => {
    if (apiHealth) {
      console.log(apiHealth);
    }
  }, [apiHealth]);

  return (
    <>
      <Template title="ユーザ管理">
        <AppBar
          component="div"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Tabs value={"users"} textColor="inherit">
            <Tab label="ユーザ一覧" onClick={() => {}} value="users" />
          </Tabs>
        </AppBar>
        <Box component="main" sx={{ flex: 1, p: 2, bgcolor: "#eaeff1" }}>
          <Stack direction="row" spacing={1} height="100%">
            <Stack
              border={(theme) => `1px solid ${theme.palette.divider}`}
              minWidth={300}
              maxWidth={300}
              bgcolor={(theme) => theme.palette.background.default}
              height="100%"
            >
              <Stack divider={<Divider />}>
                <Stack p={1}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      fetchUsers();
                    }}
                  >
                    <InputBase
                      fullWidth
                      placeholder="ユーザ名で検索..."
                      value={queryUserId}
                      onChange={(e) => setQueryUserId(e.target.value)}
                      endAdornment={
                        <IconButton type="submit">
                          <Search />
                        </IconButton>
                      }
                    />
                  </form>
                </Stack>
                {users.map((user, ukey) => (
                  <Stack
                    p={1}
                    key={ukey}
                    direction="row"
                    onClick={() => navigate(`/users/${user.user_id}`)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Box>
                      <Radio size="small" checked={userId === user.user_id} />
                    </Box>
                    <Stack>
                      <Typography>{user.user_id}</Typography>
                      <Typography variant="body2">
                        {getJstFromUnixtime(user.create_at / 1000)}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
                {isLoading && (
                  <Stack p={1}>
                    <CircularProgress />
                  </Stack>
                )}
                {lastEvaluatedKey && !isLoading && (
                  <Box p={1}>
                    <Button onClick={fetchUsers}>もっと読みこむ</Button>
                  </Box>
                )}
              </Stack>
            </Stack>
            <Stack width="100%">
              {tableConnectionError && (
                <Alert severity="error">テーブル接続エラー</Alert>
              )}
              {userId ? (
                <UserDetailPanel userId={userId} />
              ) : (
                <Stack p={1}>ユーザが選択されていません。</Stack>
              )}
            </Stack>
          </Stack>
        </Box>
      </Template>
    </>
  );
};

export default UserManagerPage;
