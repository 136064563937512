import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { JSONSchema7 } from "json-schema";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminSender } from "../api";
import { SpotItemMetadataCreate } from "../generatedApi/spotManagerApi";

const SpotItemCreatePanel = () => {
  const {
    data: dataCreated,
    sender: createSpotItem,
    isLoading: isDataCreating,
    error: isDataCreateError,
  } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "createSpotItem",
  });
  const {
    data: revisionCreated,
    sender: createRevision,
    isLoading: isRevisionCreating,
    error: isRevisionCreateError,
  } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "createSpotRevision",
  });
  const handleSubmitSpot = async () => {
    if (spotName === null) {
      alert("スポット名を入力してください");
      return;
    }
    if (spotId === null) {
      alert("スポットIDを入力してください");
      return;
    }
    const body: SpotItemMetadataCreate = {
      spot_id: spotId,
      name: spotName,
    };
    await createSpotItem({ requestBody: body });
    await createRevision({
      spotId,
      requestBody: { spot_id: spotId, name: spotName, google_cid: googleCid },
    });
  };

  const { data: searchResult, sender: searchSpot } = useAdminSender({
    apiName: "admin_search_engine",
    serviceName: "default",
    operationId: "searchDraftSpots",
  });

  const {
    data: googleCid,
    sender: getGoogleCid,
    error: googleCidError,
  } = useAdminSender({
    apiName: "util_provider",
    serviceName: "default",
    operationId: "extractGmapCid",
  });

  const handleSubmitGoogleMapsUrl = async () => {
    if (googleMapsUrl === null) {
      alert("Google Maps URLを入力してください");
      return;
    }
    getGoogleCid({ url: googleMapsUrl });
  };

  useEffect(() => {
    if (googleCid) {
      searchSpot({ requestBody: { google_cid: googleCid } });
    }
  }, [googleCid]);

  useEffect(() => {
    console.log(searchResult);
  }, [searchResult]);

  const schema: JSONSchema7 = {
    properties: {
      name: {
        description: "スポット名",
        maxLength: 128,
        minLength: 1,
        title: "スポット名",
        type: "string",
      },
      spotId: {
        description: "スポットID",
        maxLength: 128,
        minLength: 1,
        title: "スポットID",
        type: "string",
      },
    },
    title: "スポットアイテムを追加",
    required: ["name", "spotId"],
    type: "object",
  };

  const [googleMapsUrl, setGoogleMapsUrl] = useState<string | null>(null);
  const [spotName, setSpotName] = useState<string | null>(null);
  const [spotId, setSpotId] = useState<string | null>(null);

  const generateRandomSpotId = () => {
    const candidateChars = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
    const spotId = Array.from({ length: 9 }).map(() => {
      return candidateChars[Math.floor(Math.random() * candidateChars.length)];
    });
    return "S" + spotId.join("");
  };
  useEffect(() => {
    setSpotId(generateRandomSpotId());
  }, [googleCid]);

  return (
    <>
      <Box p={2}>
        <>
          <Stack spacing={3}>
            <Stack direction="column" spacing={1}>
              <Typography variant="h6">Step1: Google Maps URL 入力</Typography>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  handleSubmitGoogleMapsUrl();
                }}
              >
                <Stack direction="column" spacing={1}>
                  <TextField
                    label="Google Maps URL"
                    value={googleMapsUrl}
                    onChange={(e) => setGoogleMapsUrl(e.target.value as any)}
                  />
                  <Button type="submit" variant="contained">
                    追加
                  </Button>
                </Stack>
              </form>

              {googleCidError && (
                <Alert severity="error">
                  Google Maps URL の取り込みに失敗しました。
                </Alert>
              )}
              {googleCid && (
                <Alert severity="success">
                  Google Maps URL の取り込みに成功しました。
                  <br />
                  次のURLからアクセスできるか確認してください:
                  <a
                    target="_blank"
                    href={`https://maps.google.com/?cid=${googleCid}`}
                  >
                    {`https://maps.google.com/?cid=${googleCid}`}
                  </a>
                </Alert>
              )}
            </Stack>
            {googleCid && (
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Step 2: 重複項目の確認</Typography>
                {searchResult &&
                  searchResult.total > 0 &&
                  searchResult.results.map((spot) => (
                    <Alert severity="warning" key={spot.spot_id}>
                      既に同じGoogle Maps URLのスポットが存在します。
                      <br />
                      既存のスポットに追加する場合は、
                      <Link to={`/spots/${spot.spot_id}`} target="_blank">
                        「{spot.name}」({spot.spot_id})を編集
                      </Link>
                      してください。
                    </Alert>
                  ))}
                {searchResult && searchResult.total === 0 && (
                  <Alert severity="success">
                    重複するスポットはありません。
                  </Alert>
                )}
              </Stack>
            )}
            {googleCid && searchResult && searchResult.total === 0 && (
              <Stack direction="column" spacing={1}>
                <Typography variant="h6">Step 3: スポット名の入力</Typography>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitSpot();
                  }}
                >
                  <Stack direction="column" spacing={1}>
                    <TextField
                      variant="filled"
                      label="スポット名"
                      value={spotName ?? ""}
                      onChange={(e) => setSpotName(e.target.value)}
                    />
                    <TextField
                      variant="filled"
                      label="スポットID"
                      value={spotId ?? ""}
                      onChange={(e) => setSpotId(e.target.value)}
                    />
                    <Button type="submit" variant="contained" disabled={
                      isDataCreating || isRevisionCreating || revisionCreated !== undefined
                    }>
                      作成
                    </Button>
                    {isDataCreating && <p>スポット作成中...</p>}
                    {isRevisionCreating && <p>スポットリビジョン作成中...</p>}
                    {isDataCreateError && (
                      <Alert severity="error">
                        スポットの作成に失敗しました。
                      </Alert>
                    )}
                    {isRevisionCreateError && (
                      <Alert severity="error">
                        スポットリビジョンの作成に失敗しました。
                      </Alert>
                    )}
                    {revisionCreated && (
                      <Alert severity="success">
                        スポットリビジョンの作成に成功しました。
                        <br />
                        <Link
                          to={`/spots/${spotId}`}
                          target="_blank"
                        >{`/spots/${spotId}`}</Link>
                      </Alert>
                    )}
                  </Stack>
                </form>
              </Stack>
            )}
          </Stack>
        </>
      </Box>
    </>
  );
};

export default SpotItemCreatePanel;
