/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InquiryItem } from '../models/InquiryItem';
import type { InquiryItemCreate } from '../models/InquiryItemCreate';
import type { InquiryItemQueryResponse } from '../models/InquiryItemQueryResponse';
import type { InquiryStatus } from '../models/InquiryStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * お問い合わせを記録します。
     * @returns InquiryItem Successful Response
     * @throws ApiError
     */
    public postInquiryItem({
        requestBody,
    }: {
        requestBody: InquiryItemCreate,
    }): CancelablePromise<InquiryItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/inquiries',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お問い合わせを取得します。
     * @returns InquiryItem Successful Response
     * @throws ApiError
     */
    public getInquiryItem({
        inquiryId,
    }: {
        inquiryId: string,
    }): CancelablePromise<InquiryItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/inquiries/{inquiry_id}',
            path: {
                'inquiry_id': inquiryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お問い合わせを削除します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteInquiryItem({
        inquiryId,
    }: {
        inquiryId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/inquiries/{inquiry_id}',
            path: {
                'inquiry_id': inquiryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザー毎に最新順にお問い合わせのリストを返します。
     * 記録したお問い合わせの確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns InquiryItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getLatestInquiryItemsByUserId({
        userId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        userId: string,
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<InquiryItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/inquiries/user/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 月毎に最新順にお問い合わせのリストを返します。
     * 記録したお問い合わせの確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns InquiryItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getLatestInquiryItemsByMonthId({
        monthId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        monthId: string,
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<InquiryItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/inquiries/month/{month_id}',
            path: {
                'month_id': monthId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お問い合わせのステータスを変更します。
     * @returns InquiryItem Successful Response
     * @throws ApiError
     */
    public updateInquiryItemStatus({
        inquiryId,
        status,
    }: {
        inquiryId: string,
        status: InquiryStatus,
    }): CancelablePromise<InquiryItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/inquiries/{inquiry_id}/status/{status}',
            path: {
                'inquiry_id': inquiryId,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
