import { ArrowDropDown, Check } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAdminSender } from "../api";
import {
  RequestStatus,
  RequestType,
  UserRequestItemQueryResponse,
  UserRequestItem,
} from "../generatedApi/userRequestManagerApi";
import { getJstFromUnixtime } from "../utils";
import { JumboDrawer } from "./JumboDrawer";
import Template from "./Template";
import { UserRequestDetailPanel } from "./UserRequestDetailPanel";

type GetRequestStatusProps = {
  status: RequestStatus;
  type: RequestType;
};
export const getRequestStatus = (props: GetRequestStatusProps) => {
  const { status, type } = props;
  switch (status) {
    case RequestStatus.OPENED:
      return "未処理";
    case RequestStatus.DRAFT:
      return "下書き";
    case RequestStatus.MERGED:
      switch (type) {
        case RequestType.CREATE:
          return "新規作成済";
        case RequestType.UPDATE:
          return "更新済";
        case RequestType.DELETE:
          return "削除済";
      }
    case RequestStatus.CLOSED:
      return "却下済";
  }
};

export const getRequestType = (requestType: RequestType) => {
  switch (requestType) {
    case RequestType.CREATE:
      return "新規作成依頼";
    case RequestType.UPDATE:
      return "更新依頼";
    case RequestType.DELETE:
      return "削除依頼";
  }
};

export const getMergeText = (props: RequestType) => {
  switch (props) {
    case RequestType.CREATE:
      return "新規作成を承認";
    case RequestType.UPDATE:
      return "更新を承認";
    case RequestType.DELETE:
      return "削除を承認";
  }
};

export const UserRequestManagerPage = () => {
  const navigate = useNavigate();
  const { request_id: requestId } = useParams();
  const [queryParams, setSearchParams] = useSearchParams();

  const { sender: getUserRequestItemsMonthId, isLoading: isLoading1 } =
    useAdminSender({
      apiName: "user_request_manager",
      serviceName: "default",
      operationId: "getUserRequestItemsMonthId",
    });

  const { sender: getUserRequestItemsUserId, isLoading: isLoading2 } =
    useAdminSender({
      apiName: "user_request_manager",
      serviceName: "default",
      operationId: "getUserRequestItemsByUser",
    });

  const { sender: getUserRequestItemsSpotId, isLoading: isLoading3 } =
    useAdminSender({
      apiName: "user_request_manager",
      serviceName: "default",
      operationId: "getUserRequestItemsBySpot",
    });

  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const [queryResult, setQueryResult] =
    useState<UserRequestItemQueryResponse | null>(null);

  const handleSubmit = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: UserRequestItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getUserRequestItemsMonthId({ monthId: queryId });
        break;
      case "user_id":
        res = await getUserRequestItemsUserId({ userId: queryId });
        break;
      case "spot_id":
        res = await getUserRequestItemsSpotId({ spotId: queryId });
        break;
    }
    if (res === undefined) return;
    setQueryResult(res);
  };

  const [queryPartition, setQueryPartition] = useState<string>("month_id");
  const [queryId, setQueryId] = useState<string | null>(null);

  useEffect(() => {
    setQueryId(queryParams.get("id") ?? new Date().toISOString().slice(0, 7));
    setQueryPartition(queryParams.get("partition") ?? "month_id");
  }, []);

  const [moreReadButtonDisabled, setMoreReadButtonDisabled] = useState(false);
  useEffect(() => {
    if (queryResult?.last_evaluated_key) {
      setMoreReadButtonDisabled(true);
    } else {
      setMoreReadButtonDisabled(false);
    }
  }, [queryResult]);
  const [queryResult2, setQueryResult2] =
    useState<UserRequestItemQueryResponse | null>(null);
  const getMoreUserRequestItems = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: UserRequestItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getUserRequestItemsMonthId({
          monthId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
      case "user_id":
        res = await getUserRequestItemsUserId({
          userId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
      case "spot_id":
        res = await getUserRequestItemsSpotId({
          spotId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
    }
    if (res === undefined) return;
    if (queryResult === null) return;
    const totalQueryResult = queryResult;
    totalQueryResult["items"] = [...queryResult.items, ...res.items];
    totalQueryResult["last_evaluated_key"] = res.last_evaluated_key;
    if (!res.last_evaluated_key) {
      setMoreReadButtonDisabled(false);
    }
    setQueryResult2(totalQueryResult);
  };
  useEffect(() => {
    setQueryResult(queryResult2);
  }, [queryResult2]);

  const [selectionModel, setSelectionModel] =
    useState<UserRequestItem[] | undefined>();

  const [bulkMode, setBulkMode] =
    useState<"merge" | "close" | "reopen">("merge");
  const bulkModeJapanese = {
    merge: "一括承認",
    close: "一括却下",
    reopen: "一括却下を取り消し",
  };
  const [bulkMenuOpen, setBulkMenuOpen] = useState(false);
  const bulkMenuHandleClickOpen = () => {
    setBulkMenuOpen(true);
  };
  const bulkMenuHandleClose = () => {
    setBulkMenuOpen(false);
  };
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [bulkOpen, setBulkOpen] = useState(false);
  const bulkHandleClickOpen = () => {
    setBulkOpen(true);
  };
  const bulkHandleClose = () => {
    setBulkOpen(false);
  };

  const [bulkResult, setBulkResult] = useState<
    Array<{
      requestId: string;
      userId: string;
      requestType: RequestType;
      result: boolean;
    }>
  >([]);

  const [bulkLength, setBulkLength] = useState<number>(0);
  const [bulkSuccessCount, setBulkSuccessCount] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number | null>(null);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const progressModalHandleClickOpen = () => {
    setProgressModalOpen(true);
  };
  const progressModalHandleClose = () => {
    setProgressModalOpen(false);
  };

  const { sender: mergeRequestItem, isLoading: isMerging } = useAdminSender({
    apiName: "user_request_manager",
    serviceName: "admin",
    operationId: "adminMergeUserRequestItem",
  });
  const { sender: closeRequestItem, isLoading: isRejecting } = useAdminSender({
    apiName: "user_request_manager",
    serviceName: "admin",
    operationId: "adminCloseUserRequestItem",
  });
  const { sender: reopenRequestItem, isLoading: isReopening } = useAdminSender({
    apiName: "user_request_manager",
    serviceName: "admin",
    operationId: "adminReopenUserRequestItem",
  });

  const { sender: getSpotItem, isLoading: isSpotLoading } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItem",
  });
  const {
    sender: publishSpotRevision,
    isLoading: isPublishing,
    error: publishError,
  } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "publishSpotRevision",
  });

  const onClickBulkPublish = async (
    mode: "merge" | "close" | "reopen"
  ) => {
    if (!selectionModel) return;
    setProgressValue(null);
    setBulkLength(selectionModel.length);
    setBulkSuccessCount(0);
    let successCount = 0;
    let totalBulkResult = bulkResult;
    totalBulkResult = [];
    setBulkResult(totalBulkResult);
    for (let i = 0; i < selectionModel.length; i++) {
      const requestId = selectionModel[i].request_id;
      const status = selectionModel[i].status;
      const userId = selectionModel[i].user_id;
      const requestType = selectionModel[i].request_type;

      let res: UserRequestItem | undefined;
      switch (mode) {
        case "merge":
          res = await mergeRequestItem({
            requestId,
          });
          break;
        case "close":
          res = await closeRequestItem({
            requestId,
          });
          break;
        case "reopen":
          res = await reopenRequestItem({
            requestId,
          });
          break;
      }
      if (res === undefined) {
        totalBulkResult.unshift({
          requestId,
          userId,
          requestType,
          result: false,
        });
        totalBulkResult = [...totalBulkResult];
        setBulkResult(totalBulkResult);
        setProgressValue(i + 1);
        continue;
      }
      if (mode === "merge") {
        const spotId = res.spot_id;
        let revisionId: string | undefined = undefined;
        if (spotId !== undefined && spotId !== null) {
          const spotItemMetaData = await getSpotItem({ spotId });
          if (
            spotItemMetaData?.latest_revision_id !== undefined &&
            spotItemMetaData.latest_revision_id !== null
          ) {
            revisionId = spotItemMetaData.latest_revision_id;
          }
        }
        if (
          spotId === undefined ||
          spotId === null ||
          revisionId === undefined
        ) {
          totalBulkResult.unshift({
            requestId,
            userId,
            requestType,
            result: false,
          });
          totalBulkResult = [...totalBulkResult];
          setBulkResult(totalBulkResult);
          setProgressValue(i + 1);
          continue;
        }
        const publishRes = await publishSpotRevision({
          spotId,
          revisionId,
        });
        if (publishRes === undefined) {
          totalBulkResult.unshift({
            requestId,
            userId,
            requestType,
            result: false,
          });
          totalBulkResult = [...totalBulkResult];
          setBulkResult(totalBulkResult);
          setProgressValue(i + 1);
          continue;
        }
      }
      totalBulkResult.unshift({
        requestId,
        userId,
        requestType,
        result: true,
      });
      totalBulkResult = [...totalBulkResult];
      setBulkResult(totalBulkResult);
      setProgressValue(i + 1);
      successCount = successCount + 1;
    }
    setBulkSuccessCount(successCount);
  };

  return (
    <>
      <Template title="ユーザリクエスト管理">
        <AppBar
          component="div"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Tabs value={"users"} textColor="inherit">
            <Tab label="リクエスト一覧" onClick={() => {}} value="users" />
          </Tabs>
        </AppBar>
        <Box component="main" sx={{ flex: 1, p: 2, bgcolor: "#eaeff1" }}>
          <Stack direction="row" spacing={1} height="100%">
            <Stack
              border={(theme) => `1px solid ${theme.palette.divider}`}
              minWidth={300}
              bgcolor={(theme) => theme.palette.background.default}
            >
              <Stack divider={<Divider />}>
                <Stack p={1} spacing={1}>
                  <Typography>検索</Typography>
                  <Stack direction="row" spacing={1}>
                    {["month_id", "user_id", "spot_id"].map(
                      (partition, pkey) => (
                        <Chip
                          key={pkey}
                          label={partition}
                          onClick={() => {
                            setQueryPartition(partition);
                          }}
                          size="small"
                          variant={
                            queryPartition === partition ? "filled" : "outlined"
                          }
                          icon={
                            queryPartition === partition ? <Check /> : undefined
                          }
                        />
                      )
                    )}
                  </Stack>
                </Stack>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Stack p={1} spacing={1}>
                    <TextField
                      label="ID"
                      placeholder={`${queryPartition} を入力...`}
                      value={queryId}
                      onChange={(e) => setQueryId(e.target.value)}
                      disabled={isLoading}
                    />
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        disabled={isLoading}
                      >
                        IDに該当するデータを取得
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </Stack>
            </Stack>
            <Stack width="100%" height="100%">
              <DataGrid
                rows={queryResult?.items ?? []}
                onSelectionModelChange={(RowId) => {
                  const selectedRowId = new Set(RowId);
                  const selectedRows = queryResult?.items.filter(
                    (dataGridRow) => selectedRowId.has(dataGridRow.request_id)
                  );
                  setSelectionModel(selectedRows);
                }}
                checkboxSelection
                sx={{ bgcolor: "#fff" }}
                loading={isLoading}
                getRowId={(row) => row.request_id}
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Stack direction="row">
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          endIcon={<ArrowDropDown />}
                          disabled={
                            selectionModel?.length === 0 ||
                            selectionModel === undefined
                          }
                          ref={anchorEl}
                          onClick={bulkMenuHandleClickOpen}
                        >
                          一括操作
                        </Button>
                        <Menu
                          id="bulk-menu"
                          anchorEl={anchorEl.current}
                          open={bulkMenuOpen}
                          onClose={() => bulkMenuHandleClose()}
                        >
                          <MenuItem
                            onClick={() => {
                              setBulkMode("merge");
                              bulkMenuHandleClose();
                              bulkHandleClickOpen();
                            }}
                          >
                            一括承認
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setBulkMode("close");
                              bulkMenuHandleClose();
                              bulkHandleClickOpen();
                            }}
                          >
                            一括却下
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setBulkMode("reopen");
                              bulkMenuHandleClose();
                              bulkHandleClickOpen();
                            }}
                          >
                            一括却下を取り消し
                          </MenuItem>
                        </Menu>
                      </Stack>
                    </GridToolbarContainer>
                  ),
                  Footer: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      {moreReadButtonDisabled && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => getMoreUserRequestItems()}
                        >
                          続きを読み込む
                        </Button>
                      )}
                    </Box>
                  ),
                }}
                columns={[
                  {
                    field: "request_id",
                    renderCell: (params) => (
                      <Link to={`/user_requests/${params.value}`}>
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: "user_id",
                    renderCell: (params) => (
                      <Link target="_blank" to={`/users/${params.value}`}>
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: "spot_id",
                    renderCell: (params) => (
                      <Link target="_blank" to={`/spots/${params.value}`}>
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: "request_type",
                    width: 120,
                    renderCell: (params) => (
                      <Chip size="small" label={getRequestType(params.value)} />
                    ),
                  },
                  {
                    field: "status",
                    width: 110,
                    renderCell: (params) => (
                      <Chip
                        size="small"
                        label={getRequestStatus({
                          status: params.value,
                          type: params.row.request_type,
                        })}
                      />
                    ),
                  },
                  { field: "request_from", headerName: "送信元" },
                  {
                    field: "timestamp_created",
                    headerName: "作成日時",
                    width: 180,
                    renderCell: (params) =>
                      getJstFromUnixtime(params.value / 1000),
                  },
                  {
                    field: "comment",
                    width: 300,
                    renderCell: (params) => (
                      <Typography variant="body2">{params.value}</Typography>
                    ),
                  },
                ]}
              />
              <Dialog
                open={bulkOpen}
                onClose={bulkHandleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="alert-dialog-title">
                  {bulkModeJapanese[bulkMode]}
                  {"しますか?"}
                  <br />
                  <Typography pl={1} variant="body1">
                    対象スポット数: {selectionModel?.length ?? 0}
                    <br />
                    対象一覧は以下の通りです。
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  {selectionModel?.map((row) => (
                    <Box pl={1} pb={1}>
                      <Typography key={row.spot_id} pl={1}>
                        {row.spot_id}: {row.user_id} からの{" "}
                        <Chip
                          size="small"
                          label={getRequestType(row.request_type)}
                        />
                      </Typography>
                    </Box>
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={bulkHandleClose} variant="outlined">
                    キャンセル
                  </Button>
                  <Button
                    onClick={() => {
                      onClickBulkPublish(bulkMode);
                      progressModalHandleClickOpen();
                      bulkHandleClose();
                    }}
                    variant="contained"
                    autoFocus
                  >
                    {bulkModeJapanese[bulkMode]}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={progressModalOpen}
                aria-labelledby="progres-dialog-title"
                aria-describedby="progress-dialog-description"
                fullWidth
                maxWidth="sm"
                sx={{ maxHeight: "80%" }}
              >
                <DialogTitle id="progress-dialog-title">
                  {progressValue === bulkLength ? (
                    <Typography fontSize="body1">
                      完了({bulkLength}件中 {bulkSuccessCount}件 成功)
                    </Typography>
                  ) : (
                    <Typography fontSize="body1">
                      {bulkModeJapanese[bulkMode]}
                      {"中..."}
                    </Typography>
                  )}
                  <Stack>
                    {progressValue && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ width: "100%", mr: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={(progressValue / bulkLength) * 100}
                          />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                          >{`${Math.round(
                            (progressValue / bulkLength) * 100
                          )}%`}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Stack>
                </DialogTitle>
                <DialogContent>
                  {bulkResult.map((result) => (
                    <Box key={result.requestId} p={1}>
                      <Alert severity={result.result ? "success" : "error"}>
                        {result.result ? "成功" : "失敗"}{" "}
                        <Link
                          to={`/user_requests/${result.requestId}`}
                          target="_blank"
                        >
                          {result.requestId}
                        </Link>
                        : {result.userId} からの{" "}
                        <Chip
                          size="small"
                          label={getRequestType(result.requestType)}
                        />
                      </Alert>
                    </Box>
                  ))}
                </DialogContent>
                <DialogActions>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {progressValue !== bulkLength ? (
                      <Typography pl={4} fontSize="body1">
                        処理が完了するまで閉じないでください。
                      </Typography>
                    ) : (
                      <Box></Box>
                    )}
                    <Button
                      onClick={() => {
                        handleSubmit();
                        progressModalHandleClose();
                      }}
                      variant="outlined"
                    >
                      閉じる
                    </Button>
                  </Stack>
                </DialogActions>
              </Dialog>
            </Stack>
          </Stack>
        </Box>
      </Template>
      <JumboDrawer
        open={requestId !== undefined}
        anchor="right"
        onClose={() => navigate("/user_requests")}
      >
        <UserRequestDetailPanel requestId={requestId} handleReload={handleSubmit} />
      </JumboDrawer>
    </>
  );
};
export default UserRequestManagerPage;
