import {
  Box,
  Divider,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAdminSender } from "../api";
import { AxiosError } from "axios";

export const MediaUploadTab = () => {
  const [statusText, setStatusText] = useState<string | null>();
  const [filesLength, setFilesLength] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number | null>(null);
  const [file, setFile] = useState<FileList | null>(null);
  const { spotId } = useParams();
  const [userId, setUserId] = useState<string>("refloop_1705403019672");
  const [inputSpotId, setInputSpotId] = useState<string>(spotId ?? "");

  const { sender: prepareDataUpload, isLoading: isPrepareDataUploadLoading } =
    useAdminSender({
      apiName: "media_manager",
      serviceName: "default",
      operationId: "prepareMediaItemsUpload",
    });

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setFile(files);
    setStatusText(null);
    setProgressValue(null);
    setFilesLength(files?.length ?? 0);
  };

  const { sender: getUser, isLoading: isGettingUser } = useAdminSender({
    apiName: "user_manager",
    serviceName: "default",
    operationId: "getUser",
  });
  const { sender: getSpot, isLoading: isGettingSpot } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItem",
  });

  const onClickSubmit = async () => {
    if (!file) return;

    const user = await getUser({ userId: userId });
    if (!user) {
      if (
        window.confirm(
          "ユーザが存在しません\nユーザIDを確認してください\nこのまま登録しますか?"
        ) === false
      )
        return;
    }
    const spot = await getSpot({ spotId: inputSpotId });
    if (!spot) {
      if (
        window.confirm(
          "スポットが存在しません\nスポットIDを確認してください\nこのまま登録しますか?"
        ) === false
      )
        return;
    }

    setProgressValue(0);
    for (let i = 0; i < file.length; i++) {
      setStatusText(`${i + 1} / ${file.length} 件目を送信中...`);
      const res = await prepareDataUpload({
        userId: userId,
        spotId: inputSpotId,
        requestBody: [file[i].name],
      });
      if (!res) {
        setStatusText("送信に失敗しました");
        return;
      }

      const presignedUrl = res[0].presigned_url;

      fetch(`${presignedUrl}`, {
        method: "PUT",
        body: file[i],
        headers: {
          "Content-Type": file[i].type,
        },
      })
        .then((res) => {
          alert("送信しました");
        })
        .catch((e: AxiosError) => {
          console.error(e);
        });
      setProgressValue(i + 1);
    }
    setStatusText("送信完了しました");
  };
  return (
    <Stack height="100%" direction="row" spacing={2} pt={2}>
      <Stack spacing={1}>
        <Stack width={400} spacing={1}>
          <Stack border={(theme) => `1px solid ${theme.palette.divider}`}>
            <Stack
              p={1}
              bgcolor={(theme) => theme.palette.grey[100]}
              spacing={1}
              divider={<Divider />}
            >
              <Stack p={1} spacing={1}>
                <Typography>メディアをアップロード</Typography>
              </Stack>
              <Stack spacing={2} p={1}>
                <TextField
                  label="ユーザID"
                  placeholder={`ユーザID を入力...`}
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  disabled={isPrepareDataUploadLoading}
                />
                <TextField
                  label="スポットID"
                  placeholder={`スポットID を入力...`}
                  value={inputSpotId}
                  onChange={(e) => setInputSpotId(e.target.value)}
                  disabled={isPrepareDataUploadLoading || !!spotId}
                />
              </Stack>
              <Stack spacing={1}>
                <input
                  name="file"
                  type="file"
                  multiple
                  accept="*.csv"
                  onChange={onChangeFile}
                  disabled={
                    isPrepareDataUploadLoading || isGettingUser || isGettingSpot
                  }
                />
                <input
                  type="button"
                  disabled={
                    !file ||
                    !userId ||
                    !inputSpotId ||
                    isGettingUser ||
                    isGettingSpot
                  }
                  value="送信"
                  onClick={onClickSubmit}
                />
              </Stack>
              {progressValue && (
                <Stack>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={(progressValue / filesLength) * 100}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${Math.round(
                        (progressValue / filesLength) * 100
                      )}%`}</Typography>
                    </Box>
                  </Box>
                  <Typography pl={1}>{statusText}</Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default MediaUploadTab;
