/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 問い合わせのステータス
 */
export enum InquiryStatus {
    NEW = 'new',
    IN_PROGRESS = 'in_progress',
    RESOLVED = 'resolved',
    CLOSED = 'closed',
    REOPENED = 'reopened',
}
