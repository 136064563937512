import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import ReactJson from "react-json-view";
import { useNavigate, useParams } from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import { SpotEditor } from "./SpotEditor";
import { SpotItemRevisionList } from "./SpotItemRevisionList";

export const SpotRevisionTab = () => {
  const { spotId, revisionId } = useParams();
  const navigate = useNavigate();
  const onSelectRevision = (revisionId: string) => {
    if (!spotId) return;
    navigate(`/spots/${spotId}/revisions/${revisionId}`);
  };

  const {
    data: spotMetadata,
    mutate: reloadSpotMetadata,
    isLoading: isSpotMetaLoading,
  } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItem",
    requestParameters: { spotId: spotId ?? "" },
  });

  const {
    data: spotRevision,
    isLoading: spotLoading,
    mutate: reloadSpotRevision,
  } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItemRevision",
    requestParameters: { spotId: spotId ?? "", revisionId: revisionId ?? "" },
  });

  useEffect(() => {
    reloadSpotMetadata();
    reloadSpotRevision();
  }, [revisionId]);

  const {
    sender: publishSpotRevision,
    isLoading: isPublishing,
    error: publishError,
  } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "publishSpotRevision",
  });

  const { data: validationResult } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "validateSpotRevision",
    requestParameters: {
      requestBody: spotRevision ?? { spot_id: spotId ?? "" },
    },
  });
  const validationErrorItems = useMemo(() => {
    if (!validationResult) return [];
    if (validationResult === "__fetchSuccess__") return [];
    return validationResult;
  }, [validationResult]);

  const handlePublishSpotRevision = async () => {
    if (!spotId || !revisionId) return;
    await publishSpotRevision({
      spotId,
      revisionId,
    });
    await reloadSpotMetadata();
    await reloadSpotRevision();
  };

  const { sender: unpublishSpotRevision, isLoading: isUnpublishing } =
    useAdminSender({
      apiName: "spot_manager",
      serviceName: "default",
      operationId: "unpublishSpotRevision",
    });

  const handleUnpublishSpotRevision = async () => {
    if (!spotId || !revisionId) return;
    await unpublishSpotRevision({
      spotId,
      revisionId,
    });
    await reloadSpotMetadata();
    await reloadSpotRevision();
  };

  const { data: publishedRevision } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItemRevision",
    requestParameters: {
      spotId: spotId ?? "",
      revisionId: spotMetadata?.published_revision_id ?? "",
    },
  });

  const isRevisionPublished =
    spotRevision?.revision_id === spotMetadata?.published_revision_id;
  const isRevisionLatest =
    spotRevision?.revision_id === spotMetadata?.latest_revision_id;
  const [tab, setTab] = useState("compare");

  return (
    <>
      <Stack direction="row" width="100%" flexWrap="nowrap" spacing={1}>
        <Box width={"100%"} overflow='auto'>
          <Stack
            border={(theme) => `1px solid ${theme.palette.divider}`}
            divider={<Divider />}
          >
            {revisionId ? (
              <Stack bgcolor={(theme) => theme.palette.grey[100]}>
                <Stack
                  p={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {spotRevision ? (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography variant="body1" fontWeight="bold">
                        {spotRevision.revision_id}
                      </Typography>
                      {isRevisionPublished && (
                        <Chip label="公開中" size="small" color="success" />
                      )}
                      {isRevisionLatest && (
                        <Chip label="最新" size="small" color="info" />
                      )}
                    </Stack>
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {publishError && (
                      <Typography color="error">
                        公開に失敗しました。
                      </Typography>
                    )}
                    {isPublishing && <CircularProgress />}
                    <Button
                      variant="contained"
                      onClick={() => handlePublishSpotRevision()}
                      disabled={isRevisionPublished || isPublishing}
                    >
                      公開
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleUnpublishSpotRevision()}
                      color="error"
                      disabled={!isRevisionPublished || isUnpublishing}
                    >
                      非公開
                    </Button>
                  </Stack>
                </Stack>
                <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
                  <Tab label="比較" value="compare" />
                  <Tab label="データ" value="data" />
                  <Tab label="生データ" value="raw" />
                  <Tab label="プレビュー(実装予定)" value="preview" disabled />
                </Tabs>
              </Stack>
            ) : (
              <></>
            )}
            {!isSpotMetaLoading && !spotLoading && !revisionId ? (
              "リビジョンを選択してください"
            ) : (
              <>
                {tab === "compare" && (
                  <Box p={2} width="100%" overflow="scroll">
                    {spotId && publishedRevision && spotRevision && (
                      <SpotEditor
                        spotId={spotId}
                        baseData={publishedRevision}
                        comparingData={spotRevision}
                        editable={false}
                        baseTitle={`公開中(${publishedRevision.revision_id})`}
                        comparingTitle="更新対象"
                        resultingTitle="更新結果"
                        validationErrorItems={validationErrorItems}
                      />
                    )}
                    {spotId && publishedRevision == null && spotRevision && (
                      <SpotEditor
                        spotId={spotId}
                        comparingData={spotRevision}
                        editable={false}
                        baseTitle="公開中(なし)"
                        comparingTitle="追加対象"
                        resultingTitle="追加結果"
                        validationErrorItems={validationErrorItems}
                      />
                    )}
                  </Box>
                )}
                {tab === "data" && (
                  <Box p={2}>
                    {spotRevision && (
                      <ReactJson src={spotRevision} enableClipboard />
                    )}
                  </Box>
                )}
                {tab === "raw" && (
                  <Box p={2}>
                    <code>{JSON.stringify(spotRevision, undefined, " ")}</code>
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Box>
        <Box width="300px">
          <SpotItemRevisionList
            selectedRevisionId={revisionId}
            onSelectRevision={onSelectRevision}
            publishedRevisionId={
              spotMetadata?.published_revision_id ?? undefined
            }
            latestRevisionId={spotMetadata?.latest_revision_id ?? undefined}
          />
        </Box>
      </Stack>
    </>
  );
};
