/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationItem } from '../models/NotificationItem';
import type { NotificationItemQueryResponse } from '../models/NotificationItemQueryResponse';
import type { NotificationStatus } from '../models/NotificationStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * 最新順にお知らせリストを返します。
     * リストの上限はDynamoDBで一度にクエリできる容量に依存します。配信準備ができているお知らせリストの上限を超えて取得する場合は、ready となっているお知らせを fetched にして再度取得してください。
     * @returns NotificationItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getNotificationItems({
        status,
        limit = 100,
        lastEvaluatedKey,
    }: {
        status: NotificationStatus,
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<NotificationItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications',
            query: {
                'status': status,
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お知らせを取得します。
     * @returns NotificationItem Successful Response
     * @throws ApiError
     */
    public getNotificationItem({
        notificationId,
    }: {
        notificationId: string,
    }): CancelablePromise<NotificationItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications/{notification_id}',
            path: {
                'notification_id': notificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * お知らせのステータスを変更します。
     * @returns NotificationItem Successful Response
     * @throws ApiError
     */
    public updateNotificationItemStatus({
        notificationId,
        status,
    }: {
        notificationId: string,
        status: NotificationStatus,
    }): CancelablePromise<NotificationItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/notifications/{notification_id}/status/{status}',
            path: {
                'notification_id': notificationId,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
