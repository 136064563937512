/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserQueryParams } from '../models/UserQueryParams';
import type { UserQueryResponse } from '../models/UserQueryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * ヘルスチェックを行います。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getHealth(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/health',
        });
    }

    /**
     * ユーザの一覧を返します。
     * @returns UserQueryResponse Successful Response
     * @throws ApiError
     */
    public getUsers({
        limit = 100,
        lastEvaluatedKey,
        requestBody,
    }: {
        limit?: number,
        lastEvaluatedKey?: (string | null),
        requestBody?: (UserQueryParams | null),
    }): CancelablePromise<UserQueryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users',
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザを返します。
     * @returns User Successful Response
     * @throws ApiError
     */
    public getUser({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザを停止します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public disableUser({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{user_id}/disable',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザを有効化します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public cancelDisabledUser({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{user_id}/enable',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
