import { Close } from "@mui/icons-material";
import { Box, Drawer, DrawerProps, IconButton, Stack } from "@mui/material";

type JumboDrawerProps = { children?: React.ReactNode } & DrawerProps;
export const JumboDrawer = (props: JumboDrawerProps) => {
  return (
    <Drawer PaperProps={{ sx: { background: "none" } }} {...props}>
      <Stack width="100vw" direction="row" height="100vh">
        <Box
          minWidth={(theme) => theme.spacing(5)}
          onClick={(e) => props.onClose && props.onClose(e, "backdropClick")}
          p={1}
        >
          <IconButton
            color="default"
            size="small"
            sx={{ bgcolor: "#fff", ":hover": { bgcolor: "#fff" } }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          width="100%"
          height='100%'
          overflow='scroll'
          bgcolor={"#fff"}
          borderRadius={(theme) =>
            `${theme.shape.borderRadius * 2}px 0px 0px ${
              theme.shape.borderRadius * 2
            }px`
          }
        >
          {props.children}
        </Box>
      </Stack>
    </Drawer>
  );
};
