/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicSpotItemQueryResponse } from '../models/PublicSpotItemQueryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Admin Public Spot Items
     * @returns PublicSpotItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getAdminPublicSpotItems({
        limit = 100,
        lastEvaluatedKey,
    }: {
        limit?: number,
        lastEvaluatedKey?: string,
    }): CancelablePromise<PublicSpotItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/spots',
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
