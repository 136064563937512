/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnotatedLocation } from '../models/AnnotatedLocation';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * パラメータから緯度経度情報を取得します。
     * @returns AnnotatedLocation Successful Response
     * @throws ApiError
     */
    public getLocation({
        postalCode,
    }: {
        postalCode: string,
    }): CancelablePromise<AnnotatedLocation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/locator/location',
            query: {
                'postal_code': postalCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Extract Gmap Cid
     * @returns string Successful Response
     * @throws ApiError
     */
    public extractGmapCid({
        url,
    }: {
        url: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/gmap/extract_cid',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 例外処理を起こします
     * @returns any Successful Response
     * @throws ApiError
     */
    public raiseException(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/errors/raise_exception',
        });
    }

    /**
     * エラーログを出力します
     * @returns any Successful Response
     * @throws ApiError
     */
    public putErrorLog(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/errors/put_error_log',
        });
    }

    /**
     * タイムアウトを起こします
     * @returns any Successful Response
     * @throws ApiError
     */
    public raiseTimeout({
        secs = 900,
    }: {
        secs?: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/errors/raise_timeout',
            query: {
                'secs': secs,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
