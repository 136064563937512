import { Box } from "@mui/material";
import Template from "./Template";

const NotFoundPage = () => {
  return (
    <Template title="404 Not Found.">
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>

      </Box>
    </Template>
  );
};

export default NotFoundPage;
