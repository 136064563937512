/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaItem } from '../models/MediaItem';
import type { MediaItemQueryResponse } from '../models/MediaItemQueryResponse';
import type { MediaStatus } from '../models/MediaStatus';
import type { MediaUploadReference } from '../models/MediaUploadReference';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * メディアのアップロード先を作成します。
     * アップロード先のS3情報を返します。その情報を元にS3クライアントを使ってメディアをアップロードします。
     * @returns MediaUploadReference Successful Response
     * @throws ApiError
     */
    public prepareMediaItemsUpload({
        spotId,
        userId,
        requestBody,
    }: {
        spotId: string,
        userId: string,
        requestBody: Array<string>,
    }): CancelablePromise<Array<MediaUploadReference>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/media/prepare',
            query: {
                'spot_id': spotId,
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアを返します。
     * @returns MediaItem Successful Response
     * @throws ApiError
     */
    public getMediaItem({
        mediaId,
    }: {
        mediaId: string,
    }): CancelablePromise<MediaItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/media/{media_id}',
            path: {
                'media_id': mediaId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアを削除します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteMediaItem({
        mediaId,
        reason,
    }: {
        mediaId: string,
        reason?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/media/{media_id}',
            path: {
                'media_id': mediaId,
            },
            query: {
                'reason': reason,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットに紐づくメディアの一覧を返します。
     * 確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns MediaItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getMediaItemsBySpotId({
        spotId,
        limit,
        lastEvaluatedKey,
    }: {
        spotId: string,
        limit?: (number | null),
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<MediaItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots/{spot_id}/media',
            path: {
                'spot_id': spotId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザに紐づくメディアの一覧を返します。
     * 確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns MediaItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getMediaItemsByUserId({
        userId,
        limit,
        lastEvaluatedKey,
    }: {
        userId: string,
        limit?: (number | null),
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<MediaItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/media',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 月ごとにメディアの一覧を返します。
     * @returns MediaItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getMediaItemsByMonthId({
        monthId,
        limit,
        lastEvaluatedKey,
    }: {
        monthId?: (string | null),
        limit?: (number | null),
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<MediaItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/media',
            query: {
                'month_id': monthId,
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアを報告します。
     * @returns MediaItem Successful Response
     * @throws ApiError
     */
    public reportMediaItem({
        mediaId,
        reason,
    }: {
        mediaId: string,
        reason?: (string | null),
    }): CancelablePromise<MediaItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/media/{media_id}/report',
            path: {
                'media_id': mediaId,
            },
            query: {
                'reason': reason,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアを返します。
     * @returns MediaItem Successful Response
     * @throws ApiError
     */
    public getAdminMediaItem({
        mediaId,
        requestBody,
    }: {
        mediaId: string,
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/media/{media_id}',
            path: {
                'media_id': mediaId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 月ごとにメディアの一覧を返します。
     * @returns MediaItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getAdminMediaItemsByMonthId({
        monthId,
        limit,
        lastEvaluatedKey,
        requestBody,
    }: {
        monthId?: (string | null),
        limit?: (number | null),
        lastEvaluatedKey?: (string | null),
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItemQueryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/media',
            query: {
                'month_id': monthId,
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットに紐づくメディアの一覧を返します。
     * 確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns MediaItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getAdminMediaItemsBySpotId({
        spotId,
        limit,
        lastEvaluatedKey,
        requestBody,
    }: {
        spotId: string,
        limit?: (number | null),
        lastEvaluatedKey?: (string | null),
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItemQueryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/spots/{spot_id}/media',
            path: {
                'spot_id': spotId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザに紐づくメディアの一覧を返します。
     * 確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns MediaItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getAdminMediaItemsByUserId({
        userId,
        limit,
        lastEvaluatedKey,
        requestBody,
    }: {
        userId: string,
        limit?: (number | null),
        lastEvaluatedKey?: (string | null),
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItemQueryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/users/{user_id}/media',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアを復元します。
     * @returns MediaItem Successful Response
     * @throws ApiError
     */
    public restoreMediaItem({
        mediaId,
        reason,
        requestBody,
    }: {
        mediaId: string,
        reason?: (string | null),
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/media/{media_id}/restore',
            path: {
                'media_id': mediaId,
            },
            query: {
                'reason': reason,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアをアーカイブします。
     * @returns MediaItem Successful Response
     * @throws ApiError
     */
    public archiveMediaItem({
        mediaId,
        reason,
        requestBody,
    }: {
        mediaId: string,
        reason?: (string | null),
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/media/{media_id}/archive',
            path: {
                'media_id': mediaId,
            },
            query: {
                'reason': reason,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアを公開停止します。
     * @returns MediaItem Successful Response
     * @throws ApiError
     */
    public banMediaItem({
        mediaId,
        reason,
        requestBody,
    }: {
        mediaId: string,
        reason?: (string | null),
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/media/{media_id}/ban',
            path: {
                'media_id': mediaId,
            },
            query: {
                'reason': reason,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * メディアのキャプションを更新します。
     * @returns MediaItem Successful Response
     * @throws ApiError
     */
    public updateMediaNote({
        mediaId,
        note,
        citeUrl,
        requestBody,
    }: {
        mediaId: string,
        note: string,
        citeUrl?: (string | null),
        requestBody?: (Array<MediaStatus> | null),
    }): CancelablePromise<MediaItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/media/{media_id}/note',
            path: {
                'media_id': mediaId,
            },
            query: {
                'note': note,
                'cite_url': citeUrl,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
