import { AuthenticatorProps } from "@aws-amplify/ui-react";

const params: AuthenticatorProps = {
  components: {
    SignIn: {
      Footer: () => <></>,
    },
  },
  loginMechanisms: ["email"],
  hideSignUp: true,
  formFields: {
    signIn: {
      username: {
        label: "メールアドレス",
        placeholder: "メールアドレスを入力",
      },
      password: {
        label: "パスワード (小文字、数字、記号を含む10文字以上)",
        placeholder: "パスワードを入力",
      },
    },
    signUp: {
      email: {
        label: "メールアドレス",
        placeholder: "メールアドレスを入力",
        order: 1,
      },
      password: {
        label: "パスワード (小文字、数字、記号を含む10文字以上)",
        placeholder: "パスワードを入力",
        order: 3,
      },
      confirm_password: {
        label: "パスワードの確認",
        placeholder: "パスワードを再入力してください",
        order: 4,
      },
    },
  },
};

export default params