/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserRequestItem } from '../models/UserRequestItem';
import type { UserRequestItemCreate } from '../models/UserRequestItemCreate';
import type { UserRequestItemQueryResponse } from '../models/UserRequestItemQueryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * スポットのリクエストを作成します。
     * google_map_url が指定されている場合、既存のスポットと重複していないかチェックします。既存のスポットと重複している場合は409エラーを返します。
     * @returns UserRequestItem Successful Response
     * @throws ApiError
     */
    public createUserRequestItem({
        requestBody,
    }: {
        requestBody: UserRequestItemCreate,
    }): CancelablePromise<UserRequestItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user_requests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 最新順にスポットのリクエストのリストを返します。
     * 記録したデータの確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns UserRequestItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getUserRequestItemsMonthId({
        monthId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        monthId?: (string | null),
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<UserRequestItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user_requests',
            query: {
                'month_id': monthId,
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットのリクエストを取得します。
     * @returns UserRequestItem Successful Response
     * @throws ApiError
     */
    public getUserRequestItem({
        requestId,
    }: {
        requestId: string,
    }): CancelablePromise<UserRequestItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user_requests/{request_id}',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザごとのスポットのリクエストのリストを返します。
     * 記録したデータの確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns UserRequestItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getUserRequestItemsByUser({
        userId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        userId: string,
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<UserRequestItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/user_requests',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットごとのスポットのリクエストのリストを返します。
     * 記録したデータの確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns UserRequestItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getUserRequestItemsBySpot({
        spotId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        spotId: string,
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<UserRequestItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots/{spot_id}/user_requests',
            path: {
                'spot_id': spotId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
