/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicSpotItem } from '../models/PublicSpotItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * スポットの詳細情報を返します。
     * sc2-006302 などのスポットIDを指定します。
     * @returns PublicSpotItem Successful Response
     * @throws ApiError
     */
    public getPublicSpotItem({
        spotId,
    }: {
        spotId: string,
    }): CancelablePromise<PublicSpotItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots/{spot_id}',
            path: {
                'spot_id': spotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * @deprecated
     * [非推奨] スポットの詳細情報をリスト形式で返します。同じ機能のAPIである /bulk_spots を使用してください。
     * spot_id_list に "sc2-006302,sc2-006303" などのスポットIDをカンマ区切りで指定します。存在しないスポットIDはNoneになります。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPublicSpotItems({
        spotIdList,
    }: {
        spotIdList: string,
    }): CancelablePromise<Record<string, (PublicSpotItem | null)>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots',
            query: {
                'spot_id_list': spotIdList,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * スポットの詳細情報をリスト形式で返します。
     * spot_id_list に "sc2-006302,sc2-006303" などのスポットIDをカンマ区切りで指定します。存在しないスポットIDはNoneになります。
     * @returns any Successful Response
     * @throws ApiError
     */
    public bulkGetPublicSpotItems({
        spotIdList,
    }: {
        spotIdList: string,
    }): CancelablePromise<Record<string, (PublicSpotItem | null)>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bulk_spots',
            query: {
                'spot_id_list': spotIdList,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
