import {
  Box,
  Button,
  Chip,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAdminFetcherWithSwr } from "../api";
import { SpotSearchIndexResult } from "../generatedApi/adminSearchEngineApi";

type RecommendationCardProps = {
  spotId: string;
  distance?: number | null;
  isLoading: boolean;
  // (サンプル用) 都道府県ごとのサンプル画像を表示するために追加
  address_prefecture?: string | null | undefined;
};
const RecommendationCard = (props: RecommendationCardProps) => {
  const { spotId } = props;

  // 今回はメディアは全てサンプルのため、メディア用のspot_idを指定している
  const mediaSpotId = (() => {
    if (props.address_prefecture === "愛知県") {
      return "mockup_test_aichi";
    } else if (props.address_prefecture === "岐阜県") {
      return "mockup_test_gifu";
    }
    else if (props.address_prefecture === "三重県") {
      return "mockup_test_mie";
    }
    else if (props.address_prefecture === "静岡県") {
      return "mockup_test_shizuoka";
    } else {
      return "mockup_test";
    }
  })();

  // メディア情報取得
  const {
    data: mediaItem,
    mutate: reloadMedia,
    isLoading,
  } = useAdminFetcherWithSwr({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "getAdminMediaItemsBySpotId",
    requestParameters: { spotId: mediaSpotId },
  });

  // スポット情報取得
  const {
    data: spotMetadata,
    mutate: reloadSpotMetadata,
    error: spotMetadataError,
  } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItem",
    requestParameters: { spotId: spotId ?? "" },
  });

  const [published_revision_id, setPublishedRevisionId] = useState<string>("v000001");

  useEffect(() => {
    if (spotMetadata?.published_revision_id) {
      setPublishedRevisionId(spotMetadata.published_revision_id);
    }
  }, [spotMetadata]);

  const {
    data: spotRevision,
    isLoading: spotLoading,
    mutate: reloadSpotRevision,
  } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItemRevision",
    requestParameters: { spotId: spotId ?? "", revisionId: published_revision_id },
  });

  return (
    <Stack
      width={300}
      border={(theme) => `1px solid ${theme.palette.divider}`}
      p={1}
      spacing={1}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        {mediaItem?.items.map((media, key) => (
          media.sources && media.sources["vid480p"] ? (
            <video
              key={key}
              src={media.sources ? media.sources["vid480p"] : ""}
              width="100%"
              controls
            />
          ) : (
            <img
              key={key}
              src={media.sources ? media.sources["im480"] : ""}
              width="100%"
            />
          )
        ))}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h6" noWrap>
          {spotRevision?.name}
        </Typography>
      </Stack>
      <Box>
        <Chip label={"スポットID"} />
        <Link href={`/spots/${spotRevision?.spot_id}`} target="_blank" pl={1}>
          {spotRevision?.spot_id}
        </Link>
        {props.distance && (
          <Box padding={1}>
            <Chip label={"距離"} />
            <Typography variant="body2" pl={1}>
              {props.distance ? `${props.distance.toFixed(2)} km` : "未設定"}
            </Typography>
          </Box>)}
        <Box padding={1}>
          <Chip label={"住所"} size="small" />
          <Typography variant="body2" pl={1}>
            {spotRevision?.address}
          </Typography>
        </Box>
        <Box padding={1} >
          <Chip label={"ジャンル"} size="small" />
          <Typography variant="body2" pl={1} pb={1}>
            {!spotRevision?.genre ? "未設定" : spotRevision?.genre}
          </Typography>
          <Chip label={"サブジャンル"} size="small" />
          <Typography variant="body2" pl={1}>
            {!spotRevision?.subgenre ? "未設定" : spotRevision?.subgenre}
          </Typography>
        </Box>
        <Box padding={1}>
          <Chip label={"目的ジャンル"} size="small" />
          <Typography variant="body2" pl={1}>
            {!spotRevision?.mood_categories ? "未設定" : spotRevision?.mood_categories.map((mood, key) => (
              <div key={key}>{mood}</div>
            ))}
          </Typography>
        </Box>
        <Box padding={1}>
          <Chip label={"緯度経度"} size="small" />
          <Typography variant="body2" pl={1}>
            緯度: {spotRevision?.location?.lat}
          </Typography>
          <Typography variant="body2" pl={1}>
            経度: {spotRevision?.location?.lon}
          </Typography>
        </Box>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >

        </Stack>
        <Box padding={1}>
          <Chip label={"外部リンク"} size="small" />
          {spotRevision?.xlinks && spotRevision?.xlinks.map((url, key) => (
            <Box key={key}>
              <Link key={key} href={url} target="_blank" pl={1}>
                外部リンク{key + 1}
              </Link>
            </Box>
          ))}
          <Chip label={"SNS"} size="small" />
          {spotRevision?.social_links && spotRevision?.social_links.map((url, key) => (
            <Box key={key}>
              <Link key={key} href={url} target="_blank" variant="body2" pl={1}>
                {url.includes("instagram.com") ? "Instagram" : (
                  url.includes("twitter.com") ? "Twitter" : (
                    url.includes("google.com/maps") ? "Google Maps" : (
                      url.includes("tiktok.com") ? "TikTok" : url
                    )
                  ))}
              </Link>
            </Box>
          ))}
        </Box>
        <Box padding={1}>
          <Chip label={"営業情報"} size="small" />
          <Typography variant="body2" pl={1} pb={1}>
            {spotRevision?.opening_hours && spotRevision?.opening_hours.split("\n").map((line, key) => (
              <div key={key}>{line}</div>
            ))}
          </Typography>
          <Chip label={"定休日"} size="small" />
          <Typography variant="body2" pt={1} pl={1} pb={1}>
            {spotRevision?.holiday}
          </Typography>
          <Chip label={"電話番号"} size="small" />
          <Typography variant="body2" pt={1} pl={1}>
            {spotRevision?.phone_number}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

type RecommendationMockupListTabProps = {
  RecommendationItems: Array<SpotSearchIndexResult>;
  getMoreRecommend: () => void;
  isLoading: boolean;
};
export const RecommendationMockupListTab = (props: RecommendationMockupListTabProps) => {
  const { RecommendationItems } = props;

  return (
    <Stack
      spacing={1}
      border={(theme) => `1px solid ${theme.palette.divider}`}
      width="100%"
      height="100%"
    >
      <Stack direction="row" flexWrap="wrap" height="100%" width="100%" p={1}>
        {RecommendationItems.map((spot, key) => (
          <RecommendationCard
            key={key}
            spotId={spot.spot_id}
            distance={spot.distance}
            isLoading={props.isLoading}
            address_prefecture={spot.address_pref}
          />
        ))}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        p={1}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            props.getMoreRecommend();
          }}
        >
          次の推薦
        </Button>
      </Stack>
    </Stack>
  );
};
export default RecommendationMockupListTab;
