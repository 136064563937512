export function getJstFromUnixtime(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000); // Unixタイムスタンプをミリ秒に変換
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const japaneseDateTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  return japaneseDateTime;
}

export function queryStringToObject(queryString: string): Record<string, any> {
  const params = new URLSearchParams(queryString);
  const obj: Record<string, any> = {};

  params.forEach((value, key) => {
    const keys = key.split(".");
    const lastKey = keys.pop() as string;

    let target = obj;
    keys.forEach((k) => {
      target = target[k] = target[k] || {};
    });

    // JSON文字列かどうかをチェック
    try {
      target[lastKey] = JSON.parse(value);
    } catch (e) {
      // JSONパースエラーが発生した場合、そのままの文字列として設定
      target[lastKey] = value;
    }
  });

  return obj;
}

export function objectToQueryString(
  obj: Record<string, any>,
  parentKey: string = ""
): string {
  const queryString: string[] = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object") {
        // オブジェクトを JSON 文字列に変換
        const jsonValue = JSON.stringify(value);
        queryString.push(`${newKey}=${encodeURIComponent(jsonValue)}`);
      } else {
        // 単純な値
        queryString.push(`${newKey}=${encodeURIComponent(value)}`);
      }
    }
  }
  return queryString.join("&");
}

const objectSort = (obj: {[key: string]: any}): {[key: string]: any} => {
  if (typeof obj !== "object" || obj === null) return obj;

  if (Array.isArray(obj)) {
    return obj.map(objectSort);
  }

  return Object.keys(obj).sort().reduce((sortedObj, key) => {
    let value = obj[key];
    // undefined は null と同じ扱いにする
    // ふりがなの値がない場合、undefined になるため
    if (value === undefined) {value = null}
    sortedObj[key] = objectSort(value);
    return sortedObj;
  }, {} as {[key: string]: any});
}

const sortedJsonStringify = (obj: {[key: string]: any}): string => {
  const sortedObj = objectSort(obj);
  return JSON.stringify(sortedObj, null, 2);
}

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  return sortedJsonStringify(obj1) === sortedJsonStringify(obj2);
}