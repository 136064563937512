/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ViolationReportItem } from '../models/ViolationReportItem';
import type { ViolationReportItemCreate } from '../models/ViolationReportItemCreate';
import type { ViolationReportItemQueryResponse } from '../models/ViolationReportItemQueryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * 違反報告を記録します。
     * @returns ViolationReportItem Successful Response
     * @throws ApiError
     */
    public postViolationReportItem({
        requestBody,
    }: {
        requestBody: ViolationReportItemCreate,
    }): CancelablePromise<ViolationReportItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/violation_reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 月毎に最新順に違反報告のリストを返します。
     * 記録した違反報告の確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns ViolationReportItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getLatestViolationReportItemsByMonthId({
        monthId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        monthId?: (string | null),
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<ViolationReportItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/violation_reports',
            query: {
                'month_id': monthId,
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 違反報告を取得します。
     * @returns ViolationReportItem Successful Response
     * @throws ApiError
     */
    public getViolationReportItem({
        violationReportId,
    }: {
        violationReportId: string,
    }): CancelablePromise<ViolationReportItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/violation_reports/{violation_report_id}',
            path: {
                'violation_report_id': violationReportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 違反報告を削除します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteViolationReportItem({
        violationReportId,
    }: {
        violationReportId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/violation_reports/{violation_report_id}',
            path: {
                'violation_report_id': violationReportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 違反報告をクローズします。
     * @returns ViolationReportItem Successful Response
     * @throws ApiError
     */
    public closeViolationReportItem({
        violationReportId,
    }: {
        violationReportId: string,
    }): CancelablePromise<ViolationReportItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/violation_reports/{violation_report_id}/close',
            path: {
                'violation_report_id': violationReportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 違反報告から復元します。
     * @returns ViolationReportItem Successful Response
     * @throws ApiError
     */
    public recoverViolationReportItem({
        violationReportId,
    }: {
        violationReportId: string,
    }): CancelablePromise<ViolationReportItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/violation_reports/{violation_report_id}/recover',
            path: {
                'violation_report_id': violationReportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザー毎に最新順に違反報告のリストを返します。
     * 記録した違反報告の確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns ViolationReportItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getLatestViolationReportItemsByUserId({
        userId,
        limit = 100,
        lastEvaluatedKey,
    }: {
        userId: string,
        limit?: number,
        lastEvaluatedKey?: (string | null),
    }): CancelablePromise<ViolationReportItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/users/{user_id}/violation_reports',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'last_evaluated_key': lastEvaluatedKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
