import "@aws-amplify/ui-react/styles.css";
import Amplify from "aws-amplify";
import { render } from "react-dom";
import App from "./App";
import config from "./aws-exports";

import "./index.css";

Amplify.configure(config);

render(
  <App />,
  document.getElementById("root")
);
