/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 同行者属性
 */
export enum CompanionAttr {
    FAMILY = 'family',
    COUPLE = 'couple',
    FRIEND = 'friend',
    ALONE = 'alone',
}
