import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  LinearProgress,
  Radio,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { Check } from "@mui/icons-material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import {
  ImportJobStatus,
  UserImportJobQueryResponse,
} from "../generatedApi/userUserDataImporterApi";
import { getJstFromUnixtime } from "../utils";
import Template from "./Template";

const UserDataImportTab = () => {
  const { sender: prepareDataUpload, isLoading: isPrepareDataUploadLoading } =
    useAdminSender({
      apiName: "user_data_importer",
      serviceName: "default",
      operationId: "prepareDataUpload",
    });

  const [statusText, setStatusText] = useState<string | null>();
  const [filesLength, setFilesLength] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number | null>(null);
  const [file, setFile] = useState<FileList | null>(null);
  const [userId, setUserId] = useState<string>("");

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setFile(files);
    setStatusText(null);
    setProgressValue(null);
    setFilesLength(files?.length ?? 0);
  };

  const onClickSubmit = async () => {
    if (!file) return;

    setProgressValue(0);
    for (let i = 0; i < file.length; i++) {
      setStatusText(`${i + 1} / ${file.length} 件目を送信中...`);
      const res = await prepareDataUpload({
        userId: userId,
        jobType: "saved_google_map_spots",
        requestBody: [file[i].name],
      });
      if (!res) {
        setStatusText("送信に失敗しました");
        return;
      }

      const presignedUrl = res[0].presigned_url;

      fetch(`${presignedUrl}`, {
        method: "PUT",
        body: file[i],
        headers: {
          "Content-Type": file[i].type,
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((e: AxiosError) => {
          console.error(e);
        });
      setProgressValue(i + 1);
    }
    setStatusText("送信完了しました");
  };

  return (
    <Stack height="100%" direction="row" spacing={2} pt={2}>
      <Stack width={400} spacing={1}>
        <Stack border={(theme) => `1px solid ${theme.palette.divider}`}>
          <Stack
            p={1}
            bgcolor={(theme) => theme.palette.grey[100]}
            spacing={1}
            divider={<Divider />}
          >
            <Stack p={1} spacing={1}>
              <Typography>テストユーザデータインポート</Typography>
            </Stack>
            <Stack spacing={2} p={1}>
              <TextField
                label="ユーザID"
                placeholder={`ユーザID を入力...`}
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                disabled={isPrepareDataUploadLoading}
              />
            </Stack>
            <Box>
              <input
                name="file"
                type="file"
                multiple
                accept="*.csv"
                onChange={onChangeFile}
              />
              <input
                type="button"
                disabled={!file || !userId}
                value="送信"
                onClick={onClickSubmit}
              />
            </Box>
            {progressValue && (
              <Stack>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={(progressValue / filesLength) * 100}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${Math.round(
                      (progressValue / filesLength) * 100
                    )}%`}</Typography>
                  </Box>
                </Box>
                <Typography pl={1}>{statusText}</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const UserDataImportJobStatusJapanese = {
  [ImportJobStatus.PREPARED]: "送信待ち",
  [ImportJobStatus.UPLOADED]: "アップロード完了",
  [ImportJobStatus.PROCESSING]: "処理中",
  [ImportJobStatus.COMPLETED]: "処理完了",
  [ImportJobStatus.FAILED]: "処理失敗",
};

type UserDataImportDetailPanelProps = {
  jobId: string;
  handleSubmit: () => void;
};
const UserDataImportDetailPanel = (props: UserDataImportDetailPanelProps) => {
  const { jobId } = props;
  const {
    data: jobDetail,
    isLoading: isJobDetailLoading,
    mutate: reloadJobDetail,
    error: jobDetailError,
  } = useAdminFetcherWithSwr({
    apiName: "user_data_importer",
    serviceName: "default",
    operationId: "adminGetUserImportJob",
    requestParameters: { jobId: jobId },
  });

  const { sender: deleteUserImportJob, isLoading: isUserImportJobDeleting } =
    useAdminSender({
      apiName: "user_data_importer",
      serviceName: "default",
      operationId: "deleteUserImportJob",
    });
  const deleteJob = async (jobId: string) => {
    if (window.confirm("本当に削除しますか？") === false) return;
    await deleteUserImportJob({ jobId: jobId });
    await reloadJobDetail();
    await props.handleSubmit();
  };

  return (
    <>
      {jobDetailError}
      {isJobDetailLoading && <CircularProgress sx={{ m: 1 }} />}
      {!isJobDetailLoading && !jobDetail && (
        <Box p={1}>ジョブは削除されています。(ジョブID: {props.jobId})</Box>
      )}
      {jobDetail && (
        <Stack>
          <Stack
            bgcolor={(theme) => theme.palette.grey[100]}
            p={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography fontWeight="bold">{jobDetail.filename}</Typography>
            <Chip
              label={UserDataImportJobStatusJapanese[jobDetail.status]}
              size="small"
              variant="outlined"
            />
          </Stack>
          <Stack divider={<Divider />}>
            <Stack p={1}>
              <Typography variant="body1" fontWeight="bold">
                ジョブ詳細
              </Typography>
              <Box>
                <Chip label="ジョブ ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {jobDetail.job_id}
                </Typography>
              </Box>
              <Box>
                <Chip label="ユーザ ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {jobDetail.user_id}
                </Typography>
              </Box>
              <Box>
                <Chip label="ファイル名" size="small" />
                <Typography pl={1} pt={0.5}>
                  {jobDetail.filename}
                </Typography>
              </Box>
              <Box>
                <Chip label="ジョブタイプ" size="small" />
                <Typography pl={1} pt={0.5}>
                  {jobDetail.job_type}
                </Typography>
              </Box>
              <Box>
                <Chip label="作成日時" size="small" />
                <Typography pl={1} pt={0.5}>
                  {getJstFromUnixtime(jobDetail.timestamp_created / 1000)}
                </Typography>
              </Box>
              <Box>
                <Chip label="ステータス" size="small" />
                <Typography pl={1} pt={0.5}>
                  {UserDataImportJobStatusJapanese[jobDetail.status]}
                </Typography>
              </Box>
              {jobDetail.timestamp_started && (
                <Box>
                  <Chip label="開始日時" size="small" />
                  <Typography pl={1} pt={0.5}>
                    {getJstFromUnixtime(jobDetail.timestamp_started / 1000)}
                  </Typography>
                </Box>
              )}
              {jobDetail.timestamp_finished && (
                <Box>
                  <Chip label="終了日時" size="small" />
                  <Typography pl={1} pt={0.5}>
                    {getJstFromUnixtime(jobDetail.timestamp_finished / 1000)}
                  </Typography>
                </Box>
              )}
              <Box>
                <Chip label="月 ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {jobDetail.month_id}
                </Typography>
              </Box>
            </Stack>
            <Stack p={1}>
              <Typography variant="body2" fontWeight="bold">
                データ読み込み結果
              </Typography>
              {jobDetail.result && (
                <Box>
                  <Chip
                    label="スポットの総数 (total_item_count)"
                    size="small"
                  />
                  <Typography pl={1} pt={0.5}>
                    {jobDetail.result.total_item_count}
                  </Typography>
                  <Chip
                    label="refloop内のスポットとマッチした件数 (matched_item_count)"
                    size="small"
                  />
                  <Typography pl={1} pt={0.5}>
                    {jobDetail.result.matched_item_count}
                  </Typography>
                  <Chip
                    label="refloop内のスポットとマッチしなかった件数 (not_matched_item_count)"
                    size="small"
                  />
                  <Typography pl={1} pt={0.5}>
                    {jobDetail.result.not_matched_item_count}
                  </Typography>
                  <Chip
                    label="googleMapともヒットしなかった数 (not_found_item_count)"
                    size="small"
                  />
                  <Typography pl={1} pt={0.5}>
                    {jobDetail.result.not_found_item_count}
                  </Typography>
                </Box>
              )}
            </Stack>
            <Stack p={1}>
              <Box>
                <Button
                  onClick={() => deleteJob(jobDetail.job_id)}
                  variant="contained"
                  disabled={isUserImportJobDeleting}
                  size="small"
                  color="error"
                >
                  削除
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

const UserDataImporterPage = () => {
  const { jobId } = useParams();

  const navigate = useNavigate();
  const [queryParams, setSearchParams] = useSearchParams();

  const { sender: getUserImportJobsByUserId, isLoading: isLoading1 } =
    useAdminSender({
      apiName: "user_data_importer",
      serviceName: "default",
      operationId: "getUserImportJobsByUserId",
    });
  const { sender: getUserImportJobsByMonthId, isLoading: isLoading2 } =
    useAdminSender({
      apiName: "user_data_importer",
      serviceName: "default",
      operationId: "getUserImportJobsByMonthId",
    });

  const isLoading = isLoading1 || isLoading2;
  const [queryResult, setQueryResult] =
    useState<UserImportJobQueryResponse | null>(null);

  const handleSubmit = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: UserImportJobQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getUserImportJobsByMonthId({
          monthId: queryId,
        });
        break;
      case "user_id":
        res = await getUserImportJobsByUserId({
          userId: queryId,
        });
        break;
    }
    if (res === undefined) return;
    setQueryResult(res);
  };
  const [queryPartition, setQueryPartition] = useState<string>("month_id");
  const [queryId, setQueryId] = useState<string | null>(null);

  useEffect(() => {
    setQueryId(queryParams.get("id") ?? new Date().toISOString().slice(0, 7));
    setQueryPartition(queryParams.get("partition") ?? "month_id");
  }, []);

  const [moreReadButtonDisabled, setMoreReadButtonDisabled] = useState(false);
  useEffect(() => {
    if (queryResult?.last_evaluated_key) {
      setMoreReadButtonDisabled(true);
    } else {
      setMoreReadButtonDisabled(false);
    }
  }, [queryResult]);
  const [queryResult2, setQueryResult2] =
    useState<UserImportJobQueryResponse | null>(null);
  const getMoreReadUserImportJobs = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: UserImportJobQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getUserImportJobsByMonthId({
          monthId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
      case "user_id":
        res = await getUserImportJobsByUserId({
          userId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
    }
    if (res === undefined) return;
    if (queryResult === null) return;
    queryResult.items.push(...res.items);
    queryResult.last_evaluated_key = res.last_evaluated_key;
    if (!res.last_evaluated_key) {
      setMoreReadButtonDisabled(false);
    }
    setQueryResult2(queryResult);
  };
  useEffect(() => {
    setQueryResult(queryResult);
  }, [queryResult2]);

  const location = useLocation();
  const isUserDataImporterPage =
    location.pathname.split("/").slice(-1).includes("user_data_importer") ||
    (!location.pathname.split("/").slice(-1).includes("upload") &&
      location.pathname.split("/").slice(-2).includes("user_data_importer"));
  const isUserDataImportUploadPage = location.pathname
    .split("/")
    .slice(-1)
    .includes("upload");

  return (
    <Template title="ユーザデータ取り込みジョブ管理">
      <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs
          value={
            isUserDataImporterPage
              ? "userDataImport"
              : isUserDataImportUploadPage
              ? "userDataImportUpload"
              : ""
          }
          textColor="inherit"
        >
          <Tab
            label="ジョブ管理"
            onClick={() => navigate("/user_data_importer")}
            value="userDataImport"
          />
          <Tab
            label="登録"
            onClick={() => navigate("/user_data_importer/upload")}
            value="userDataImportUpload"
          />
        </Tabs>
      </AppBar>
      <Box component="main" sx={{ flex: 1, px: 2, bgcolor: "#eaeff1" }}>
        {isUserDataImportUploadPage && <UserDataImportTab />}
        {isUserDataImporterPage && (
          <Stack height="100%" direction="row" spacing={2} pt={2}>
            <Stack width={400} spacing={1}>
              <Stack border={(theme) => `1px solid ${theme.palette.divider}`}>
                <Stack
                  p={1}
                  bgcolor={(theme) => theme.palette.grey[100]}
                  spacing={1}
                  divider={<Divider />}
                >
                  <Stack p={1} spacing={1}>
                    <Typography>検索</Typography>
                    <Stack direction="row" spacing={1}>
                      {["month_id", "user_id"].map((partition, pkey) => (
                        <Chip
                          key={pkey}
                          label={partition}
                          onClick={() => {
                            setQueryPartition(partition);
                          }}
                          size="small"
                          variant={
                            queryPartition === partition ? "filled" : "outlined"
                          }
                          icon={
                            queryPartition === partition ? <Check /> : undefined
                          }
                        />
                      ))}
                    </Stack>
                  </Stack>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <Stack p={1} spacing={1}>
                      <TextField
                        label="ID"
                        placeholder={`${queryPartition} を入力...`}
                        value={queryId}
                        onChange={(e) => setQueryId(e.target.value)}
                        disabled={isLoading}
                      />
                      <Box>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          disabled={isLoading}
                        >
                          IDに該当するデータを取得
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                  <Typography>ジョブ一覧</Typography>
                </Stack>
                <Stack
                  pt={1}
                  bgcolor={(theme) => theme.palette.background.default}
                  divider={<Divider />}
                >
                  {isLoading && <CircularProgress />}
                  {queryResult?.items.length === 0 && (
                    <Box p={1}>ジョブはありません</Box>
                  )}
                  {queryResult?.items.map((userDataImportJob, nkey) => (
                    <Stack
                      onClick={() => {
                        navigate(
                          `/user_data_importer/${userDataImportJob.job_id}`
                        );
                      }}
                      key={nkey}
                      p={1}
                      sx={{ cursor: "pointer" }}
                      direction="row"
                    >
                      <Box>
                        <Radio checked={jobId === userDataImportJob.job_id} />
                      </Box>
                      <Stack>
                        <Typography fontWeight="bold" fontSize="body2">
                          {userDataImportJob.filename}
                        </Typography>
                        {userDataImportJob.status && (
                          <Box>
                            <Chip
                              label={
                                UserDataImportJobStatusJapanese[
                                  userDataImportJob.status
                                ]
                              }
                              size="small"
                            />
                          </Box>
                        )}
                        <Typography>
                          {getJstFromUnixtime(
                            userDataImportJob.timestamp_created / 1000
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                  {moreReadButtonDisabled && (
                    <Button onClick={() => getMoreReadUserImportJobs()}>
                      続きを読み込む
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack width="100%">
              <Stack
                border={(theme) => `1px solid ${theme.palette.divider}`}
                bgcolor={(theme) => theme.palette.background.default}
              >
                {!jobId && "ジョブを選択してください"}
                {jobId && (
                  <UserDataImportDetailPanel
                    jobId={jobId}
                    handleSubmit={handleSubmit}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Box>
    </Template>
  );
};

export default UserDataImporterPage;
