/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecommendationResult } from '../models/RecommendationResult';
import type { RecommenderQueryParams } from '../models/RecommenderQueryParams';
import type { SpotSearchQueryParams } from '../models/SpotSearchQueryParams';
import type { SpotSearchQueryResult } from '../models/SpotSearchQueryResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * 検索結果を返します。
     * @returns SpotSearchQueryResult Successful Response
     * @throws ApiError
     */
    public searchSpots({
        requestBody,
    }: {
        requestBody?: (SpotSearchQueryParams | null),
    }): CancelablePromise<SpotSearchQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 推薦結果を返します。
     * @returns RecommendationResult Successful Response
     * @throws ApiError
     */
    public getRecommendation({
        recommendationId,
        requestBody,
    }: {
        recommendationId?: (string | null),
        requestBody?: (RecommenderQueryParams | null),
    }): CancelablePromise<RecommendationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/recommend',
            query: {
                'recommendation_id': recommendationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 推薦結果を返します。
     * @returns RecommendationResult Successful Response
     * @throws ApiError
     */
    public debugGetRecommendation({
        recommendationId,
        requestBody,
    }: {
        recommendationId?: (string | null),
        requestBody?: (RecommenderQueryParams | null),
    }): CancelablePromise<RecommendationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/debug_recommend',
            query: {
                'recommendation_id': recommendationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Experimental Get Similar Users
     * @returns string Successful Response
     * @throws ApiError
     */
    public experimentalGetSimilarUsers({
        userId,
        size,
    }: {
        userId: string,
        size?: (number | null),
    }): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/similar',
            path: {
                'user_id': userId,
            },
            query: {
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
