import { Box, Divider, Stack } from "@mui/material";

import { useParams } from "react-router-dom";
import { useAdminFetcherWithSwr } from "../api";
import MediaListTab from "./MediaListTab";

export const SpotItemMediaTab = () => {
  const { spotId } = useParams();

  const {
    data,
    mutate: reloadMedia,
    isLoading,
  } = useAdminFetcherWithSwr({
    apiName: "media_manager",
    serviceName: "default",
    operationId: "getAdminMediaItemsBySpotId",
    requestParameters: { spotId: spotId ?? "" },
  });

  return (
    <Box component="main" sx={{ flex: 1 }} height="100%">
      <Stack
        bgcolor={(theme) => theme.palette.background.default}
        divider={<Divider />}
        width="100%"
        height="100%"
      >
        <Stack direction="row" flexWrap="wrap" spacing={1} height="100%">
          {data?.items.length === 0 && <Box>メディアがありません</Box>}
          {data?.items && data?.items.length !== 0 && (
            <MediaListTab
              MediaItems={data?.items}
              handleSubmit={reloadMedia}
              isLoading={isLoading}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
