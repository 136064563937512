/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * お知らせのステータス
 */
export enum NotificationStatus {
    DRAFT = 'draft',
    READY = 'ready',
    FETCHED = 'fetched',
    SENT = 'sent',
}
