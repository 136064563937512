import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useDebounce } from "@uidotdev/usehooks";

import { Add, ArrowDropDown, Clear, Sync } from "@mui/icons-material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import {
  SpotSearchIndexResult,
  SpotSearchQueryParams,
  SpotSearchQuerySortableField,
  SpotSortableOrder,
} from "../generatedApi/adminSearchEngineApi";
import { CancelablePromise } from "../generatedApi/spotManagerApi";
import { objectToQueryString, queryStringToObject } from "../utils";
import { callbackDraftPageAtom, callbackDraftPathAtom, callbackTabAtom } from "../atom";
import { useAtom, useSetAtom } from "jotai/react";

export const SpotItemPageDraftTab = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryName, setQueryName] = useState<string | null>(null);
  const [queryPhoneticName, setQueryPhoneticName] =
    useState<string | null>(null);
  const [queryLat, setQueryLat] = useState<number | null>(null);
  const [queryLon, setQueryLon] = useState<number | null>(null);
  const [querySortField, setQuerySortField] =
    useState<SpotSearchQuerySortableField | null>(null);
  const [querySortOrder, setQuerySortOrder] =
    useState<SpotSortableOrder | null>(null);

  const [queryPublishedRevision, setQueryPublishedRevision] =
    useState<boolean | null>(null);
  const [queryHasUnpublishedChanges, setQueryHasUnpublishedChanges] =
    useState<boolean | null>(null);

  const setCallbackDraftPath = useSetAtom(callbackDraftPathAtom);
  const [callbackDraftPage, setCallbackDraftPage] = useAtom(callbackDraftPageAtom);
  const setCallbackTab = useSetAtom(callbackTabAtom);

  const [querySize, setQuerySize] = useState<number>(10);
  const [queryPage, setQueryPage] = useState<number>(() => {
    const res = queryStringToObject(searchParams.toString());
    // queryPageがない場合は0を返す
    // Navigator などでページ遷移した場合にqueryPageがない場合があるため
    if (!res.page) {
      setCallbackDraftPath("");
      setCallbackDraftPage("0");
      return 0;
    }
    return callbackDraftPage ? Number(callbackDraftPage) : 0
  });
  useEffect(() => {
    setCallbackDraftPath(objectToQueryString(queryParams));
    setCallbackDraftPage(queryPage.toString());
  }, [queryPage]);


  useEffect(() => {
    const res = queryStringToObject(searchParams.toString());
    setQueryName(res.name ?? null);
    setQueryPhoneticName(res.phonetic_name ?? null);
    setQueryLat(res.location?.lat ?? null);
    setQueryLon(res.location?.lon ?? null);
    setQuerySortField(res.sort?.[0]?.field ?? null);
    setQuerySortOrder(res.sort?.[0]?.order ?? null);
    setQueryPublishedRevision(res.published_revision_id ?? null);
    setQueryHasUnpublishedChanges(res.has_unpublished_changes ?? null);
    setQuerySize(res.size ?? 10);
    setQueryPage(res.page ?? 0);
  }, []);

  const queryParams = useMemo<SpotSearchQueryParams>(() => {
    let res: any = { size: querySize, page: queryPage };
    if (queryName) {
      res = { ...res, name: queryName };
    }
    if (queryPhoneticName) {
      res = { ...res, phonetic_name: queryPhoneticName };
    }
    if (queryLat && queryLon) {
      res = { ...res, location: { lat: queryLat, lon: queryLon } };
    }
    if (querySortField && querySortOrder) {
      res = {
        ...res,
        sort: [{ field: querySortField, order: querySortOrder }],
      };
    }
    if (queryPublishedRevision !== null) {
      res = { ...res, published_revision_id: queryPublishedRevision };
    }
    if (queryHasUnpublishedChanges !== null) {
      res = { ...res, has_unpublished_changes: queryHasUnpublishedChanges };
    }
    const searchParams = objectToQueryString(res);
    setSearchParams(searchParams);

    return res;
  }, [
    querySize,
    queryPage,
    queryName,
    queryPhoneticName,
    queryLat,
    queryLon,
    querySortField,
    querySortOrder,
    queryPublishedRevision,
    queryHasUnpublishedChanges,
  ]);
  const debouncedQueryParams = useDebounce<SpotSearchQueryParams>(
    queryParams,
    500
  );

  const {
    data: spotItems,
    isLoading: loading,
    mutate: reloadSpotItems,
  } = useAdminFetcherWithSwr({
    apiName: "admin_search_engine",
    serviceName: "default",
    operationId: "searchDraftSpots",
    requestParameters: { requestBody: debouncedQueryParams },
  });

  const [selectionModel, setSelectionModel] =
    useState<SpotSearchIndexResult[] | undefined>();

  const [bulkMode, setBulkMode] = useState<"publish" | "unpublish">("publish");
  const [bulkMenuOpen, setBulkMenuOpen] = useState(false);
  const bulkMenuHandleClickOpen = () => {
    setBulkMenuOpen(true);
  };
  const bulkMenuHandleClose = () => {
    setBulkMenuOpen(false);
  };
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [bulkOpen, setBulkOpen] = useState(false);
  const bulkHandleClickOpen = () => {
    setBulkOpen(true);
  };
  const bulkHandleClose = () => {
    setBulkOpen(false);
  };

  const [bulkResult, setBulkResult] = useState<
    Array<{
      spotId: string;
      name: string;
      revisionId: string;
      result: boolean;
    }>
  >([]);

  const [bulkLength, setBulkLength] = useState<number>(0);
  const [bulkSuccessCount, setBulkSuccessCount] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number | null>(null);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const progressModalHandleClickOpen = () => {
    setProgressModalOpen(true);
  };
  const progressModalHandleClose = () => {
    setProgressModalOpen(false);
  };

  const { sender: publishSpotRevision } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "publishSpotRevision",
  });

  const { sender: unpublishSpotRevision } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "unpublishSpotRevision",
  });

  const onClickBulkPublish = async (mode: "publish" | "unpublish") => {
    if (!selectionModel) return;
    setProgressValue(null);
    setBulkLength(selectionModel.length);
    setBulkSuccessCount(0);
    let successCount = 0;
    let totalBulkResult = bulkResult;
    totalBulkResult = [];
    setBulkResult(totalBulkResult);
    for (let i = 0; i < selectionModel.length; i++) {
      const spotId = selectionModel[i].spot_id;
      const name = selectionModel[i].name;
      const revisionId = selectionModel[i].latest_revision_id;
      if (!spotId || !revisionId) {
        totalBulkResult.unshift({
          spotId,
          name,
          revisionId: "notfound",
          result: false,
        });
        totalBulkResult = [...totalBulkResult];
        setBulkResult(totalBulkResult);
        setProgressValue(i + 1);
        continue;
      }

      let res: CancelablePromise<any> | undefined;
      switch (mode) {
        case "publish":
          res = await publishSpotRevision({
            spotId,
            revisionId,
          });
          break;
        case "unpublish":
          res = await unpublishSpotRevision({
            spotId,
            revisionId,
          });
          break;
      }
      if (res === undefined) {
        totalBulkResult.unshift({
          spotId,
          name,
          revisionId,
          result: false,
        });
        totalBulkResult = [...totalBulkResult];
        setBulkResult(totalBulkResult);
        setProgressValue(i + 1);
        continue;
      }
      totalBulkResult.unshift({
        spotId,
        name,
        revisionId,
        result: true,
      });
      totalBulkResult = [...totalBulkResult];
      setBulkResult(totalBulkResult);
      setProgressValue(i + 1);
      successCount = successCount + 1;
    }
    setBulkSuccessCount(successCount);
  };

  return (
    <Stack height="100%" direction="row" spacing={2}>
      <Stack width={300} py={1} spacing={1}>
        <Stack spacing={1}>
          <Typography variant="body1" fontWeight="bold">
            検索
          </Typography>
        </Stack>
        <TextField
          variant="filled"
          label="スポット名"
          value={queryName ?? ""}
          onChange={(e) => setQueryName(e.target.value)}
        />
        <TextField
          variant="filled"
          label="読み仮名"
          value={queryPhoneticName ?? ""}
          onChange={(e) => setQueryPhoneticName(e.target.value)}
        />
        <TextField
          variant="filled"
          label="緯度"
          value={queryLat ?? ""}
          onChange={(e) => setQueryLat(Number(e.target.value))}
          type="number"
        />
        <TextField
          variant="filled"
          label="経度"
          value={queryLon ?? ""}
          onChange={(e) => setQueryLon(Number(e.target.value))}
          type="number"
        />
        <InputLabel id="sort-field-label">ソート名</InputLabel>
        <Select
          labelId="sort-field-label"
          label="ソート名"
          variant="filled"
          value={querySortField ?? ""}
          onChange={(e) => setQuerySortField(e.target.value as any)}
          endAdornment={
            <>
              <IconButton
                sx={{ display: querySortField ? "" : "none" }}
                onClick={() => setQuerySortField(null)}
              >
                <Clear />
              </IconButton>
            </>
          }
        >
          <MenuItem value={SpotSearchQuerySortableField.TIMESTAMP_CREATED}>
            作成日時
          </MenuItem>
          <MenuItem value={SpotSearchQuerySortableField.DISTANCE}>
            距離
          </MenuItem>
        </Select>
        <InputLabel id="sort-order-label">ソート順</InputLabel>
        <Select
          labelId="sort-order-label"
          label="ソート順"
          variant="filled"
          value={querySortOrder === null ? "" : querySortOrder}
          onChange={(e) => setQuerySortOrder(e.target.value as any)}
          endAdornment={
            <>
              <IconButton
                sx={{ display: querySortOrder ? "" : "none" }}
                onClick={() => setQuerySortOrder(null)}
              >
                <Clear />
              </IconButton>
            </>
          }
        >
          <MenuItem value={SpotSortableOrder.ASC}>昇順</MenuItem>
          <MenuItem value={SpotSortableOrder.DESC}>降順</MenuItem>
        </Select>
        <InputLabel id="published-revision-label">公開状態</InputLabel>
        <Select
          labelId="published-revision-label"
          label="公開状態"
          variant="filled"
          value={queryPublishedRevision === null ? "" : queryPublishedRevision}
          onChange={(e) => setQueryPublishedRevision(e.target.value as any)}
          endAdornment={
            <>
              <IconButton
                sx={{ display: queryPublishedRevision !== null ? "" : "none" }}
                onClick={() => setQueryPublishedRevision(null)}
              >
                <Clear />
              </IconButton>
            </>
          }
        >
          <MenuItem value={true as any}>公開中</MenuItem>
          <MenuItem value={false as any}>非公開</MenuItem>
        </Select>
        <InputLabel id="has-unpublished-changes-filter-label">
          未公開の更新
        </InputLabel>
        <Select
          labelId="has-unpublished-changes-filter-label"
          label="未公開の更新"
          variant="filled"
          value={
            queryHasUnpublishedChanges === null
              ? ""
              : queryHasUnpublishedChanges
          }
          onChange={(e) => setQueryHasUnpublishedChanges(e.target.value as any)}
          endAdornment={
            <>
              <IconButton
                sx={{
                  display: queryHasUnpublishedChanges !== null ? "" : "none",
                }}
                onClick={() => setQueryHasUnpublishedChanges(null)}
              >
                <Clear />
              </IconButton>
            </>
          }
        >
          <MenuItem value={true as any}>未公開の更新有り</MenuItem>
          <MenuItem value={false as any}>最新を公開済み</MenuItem>
        </Select>
      </Stack>
      <Box sx={{ width: "100%", height: "100%", py: 1 }}>
        <DataGrid
          loading={loading}
          sx={{ bgcolor: "#fff" }}
          rows={spotItems?.results ?? []}
          onSelectionModelChange={(RowId) => {
            const selectedRowId = new Set(RowId);
            const selectedRows = spotItems?.results.filter((dataGridRow) =>
              selectedRowId.has(dataGridRow.spot_id)
            );
            setSelectionModel(selectedRows);
          }}
          page={queryPage}
          rowCount={spotItems?.total ?? 0}
          paginationMode="server"
          disableColumnFilter
          pageSize={querySize}
          rowsPerPageOptions={[5, 10, 20, 100]}
          onPageChange={(page, details) => {
            setQueryPage(page);
          }}
          onPageSizeChange={(newPageSize) => setQuerySize(newPageSize)}
          columns={[
            {
              field: "spot_id",
              renderCell: (params) => (
                <Link
                  to={`/spots/${params.value}`}
                  onClick={() => {
                    setCallbackDraftPath(objectToQueryString(queryParams));
                    setCallbackDraftPage(queryPage.toString());
                    setCallbackTab("draft");
                  }}
                >
                  {params.value}
                </Link>
              ),
              sortable: false,
            },
            {
              field: "name",
              headerName: "スポット名",
              width: 300,
              sortable: false,
            },
            {
              width: 100,
              field: "published_revision_id",
              headerName: "公開状態",
              renderCell: (params) =>
                params.value ? `🟢 公開中(${params.value})` : "🔴 非公開",
              sortable: false,
            },
            {
              field: "latest_revision_id",
              headerName: "最新リビジョンID",
              renderCell: (params) => (params.value ? params.value : "未作成"),
              sortable: false,
            },
            {
              field: "has_unpublished_changes",
              headerName: "未公開の更新",
              width: 180,
              renderCell: (params) =>
                !params.row.latest_revision_id
                  ? "-"
                  : params.value
                    ? "⚠️ 未公開の更新あり"
                    : "✅ 最新を公開済み",
              sortable: false,
            },
          ]}
          getRowId={(row) => row.spot_id}
          checkboxSelection
          components={{
            Toolbar: () => (
              <GridToolbarContainer
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Stack direction="row">
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                  <GridToolbarExport />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    endIcon={<ArrowDropDown />}
                    disabled={
                      selectionModel?.length === 0 ||
                      selectionModel === undefined
                    }
                    ref={anchorEl}
                    onClick={bulkMenuHandleClickOpen}
                  >
                    一括操作
                  </Button>
                  <Menu
                    id="bulk-menu"
                    anchorEl={anchorEl.current}
                    open={bulkMenuOpen}
                    onClose={() => bulkMenuHandleClose()}
                  >
                    <MenuItem
                      onClick={() => {
                        setBulkMode("publish");
                        bulkMenuHandleClose();
                        bulkHandleClickOpen();
                      }}
                    >
                      一括公開
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setBulkMode("unpublish");
                        bulkMenuHandleClose();
                        bulkHandleClickOpen();
                      }}
                    >
                      一括非公開
                    </MenuItem>
                  </Menu>
                  <Button
                    variant="contained"
                    size="small"
                    color="inherit"
                    startIcon={<Sync />}
                    onClick={() => reloadSpotItems()}
                  >
                    更新
                  </Button>
                  <Button
                    startIcon={<Add />}
                    size="small"
                    variant="contained"
                    onClick={() => navigate("/spots/create")}
                  >
                    追加
                  </Button>
                </Stack>
              </GridToolbarContainer>
            ),
          }}
        />
        <Dialog
          open={bulkOpen}
          onClose={bulkHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            {bulkMode === "publish"
              ? "一括公開しますか?"
              : "一括非公開しますか?"}
            <br />
            <Typography pl={1} variant="body1">
              対象スポット数: {selectionModel?.length ?? 0}
              <br />
              対象一覧は以下の通りです。
            </Typography>
          </DialogTitle>
          <DialogContent>
            {selectionModel?.map((row) => (
              <Typography key={row.spot_id} pl={1}>
                {row.spot_id}: {row.name}
              </Typography>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={bulkHandleClose} variant="outlined">
              キャンセル
            </Button>
            <Button
              onClick={() => {
                onClickBulkPublish(bulkMode);
                progressModalHandleClickOpen();
                bulkHandleClose();
              }}
              variant="contained"
              autoFocus
            >
              {bulkMode === "publish" ? "一括公開" : "一括非公開"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={progressModalOpen}
          aria-labelledby="progres-dialog-title"
          aria-describedby="progress-dialog-description"
          fullWidth
          maxWidth="sm"
          sx={{ maxHeight: "80%" }}
        >
          <DialogTitle id="progress-dialog-title">
            {progressValue === bulkLength ? (
              <Typography fontSize="body1">
                完了({bulkLength}件中 {bulkSuccessCount}件 成功)
              </Typography>
            ) : (
              <Typography fontSize="body1">
                {bulkMode === "publish" ? "一括公開中..." : "一括非公開中..."}
              </Typography>
            )}
            <Stack>
              {progressValue && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={(progressValue / bulkLength) * 100}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${Math.round(
                      (progressValue / bulkLength) * 100
                    )}%`}</Typography>
                  </Box>
                </Box>
              )}
            </Stack>
          </DialogTitle>
          <DialogContent>
            {bulkResult.map((result) => (
              <Box key={result.spotId} p={1}>
                <Alert severity={result.result ? "success" : "error"}>
                  {result.result ? "成功" : "失敗"}{" "}
                  <Link to={`/spots/${result.spotId}`} target="_blank">
                    {result.spotId}
                  </Link>
                  : {result.name}
                </Alert>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" width="100%">
              {progressValue !== bulkLength ? (
                <Typography pl={4} fontSize="body1">
                  処理が完了するまで閉じないでください。
                </Typography>
              ) : (
                <Box></Box>
              )}
              <Button
                onClick={() => {
                  reloadSpotItems();
                  progressModalHandleClose();
                }}
                variant="outlined"
              >
                閉じる
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Box>
    </Stack>
  );
};
