/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InteractionItem } from '../models/InteractionItem';
import type { InteractionItemCreate } from '../models/InteractionItemCreate';
import type { InteractionItemQueryResponse } from '../models/InteractionItemQueryResponse';
import type { InteractionType } from '../models/InteractionType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * インタラクションを記録します。
     * インタラクションの種類は interaction_type で指定します。各タイプごとに必要なパラメータはモデル定義を参照してください。
     * @returns InteractionItem Successful Response
     * @throws ApiError
     */
    public createInteractionItem({
        requestBody,
    }: {
        requestBody: InteractionItemCreate,
    }): CancelablePromise<InteractionItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/interactions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Interaction Items
     * 最新順にインタラクションのリストを返します。
     * @returns InteractionItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getInteractionItems({
        monthId,
        lastEvaluatedKey,
        limit = 100,
    }: {
        monthId?: (string | null),
        lastEvaluatedKey?: string,
        limit?: number,
    }): CancelablePromise<InteractionItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/interactions',
            query: {
                'month_id': monthId,
                'last_evaluated_key': lastEvaluatedKey,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * インタラクションを取得します。
     * @returns InteractionItem Successful Response
     * @throws ApiError
     */
    public getInteractionItem({
        interactionId,
    }: {
        interactionId: string,
    }): CancelablePromise<InteractionItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/interactions/{interaction_id}',
            path: {
                'interaction_id': interactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * インタラクションを削除します。
     * @returns InteractionItem Successful Response
     * @throws ApiError
     */
    public deleteInteractionItem({
        interactionId,
    }: {
        interactionId: string,
    }): CancelablePromise<InteractionItem> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/interactions/{interaction_id}',
            path: {
                'interaction_id': interactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * 最新順に特定ユーザーのインタラクションのリストを返します。
     * 記録したインタラクションの確認用のAPIです。リストの上限はDynamoDBで一度にクエリできる容量に依存します。
     * @returns InteractionItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getInteractionItemsByUserId({
        userId,
        lastEvaluatedKey,
        limit = 100,
        interactionType,
    }: {
        userId: string,
        lastEvaluatedKey?: string,
        limit?: number,
        interactionType?: (InteractionType | null),
    }): CancelablePromise<InteractionItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/interactions',
            path: {
                'user_id': userId,
            },
            query: {
                'last_evaluated_key': lastEvaluatedKey,
                'limit': limit,
                'interaction_type': interactionType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Interaction Items By Spot Id
     * 最新順に特定スポットのインタラクションのリストを返します。
     * @returns InteractionItemQueryResponse Successful Response
     * @throws ApiError
     */
    public getInteractionItemsBySpotId({
        spotId,
        lastEvaluatedKey,
        limit = 100,
    }: {
        spotId: string,
        lastEvaluatedKey?: string,
        limit?: number,
    }): CancelablePromise<InteractionItemQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/spots/{spot_id}/interactions',
            path: {
                'spot_id': spotId,
            },
            query: {
                'last_evaluated_key': lastEvaluatedKey,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
