import {
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import {
  ApiName,
  apiHost,
  apiNameConstructorMap,
  targetEnv,
  useAdminFetcher,
  useAdminFetcherWithSwr,
} from "../api";
import Template from "./Template";

const ApiVersionRow = ({ apiName }: { apiName: ApiName }) => {
  const fetcher = useAdminFetcher();
  const tmp = useCallback(async () => {
    const { data, error } = await fetcher(
      "spot_manager",
      "default",
      "getSpotItems",
      {}
    );
  }, []);
  const {
    data: version,
    mutate: reload,
    error,
    isLoading,
  } = useAdminFetcherWithSwr({
    apiName,
    serviceName: "default",
    operationId: "getVersion",
    requestParameters: undefined,
  });

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {apiName}
      </TableCell>
      <TableCell>
        {isLoading && <Skeleton variant="text" />}
        {version && <span id={`${apiName}-version`}>🟢 {version}</span>}
        {error && <span>🔴 {error}</span>}
      </TableCell>
    </TableRow>
  );
};

const ApiVersionTable = () => {
  return (
    <Paper>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="space-between"
          px={2}
          py={1}
          borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
        >
          <Typography fontWeight="bold" gutterBottom>
            API
          </Typography>
        </Stack>
        <Box p={2}>
          <Typography>エンドポイント:{apiHost[targetEnv]}</Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={200}>API</TableCell>
                <TableCell>version</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(apiNameConstructorMap).map((apiName, ckey) => (
                // @ts-ignore
                <ApiVersionRow apiName={apiName} key={ckey} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  );
};

const Home = () => {
  return (
    <Template title="Refloop Dashboard">
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
        <Stack>
          <ApiVersionTable />
        </Stack>
      </Box>
    </Template>
  );
};

export default Home;
