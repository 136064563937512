import { ArrowDropDown } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import ReactJson from "react-json-view";
import { Link, useBlocker, useParams } from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import { SpotItemRevisionCreate } from "../generatedApi/spotManagerApi";
import { RequestType } from "../generatedApi/userRequestManagerApi";
import { SpotEditor } from "./SpotEditor";
import { areObjectsEqual } from "../utils";
import { editingSpotItemAtom } from "../atom";
import { useAtom } from "jotai/react";
import { RESET } from "jotai/utils";

type SpotItemEditTabProps = {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
};
export const SpotItemEditTab = (props: SpotItemEditTabProps) => {
  const { spotId } = useParams();
  const [tab, setTab] = useState("editor");

  const [valueAtom, setValueAtom] = useAtom(editingSpotItemAtom);
  const [value, setValue] =
    useState<SpotItemRevisionCreate | undefined>(() => {
      if (valueAtom?.id === spotId && valueAtom?.jsonStrinify) {
        return JSON.parse(valueAtom.jsonStrinify);
      }
    });
  useEffect(() => {
    setValueAtom({ id: spotId ?? "", jsonStrinify: JSON.stringify(value) });
  }, [value]);
  const parseValue = (value: string | object | undefined) => {
    switch (typeof value) {
      case "string": {
        try {
          return JSON.parse(value);
        } catch (e) {
          return { error: "JSONパースエラー" };
        }
      }
      case "object": {
        return value;
      }
    }
  };
  const debouncedValue = useDebounce<SpotItemRevisionCreate>(
    parseValue(value),
    500
  );

  const {
    sender: createUserRequest,
    isLoading: loading2,
    error: error2,
    data: createdUserRequest,
  } = useAdminSender({
    apiName: "user_request_manager",
    serviceName: "default",
    operationId: "createUserRequestItem",
  });
  const { data: validationResult } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "validateSpotRevision",
    requestParameters: {
      requestBody: value ?? { spot_id: spotId ?? "" },
    },
  });

  const validationErrorItems = useMemo(() => {
    if (!validationResult) return [];
    if (validationResult === "__fetchSuccess__") return [];
    return validationResult;
  }, [validationResult]);

  const [resultingDataDiff, setResultingDataDiff] =
    useState<SpotItemRevisionCreate | undefined>(undefined);

  const handleCreateUserRequest = async () => {
    const res = await createUserRequest({
      requestBody: {
        spot_id: spotId,
        target_revision_id: spotItem?.latest_revision_id,
        spot_name: spotItem?.name ?? "",
        user_id: "admin",
        comment: "管理画面から送信",
        request_type: RequestType.UPDATE,
        request_from: "admin",
        // @ts-ignore
        updating_fields: resultingDataDiff as object,
      },
    });
  };

  const handleDeleteUserRequest = async () => {
    await createUserRequest({
      requestBody: {
        spot_id: spotId,
        target_revision_id: spotItem?.latest_revision_id,
        spot_name: spotItem?.name ?? "",
        user_id: "admin",
        comment: "管理画面から送信",
        request_type: RequestType.DELETE,
        request_from: "admin",
      },
    });
  };

  const {
    sender: createSpotRevision,
    isLoading: loading,
    error: revisionCreateErrorRow,
    data: createdRevision,
  } = useAdminSender({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "createSpotRevision",
  });
  const revisionCreateError = useMemo<string | undefined>(() => {
    if (!revisionCreateErrorRow) return undefined;
    if (revisionCreateErrorRow.status === 409) {
      return "最新のリビジョンと同じ内容です";
    }
    if (revisionCreateErrorRow.status === 422) {
      return "不正な値が含まれています";
    }
    return String(revisionCreateErrorRow);
  }, [revisionCreateErrorRow]);

  const createSpotItemRevision = async () => {
    if (!spotId) return;
    await createSpotRevision({
      spotId: spotId,
      requestBody: debouncedValue,
    });
    await reloadSpotItem();
  };

  const { data: spotItem, mutate: reloadSpotItem } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItem",
    requestParameters: { spotId: spotId ?? "" },
    swrConfiguration: {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  });

  const { data: latestSpotItemRevision } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItemRevision",
    requestParameters: {
      spotId: spotId ?? "",
      revisionId: spotItem?.latest_revision_id ?? "v000000",
    },
    swrConfiguration: {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  });

  const latestRevisionId = spotItem?.latest_revision_id;

  useEffect(() => {
    if (latestSpotItemRevision) {
      setValue(latestSpotItemRevision);
    }
  }, [latestSpotItemRevision]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (!props.isEditing) return;
    window.addEventListener("beforeunload", handleBeforeUnloadEvent, true);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnloadEvent, true);
  }, [props.isEditing]);

  const blocker = useBlocker(props.isEditing);
  useEffect(() => {
    if (props.isEditing && blocker.state === "blocked") {
      if (
        window.confirm("編集中ですが、移動しますか？\n移動すると編集中のデータは破棄されます。") === false
      ) return;
    }
    blocker.proceed?.();
    setValueAtom(RESET)
    props.setIsEditing(false);
  }, [blocker]);


  useEffect(() => {
    if (areObjectsEqual(latestSpotItemRevision, value)) {
      props.setIsEditing(false);
    } else {
      props.setIsEditing(true);
    }
  }, [value]);

  return (
    <>
      <Stack
        border={(theme) => `1px solid ${theme.palette.divider}`}
        divider={<Divider />}
      >
        <TabContext value={tab}>
          <Stack bgcolor={(theme) => theme.palette.grey[100]}>
            <Stack
              p={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box p={1}>
                <Typography variant="body1" fontWeight="bold">
                  編集
                </Typography>
              </Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                {error2 && (
                  <Typography color="error">
                    スポットリクエストの作成に失敗しました
                  </Typography>
                )}
                {revisionCreateError && (
                  <Typography color="error">
                    {String(revisionCreateError)}
                  </Typography>
                )}
                {createdRevision && (
                  <Typography color="success">
                    リビジョン{createdRevision.revision_id}を作成しました
                  </Typography>
                )}
                {createdUserRequest && (
                  <Typography color="success">
                    <Link
                      to={`/user_requests/${createdUserRequest.request_id}`}
                      target="_blank"
                    >
                      リクエスト{createdUserRequest.request_id}
                    </Link>
                    を作成しました
                  </Typography>
                )}
                <ButtonGroup variant="contained">
                  <Button
                    disabled={loading}
                    onClick={() => createSpotItemRevision()}
                  >
                    リビジョンを作成
                  </Button>
                  <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleCreateUserRequest();
                    }}
                  >
                    スポット更新リクエストとして送信
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleDeleteUserRequest();
                    }}
                  >
                    スポット削除リクエストとして送信
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
            <TabList onChange={(e, newTab) => setTab(newTab)}>
              <Tab label="エディタ" value="editor" />
              <Tab label="JSON" value="json" />
            </TabList>
          </Stack>
          {!spotId && (
            <Box p={1}>
              <CircularProgress />
            </Box>
          )}
          {spotId && (
            <>
              <TabPanel value="editor">
                <Box p={0} width="100%" overflow="scroll">
                  {latestRevisionId && latestSpotItemRevision && (
                    <SpotEditor
                      spotId={spotId}
                      baseData={latestSpotItemRevision}
                      comparingData={value}
                      setResultingValue={setValue}
                      setResultingValueDiff={setResultingDataDiff}
                      validationErrorItems={validationErrorItems}
                    />
                  )}
                  {!latestRevisionId && (
                    <SpotEditor
                      spotId={spotId}
                      setResultingValue={setValue}
                      setResultingValueDiff={setResultingDataDiff}
                      validationErrorItems={validationErrorItems}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value="json">
                <Box p={1}>
                  <ReactJson
                    src={debouncedValue ?? {}}
                    onEdit={(edit) => {
                      setValue(edit.updated_src as SpotItemRevisionCreate);
                    }}
                  />
                </Box>
              </TabPanel>
            </>
          )}
        </TabContext>
      </Stack>
    </>
  );
};
