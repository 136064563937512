import { Check } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import {
  InteractionItemQueryResponse,
  InteractionType,
} from "../generatedApi/userUserInteractionLoggerApi";
import { getJstFromUnixtime } from "../utils";
import { JumboDrawer } from "./JumboDrawer";
import Template from "./Template";

type UserInteractionDetailPanelProps = { interactionId: string | undefined };
const UserInteractionDetailPanel = ({
  interactionId,
}: UserInteractionDetailPanelProps) => {
  const {
    data: interactionItem,
    isLoading,
    mutate: reloadInteractionItem,
  } = useAdminFetcherWithSwr({
    apiName: "user_interaction_logger",
    serviceName: "default",
    operationId: "getInteractionItem",
    requestParameters: { interactionId: interactionId ?? "" },
  });
  const { sender: deleteInteractionItem, isLoading: isDeleting } =
    useAdminSender({
      apiName: "user_interaction_logger",
      serviceName: "default",
      operationId: "deleteInteractionItem",
    });
  const handleDelete = async () => {
    await deleteInteractionItem({ interactionId: interactionId ?? "" });
    await reloadInteractionItem();
  };
  const isOperating = isDeleting || isLoading;
  return (
    <Stack>
      {!isLoading && !interactionItem && (
        <Box p={2}>インタラクションが見つかりません</Box>
      )}
      {interactionItem && (
        <Stack
          p={2}
          borderBottom={1}
          borderColor="divider"
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h5">
            インタラクション詳細
            <Chip label={interactionItem.interaction_type} />
          </Typography>
          <Stack>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => handleDelete()}
              disabled={isOperating}
            >
              削除
            </Button>
          </Stack>
        </Stack>
      )}
      {interactionItem && (
        <Stack p={2}>
          <ReactJson src={interactionItem} />
        </Stack>
      )}
    </Stack>
  );
};

export const UserInteractionLoggerPage = () => {
  const navigate = useNavigate();
  const { interactionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryInteractionType, setQueryInteractionType] = useState<
    InteractionType | null | undefined
  >(null);
  useEffect(() => {
    console.log(searchParams.toString());
  }, [searchParams]);

  const { sender: getInteractionItems, isLoading: isLoading1 } = useAdminSender(
    {
      apiName: "user_interaction_logger",
      serviceName: "default",
      operationId: "getInteractionItems",
    }
  );

  const { sender: getInteractionItemsByUserId, isLoading: isLoading2 } =
    useAdminSender({
      apiName: "user_interaction_logger",
      serviceName: "default",
      operationId: "getInteractionItemsByUserId",
    });

  const { sender: getInteractionItemsBySpotId, isLoading: isLoading3 } =
    useAdminSender({
      apiName: "user_interaction_logger",
      serviceName: "default",
      operationId: "getInteractionItemsBySpotId",
    });

  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const [queryResult, setQueryResult] =
    useState<InteractionItemQueryResponse | null>(null);

  const handleSubmit = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: InteractionItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getInteractionItems({ monthId: queryId });
        break;
      case "user_id":
        res = await getInteractionItemsByUserId({
          userId: queryId,
          interactionType: queryInteractionType,
        });
        break;
      case "spot_id":
        res = await getInteractionItemsBySpotId({ spotId: queryId });
        break;
    }
    if (res === undefined) return;
    console.log(res);
    setQueryResult(res);
  };

  const [queryPartition, setQueryPartition] = useState<string>("month_id");
  const [queryId, setQueryId] = useState<string | null>(null);

  useEffect(() => {
    setQueryId(searchParams.get("id") ?? new Date().toISOString().slice(0, 7));
    setQueryPartition(searchParams.get("partition") ?? "month_id");
  }, []);

  return (
    <>
      <Template title="ユーザ行動記録">
        <AppBar
          component="div"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Tabs value={"logs"} textColor="inherit">
            <Tab label="記録一覧" onClick={() => {}} value="users" />
          </Tabs>
        </AppBar>
        <Box component="main" sx={{ flex: 1, p: 2, bgcolor: "#eaeff1" }}>
          <Stack direction="row" spacing={1} height="100%">
            <Stack
              border={(theme) => `1px solid ${theme.palette.divider}`}
              minWidth={300}
              bgcolor={(theme) => theme.palette.background.default}
            >
              <Stack divider={<Divider />}>
                <Stack p={1} spacing={1}>
                  <Typography>検索</Typography>
                  <Stack direction="row" spacing={1}>
                    {["month_id", "user_id", "spot_id"].map(
                      (partition, pkey) => (
                        <Chip
                          key={pkey}
                          label={partition}
                          onClick={() => {
                            setQueryPartition(partition);
                          }}
                          size="small"
                          variant={
                            queryPartition === partition ? "filled" : "outlined"
                          }
                          icon={
                            queryPartition === partition ? <Check /> : undefined
                          }
                        />
                      )
                    )}
                  </Stack>
                </Stack>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Stack p={1} spacing={1}>
                    <Typography variant="body2">ID</Typography>
                    <TextField
                      placeholder={`${queryPartition} を入力...`}
                      value={queryId}
                      onChange={(e) => setQueryId(e.target.value)}
                      disabled={isLoading}
                    />
                    {queryPartition === "user_id" && (
                      <>
                        <Typography variant="body2">種別の絞り込み</Typography>
                        <Select
                          value={queryInteractionType ?? "なし"}
                          onChange={(e) =>
                            e.target.value === "null"
                              ? setQueryInteractionType(null)
                              : setQueryInteractionType(
                                  e.target.value as InteractionType | undefined
                                )
                          }
                        >
                          <MenuItem value={"null"}>なし</MenuItem>
                          {Object.values(InteractionType).map((type, key) => (
                            <MenuItem value={type} key={key}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}

                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        disabled={isLoading}
                      >
                        IDに該当するデータを取得
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </Stack>
            </Stack>
            <Stack width="100%" height="100%">
              <DataGrid
                rows={queryResult?.items ?? []}
                sx={{ bgcolor: "#fff" }}
                loading={isLoading}
                getRowId={(row) => row.interaction_id}
                columns={[
                  {
                    field: "interaction_id",
                    renderCell: (params) => (
                      <Link
                        to={`/interactions/${
                          params.value
                        }?${searchParams.toString()}`}
                      >
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: "interaction_type",
                    renderCell: (params) => (
                      <Chip label={params.value} size="small" />
                    ),
                  },
                  {
                    field: "user_id",
                    renderCell: (params) => (
                      <Link target="_blank" to={`/users/${params.value}`}>
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: "spot_id",
                    renderCell: (params) => (
                      <Link target="_blank" to={`/spots/${params.value}`}>
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: "timestamp_created",
                    headerName: "作成日時",
                    width: 180,
                    renderCell: (params) =>
                      getJstFromUnixtime(params.value / 1000),
                  },
                ]}
              />
            </Stack>
          </Stack>
        </Box>
      </Template>
      <JumboDrawer
        open={interactionId !== undefined}
        anchor="right"
        onClose={() => navigate(`/interactions?${searchParams.toString()}`)}
      >
        <UserInteractionDetailPanel interactionId={interactionId} />
      </JumboDrawer>
    </>
  );
};
export default UserInteractionLoggerPage;
