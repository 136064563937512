/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * スポットのソート順
 */
export enum SpotSortableOrder {
    ASC = 'asc',
    DESC = 'desc',
}
