import {
  Alert,
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Radio,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { Check } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import { ViolationReportItemQueryResponse } from "../generatedApi/violationReportManagerApi";
import { getJstFromUnixtime } from "../utils";
import Template from "./Template";

const ViolationReportStatusJapanese = {
  opened: "未処理",
  closed: "処理済",
  recovered: "再表示済",
};

type ViolationReportDetailPanelProps = {
  violationReportId: string;
  handleSubmit: () => void;
  isLoading: boolean;
};
const ViolationReportDetailPanel = (props: ViolationReportDetailPanelProps) => {
  const { violationReportId, isLoading } = props;
  const {
    data: violationReport,
    isLoading: isViolationReportLoading,
    mutate: reloadViolationReport,
    error: violationReportError,
  } = useAdminFetcherWithSwr({
    apiName: "violation_report_manager",
    serviceName: "default",
    operationId: "getViolationReportItem",
    requestParameters: { violationReportId: violationReportId },
  });

  const { sender: closeViolationReportItem, isLoading: isClose } =
    useAdminSender({
      apiName: "violation_report_manager",
      serviceName: "default",
      operationId: "closeViolationReportItem",
    });
  const handleCloseButtonClick = async () => {
    if (violationReportId === null) return;
    const res = await closeViolationReportItem({
      violationReportId: violationReportId,
    });
    if (res === undefined) return;
    await reloadViolationReport();
    props.handleSubmit();
  };

  const {
    sender: recoverViolationReportItem,
    isLoading: isRecover,
    error: recoverError,
  } = useAdminSender({
    apiName: "violation_report_manager",
    serviceName: "default",
    operationId: "recoverViolationReportItem",
  });

  const handleRecoverButtonClick = async () => {
    if (violationReportId === null) return;
    const res = await recoverViolationReportItem({
      violationReportId: violationReportId,
    });
    if (res !== undefined && res.status === "recovered") {
      alert(`${res.save_visit_id} を復元しました。`);
    } else {
      alert("復元に失敗しました。管理者に問い合わせてください。");
    }
    await reloadViolationReport();
    props.handleSubmit();
  };
  useEffect(() => {
    console.warn(recoverError);
  }, [recoverError]);

  return (
    <>
      {violationReportError}
      {isViolationReportLoading && <CircularProgress sx={{ m: 1 }} />}
      {!isViolationReportLoading && !violationReport && (
        <Box p={1}>
          クチコミ違反報告は削除されています。(クチコミ違反報告ID:{" "}
          {props.violationReportId})
        </Box>
      )}
      {violationReport && (
        <Stack>
          <Stack
            bgcolor={(theme) => theme.palette.grey[100]}
            p={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography fontWeight="bold">
              {violationReport.save_visit_id}
            </Typography>
            {violationReport.status && (
              <Chip
                label={ViolationReportStatusJapanese[violationReport.status]}
                size="small"
                variant="outlined"
              />
            )}
          </Stack>
          <Stack divider={<Divider />}>
            <Stack p={1}>
              <Typography variant="body1" fontWeight="bold">
                クチコミ違反報告詳細
              </Typography>
              <Box>
                <Chip label="クチコミ違反報告 ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {props.violationReportId}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="ユーザー ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {violationReport.user_id}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="訪問記録 ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {violationReport.save_visit_id}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="違反報告理由" size="small" />
                <Typography pl={1} pt={0.5}>
                  {violationReport.reason}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="違反報告内容" size="small" />
                <Typography pl={1} pt={0.5}>
                  {violationReport.body["key"]}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="作成日時" size="small" />
                <Typography pl={1} pt={0.5}>
                  {getJstFromUnixtime(violationReport.timestamp_created / 1000)}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="月 ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {violationReport.month_id}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="ステータス" size="small" />
                <Typography pl={1} pt={0.5}>
                  {violationReport.status &&
                    ViolationReportStatusJapanese[violationReport.status]}
                </Typography>
              </Box>
            </Stack>
            <Stack p={1} direction="row" alignItems="center" spacing={1}>
              <Box>
                <Button
                  onClick={() => handleCloseButtonClick()}
                  variant="contained"
                  disabled={
                    isViolationReportLoading ||
                    isClose ||
                    isRecover ||
                    isLoading
                  }
                  size="small"
                  color="primary"
                >
                  クローズ
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => handleRecoverButtonClick()}
                  variant="contained"
                  disabled={
                    isViolationReportLoading ||
                    isClose ||
                    isRecover ||
                    isLoading
                  }
                  size="small"
                  color="primary"
                >
                  復元
                </Button>
              </Box>
              {recoverError && (
                <Alert severity="error">{String(recoverError)}</Alert>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

const ViolationReportManagerPage = () => {
  const { violationReportId: violationReportId } = useParams();

  const navigate = useNavigate();
  const [queryParams, setSearchParams] = useSearchParams();

  const { sender: getViolationReportItemsByMonthId, isLoading: isLoading1 } =
    useAdminSender({
      apiName: "violation_report_manager",
      serviceName: "default",
      operationId: "getLatestViolationReportItemsByMonthId",
    });
  const { sender: getViolationReportItemsByUserId, isLoading: isLoading2 } =
    useAdminSender({
      apiName: "violation_report_manager",
      serviceName: "default",
      operationId: "getLatestViolationReportItemsByUserId",
    });

  const isLoading = isLoading1 || isLoading2;
  const [queryResult, setQueryResult] =
    useState<ViolationReportItemQueryResponse | null>(null);

  const handleSubmit = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: ViolationReportItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getViolationReportItemsByMonthId({
          monthId: queryId,
        });
        break;
      case "user_id":
        res = await getViolationReportItemsByUserId({
          userId: queryId,
        });
        break;
    }
    if (res === undefined) return;
    setQueryResult(res);
  };
  const [queryPartition, setQueryPartition] = useState<string>("month_id");
  const [queryId, setQueryId] = useState<string | null>(null);

  useEffect(() => {
    setQueryId(queryParams.get("id") ?? new Date().toISOString().slice(0, 7));
    setQueryPartition(queryParams.get("partition") ?? "month_id");
  }, []);

  const [moreReadButtonDisabled, setMoreReadButtonDisabled] = useState(false);
  useEffect(() => {
    if (queryResult?.last_evaluated_key) {
      setMoreReadButtonDisabled(true);
    } else {
      setMoreReadButtonDisabled(false);
    }
  }, [queryResult]);
  const [queryResult2, setQueryResult2] =
    useState<ViolationReportItemQueryResponse | null>(null);
  const getMoreReadViolationReportItems = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: ViolationReportItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getViolationReportItemsByMonthId({
          monthId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
      case "user_id":
        res = await getViolationReportItemsByUserId({
          userId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
    }
    if (res === undefined) return;
    if (queryResult === null) return;
    const totalQueryResult = queryResult;
    totalQueryResult.items = [...totalQueryResult.items, ...res.items];
    totalQueryResult.last_evaluated_key = res.last_evaluated_key;
    if (!res.last_evaluated_key) {
      setMoreReadButtonDisabled(false);
    }
    setQueryResult2(totalQueryResult);
  };
  useEffect(() => {
    if (queryResult2 === null) return;
    const newResult: ViolationReportItemQueryResponse = { ...queryResult2 };
    setQueryResult(newResult);
  }, [queryResult2]);

  return (
    <Template title="クチコミ違反報告管理">
      <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs value={"violationReports"} textColor="inherit">
          <Tab
            label="クチコミ違反報告一覧"
            onClick={() => {}}
            value="violationReports"
          />
        </Tabs>
      </AppBar>
      <Box component="main" sx={{ flex: 1, px: 2, bgcolor: "#eaeff1" }}>
        <Stack height="100%" direction="row" spacing={2} pt={2}>
          <Stack width={400} spacing={1}>
            <Stack border={(theme) => `1px solid ${theme.palette.divider}`}>
              <Stack
                p={1}
                bgcolor={(theme) => theme.palette.grey[100]}
                spacing={1}
                divider={<Divider />}
              >
                <Stack p={1} spacing={1}>
                  <Typography>検索</Typography>
                  <Stack direction="row" spacing={1}>
                    {["month_id", "user_id"].map((partition, pkey) => (
                      <Chip
                        key={pkey}
                        label={partition}
                        onClick={() => {
                          setQueryPartition(partition);
                        }}
                        size="small"
                        variant={
                          queryPartition === partition ? "filled" : "outlined"
                        }
                        icon={
                          queryPartition === partition ? <Check /> : undefined
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Stack p={1} spacing={1}>
                    <TextField
                      label="ID"
                      placeholder={`${queryPartition} を入力...`}
                      value={queryId}
                      onChange={(e) => setQueryId(e.target.value)}
                      disabled={isLoading}
                    />
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        disabled={isLoading}
                      >
                        IDに該当するデータを取得
                      </Button>
                    </Box>
                  </Stack>
                </form>
                <Typography>クチコミ違反報告一覧</Typography>
              </Stack>
              <Stack
                pt={1}
                bgcolor={(theme) => theme.palette.background.default}
                divider={<Divider />}
              >
                {isLoading && <CircularProgress />}
                {queryResult?.items.length === 0 && (
                  <Box p={1}>クチコミの違反報告はありません</Box>
                )}
                {queryResult?.items.map((violationReport, nkey) => (
                  <Stack
                    onClick={() => {
                      navigate(
                        `/violation_reports/${violationReport.violation_report_id}`
                      );
                    }}
                    key={nkey}
                    p={1}
                    sx={{ cursor: "pointer" }}
                    direction="row"
                  >
                    <Box>
                      <Radio
                        checked={
                          violationReportId ===
                          violationReport.violation_report_id
                        }
                      />
                    </Box>
                    <Stack>
                      <Typography fontWeight="bold">
                        {violationReport.save_visit_id}
                      </Typography>
                      {violationReport.status && (
                        <Box>
                          <Chip
                            label={
                              ViolationReportStatusJapanese[
                                violationReport.status
                              ]
                            }
                            size="small"
                          />
                        </Box>
                      )}
                      <Typography>
                        {getJstFromUnixtime(
                          violationReport.timestamp_created / 1000
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
                {moreReadButtonDisabled && (
                  <Button onClick={() => getMoreReadViolationReportItems()}>
                    続きを読み込む
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack width="100%">
            <Stack
              border={(theme) => `1px solid ${theme.palette.divider}`}
              bgcolor={(theme) => theme.palette.background.default}
            >
              {!violationReportId && "クチコミ違反報告を選択してください"}
              {violationReportId && (
                <ViolationReportDetailPanel
                  violationReportId={violationReportId}
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Template>
  );
};

export default ViolationReportManagerPage;
