import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import {
  RequestStatus,
  RequestType,
} from "../generatedApi/userRequestManagerApi";
import { SpotEditor } from "./SpotEditor";
import {
  getMergeText,
  getRequestStatus,
  getRequestType,
} from "./UserRequestManagerPage";
export type UserRequestDetailPanelProps = {
  requestId: string | undefined;
  handleReload: () => void;
};

export const UserRequestDetailPanel = ({
  requestId,
  handleReload,
}: UserRequestDetailPanelProps) => {
  const { data: requestItem, mutate: reloadRequestItem } =
    useAdminFetcherWithSwr({
      apiName: "user_request_manager",
      serviceName: "default",
      operationId: "getUserRequestItem",
      requestParameters: { requestId: requestId ?? "" },
    });
  const {
    data: spotMetadata,
    mutate: reloadSpotMetadata,
    isLoading: isSpotMetaLoading,
  } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItem",
    requestParameters: { spotId: requestItem?.spot_id ?? "" },
  });
  const { data: publishedRevision } = useAdminFetcherWithSwr({
    apiName: "spot_manager",
    serviceName: "default",
    operationId: "getSpotItemRevision",
    requestParameters: {
      spotId: requestItem?.spot_id ?? "",
      revisionId: spotMetadata?.published_revision_id ?? "",
    },
  });
  const { sender: mergeRequestItem, isLoading: isMerging } = useAdminSender({
    apiName: "user_request_manager",
    serviceName: "admin",
    operationId: "adminMergeUserRequestItem",
  });

  const { sender: closeRequestItem, isLoading: isRejecting } = useAdminSender({
    apiName: "user_request_manager",
    serviceName: "admin",
    operationId: "adminCloseUserRequestItem",
  });
  const { sender: reopenRequestItem, isLoading: isReopening } = useAdminSender({
    apiName: "user_request_manager",
    serviceName: "admin",
    operationId: "adminReopenUserRequestItem",
  });
  const handleMergeRequestItem = async () => {
    await mergeRequestItem({ requestId: requestItem?.request_id ?? "" });
    await reloadRequestItem();
    await handleReload();
  };
  const handleReopenRequestItem = async () => {
    await reopenRequestItem({ requestId: requestItem?.request_id ?? "" });
    await reloadRequestItem();
    await handleReload();
  };
  const handleCloseRequestItem = async () => {
    await closeRequestItem({ requestId: requestItem?.request_id ?? "" });
    await reloadRequestItem();
    await handleReload();
  };
  const isOperating = isSpotMetaLoading || isMerging || isRejecting;
  return (
    <Stack>
      <Stack p={2} borderBottom={1} borderColor={"divider"}>
        {requestItem && (
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">
                {getRequestType(requestItem.request_type)}
                <Chip
                  label={getRequestStatus({
                    status: requestItem.status,
                    type: requestItem.request_type,
                  })}
                />
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  disabled={
                    !(
                      requestItem.status === RequestStatus.DRAFT ||
                      requestItem.status === RequestStatus.OPENED
                    ) || isOperating
                  }
                  variant="contained"
                  color="success"
                  onClick={() => handleMergeRequestItem()}
                >
                  {getMergeText(requestItem.request_type)}
                </Button>
                <Button
                  disabled={
                    !(
                      requestItem.status === RequestStatus.DRAFT ||
                      requestItem.status === RequestStatus.OPENED
                    ) || isOperating
                  }
                  variant="contained"
                  color="error"
                  onClick={() => handleCloseRequestItem()}
                >
                  却下
                </Button>
                <Button
                  disabled={
                    !(requestItem.status === RequestStatus.CLOSED) ||
                    isOperating
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => handleReopenRequestItem()}
                >
                  却下を取り消し
                </Button>
              </Stack>
            </Stack>
            <Typography>ID: {requestItem.request_id}</Typography>
            <Stack
              direction="row"
              spacing={1}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Typography variant="body1">
                ユーザID:{" "}
                <Link target="_blank" to={`/users/${requestItem.user_id}`}>
                  {requestItem.user_id}
                </Link>
                ({requestItem.request_from} 経由のリクエスト)
              </Typography>
              <Typography variant="body1">
                スポットID:{" "}
                <Link target="_blank" to={`/spots/${requestItem.spot_id}`}>
                  {requestItem.spot_id} ( {requestItem.spot_name} )
                </Link>
              </Typography>
            </Stack>
            <Typography variant="body1">
              コメント: {requestItem.comment}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack p={2}>
        {requestItem && requestItem.request_type === RequestType.UPDATE && (
          <>
            {spotMetadata && publishedRevision && (
              <SpotEditor
                editable={false}
                spotId={requestItem.spot_id ?? uuidv4()}
                baseData={publishedRevision}
                comparingData={requestItem.updated_spot_item ?? undefined}
              />
            )}
          </>
        )}
        {requestItem && requestItem.request_type === RequestType.CREATE && (
          <>
            <SpotEditor
              editable={false}
              spotId={requestItem.spot_id ?? uuidv4()}
              comparingData={requestItem.updated_spot_item ?? undefined}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
