import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Check } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import {
  InquiryItemQueryResponse,
  InquiryStatus,
} from "../generatedApi/inquiryManagerApi";
import { NotificationStatus } from "../generatedApi/notificationManagerApi";
import { getJstFromUnixtime } from "../utils";
import Template from "./Template";
import { v4 as uuidv4 } from "uuid";
import { Auth } from "aws-amplify";
import * as React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

const InquiryStatusJapanese = {
  new: "新規",
  in_progress: "進行中",
  resolved: "解決済み",
  closed: "CLOSED",
  reopened: "REOPENED",
};

type InquiryDetailPanelProps = {
  inquiryId: string;
  handleSubmit: () => void;
};
const InquiryDetailPanel = (props: InquiryDetailPanelProps) => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState<string | undefined>();
  const { user } = useAuthenticator();
  const getEmail = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const { email } = currentUser.attributes;
      setEmail(email);
    } catch (error) {
      console.error("Error getting user email:", error);
    }
  };

  React.useEffect(() => {
    getEmail();
  }, [user]);

  const { inquiryId } = props;
  const {
    data: inquiry,
    isLoading: isInquiryLoading,
    mutate: reloadInquiry,
    error: inquiryError,
  } = useAdminFetcherWithSwr({
    apiName: "inquiry_manager",
    serviceName: "default",
    operationId: "getInquiryItem",
    requestParameters: { inquiryId: inquiryId },
  });

  const { sender: deleteInquiryItem, isLoading: isInquiryDeleting } =
    useAdminSender({
      apiName: "inquiry_manager",
      serviceName: "default",
      operationId: "deleteInquiryItem",
    });
  const deleteInquiry = async (inquiryId: string) => {
    if (window.confirm("本当に削除しますか？") === false) return;
    await deleteInquiryItem({
      inquiryId: inquiryId,
    });
    await reloadInquiry();
    await props.handleSubmit();
  };

  const [updateInquiryStatusValue, setUpdateInquiryStatusValue] =
    useState<InquiryStatus>(InquiryStatus.NEW);

  const {
    sender: updateInquiryItemStatus,
    isLoading: isInquiryItemStatusUpdating,
  } = useAdminSender({
    apiName: "inquiry_manager",
    serviceName: "default",
    operationId: "updateInquiryItemStatus",
  });
  const updateInquiryStatus = async (inquiryId: string) => {
    if (window.confirm("本当に更新しますか？") === false) return;
    await updateInquiryItemStatus({
      inquiryId: inquiryId,
      status: updateInquiryStatusValue,
    });
    await reloadInquiry();
    await props.handleSubmit();
  };

  const { sender: createNotification, isLoading: isNotificationCreating } =
    useAdminSender({
      apiName: "notification_manager",
      serviceName: "admin",
      operationId: "createAdminNotificationItem",
    });

  type createInquiryReplyNotificationProps = {
    inquiry_id: string;
    user_id: string;
    subject: string;
    message: string;
    email: string;
  };
  const createInquiryReplyNotification = async (
    props: createInquiryReplyNotificationProps
  ) => {
    const newNotificationId = uuidv4();
    await createNotification({
      requestBody: {
        title: "お問い合わせの返信",
        message:
          `お問い合わせID\n${props.inquiry_id}\n\n` +
          `ユーザーID\n${props.user_id}\n\n` +
          `件名\n${props.subject}\n\n` +
          `本文\n${props.message}\n\n` +
          `以上のお問合せに関してになります。`,
        notification_id: newNotificationId,
        notification_type: "INQUIRY_REPLY",
        send_from: props.email ?? "",
        sent_to: props.inquiry_id ?? "",
        status: NotificationStatus.DRAFT,
      },
    });
    await navigate(`/notifications/${newNotificationId}`);
  };

  return (
    <>
      {inquiryError}
      {isInquiryLoading && <CircularProgress sx={{ m: 1 }} />}
      {!isInquiryLoading && !inquiry && (
        <Box p={1}>
          お問い合わせは削除されています。(お問い合わせID: {props.inquiryId})
        </Box>
      )}
      {inquiry && (
        <Stack>
          <Stack
            bgcolor={(theme) => theme.palette.grey[100]}
            p={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography fontWeight="bold">
              {inquiry.subject}
              {inquiry.subject.length < 1 && "件名未設定"}
            </Typography>
            {inquiry.status && (
              <Chip
                label={InquiryStatusJapanese[inquiry.status]}
                size="small"
                variant="outlined"
              />
            )}
          </Stack>
          <Stack divider={<Divider />}>
            <Stack p={1}>
              <Typography variant="body1" fontWeight="bold">
                お問い合わせ詳細
              </Typography>
              <Box>
                <Chip label="お問い合わせ ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {props.inquiryId}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="ユーザー ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {inquiry.user_id}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="件名" size="small" />
                <Typography pl={1} pt={0.5}>
                  {inquiry.subject}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="本文" size="small" />
                <Typography pl={1} pt={0.5}>
                  {inquiry.message}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="作成日時" size="small" />
                <Typography pl={1} pt={0.5}>
                  {getJstFromUnixtime(inquiry.timestamp_created / 1000)}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="月 ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {inquiry.month_id}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="ステータス" size="small" />
                <Typography pl={1} pt={0.5}>
                  {inquiry.status && InquiryStatusJapanese[inquiry.status]}
                </Typography>
              </Box>
            </Stack>
            <Stack p={1}>
              <Typography variant="body1" fontWeight="bold">
                ステータス変更
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box pt={1}>
                  <FormControl sx={{ width: 120 }}>
                    <InputLabel id="update-status-select-label">
                      変更後
                    </InputLabel>
                    <Select
                      labelId="update-status-select-label"
                      id="update-status-select"
                      value={updateInquiryStatusValue}
                      label="Age"
                      onChange={(e) =>
                        setUpdateInquiryStatusValue(
                          e.target.value as InquiryStatus
                        )
                      }
                    >
                      <MenuItem value={InquiryStatus.NEW}>新規</MenuItem>
                      <MenuItem value={InquiryStatus.IN_PROGRESS}>
                        進行中
                      </MenuItem>
                      <MenuItem value={InquiryStatus.RESOLVED}>
                        解決済み
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Tooltip
                    title={
                      inquiry.status === updateInquiryStatusValue
                        ? "現在のステータスです"
                        : ""
                    }
                  >
                    <div>
                      <Button
                        onClick={() => updateInquiryStatus(inquiry.inquiry_id)}
                        variant="contained"
                        disabled={
                          isInquiryItemStatusUpdating ||
                          inquiry.status === updateInquiryStatusValue
                        }
                        size="small"
                        color="primary"
                      >
                        更新
                      </Button>
                    </div>
                  </Tooltip>
                </Box>
              </Stack>
            </Stack>
            <Stack
              p={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Box>
                <Button
                  onClick={() => {
                    createInquiryReplyNotification({
                        inquiry_id: inquiry.inquiry_id,
                        user_id: inquiry.user_id,
                        subject: inquiry.subject,
                        message: inquiry.message,
                        email: email ?? "",
                        });
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  お知らせで返信
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => deleteInquiry(inquiry.inquiry_id)}
                  variant="contained"
                  disabled={isInquiryDeleting}
                  size="small"
                  color="error"
                >
                  削除
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

const InquiryManagerPage = () => {
  const { inquiryId } = useParams();

  const navigate = useNavigate();
  const [queryParams, setSearchParams] = useSearchParams();

  const { sender: getInquiryItemsByMonthId, isLoading: isLoading1 } =
    useAdminSender({
      apiName: "inquiry_manager",
      serviceName: "default",
      operationId: "getLatestInquiryItemsByMonthId",
    });
  const { sender: getInquiryItemsByUserId, isLoading: isLoading2 } =
    useAdminSender({
      apiName: "inquiry_manager",
      serviceName: "default",
      operationId: "getLatestInquiryItemsByUserId",
    });

  const isLoading = isLoading1 || isLoading2;
  const [queryResult, setQueryResult] =
    useState<InquiryItemQueryResponse | null>(null);

  const handleSubmit = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: InquiryItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getInquiryItemsByMonthId({
          monthId: queryId,
        });
        break;
      case "user_id":
        res = await getInquiryItemsByUserId({
          userId: queryId,
        });
        break;
    }
    if (res === undefined) return;
    setQueryResult(res);
  };
  const [queryPartition, setQueryPartition] = useState<string>("month_id");
  const [queryId, setQueryId] = useState<string | null>(null);

  useEffect(() => {
    setQueryId(queryParams.get("id") ?? new Date().toISOString().slice(0, 7));
    setQueryPartition(queryParams.get("partition") ?? "month_id");
  }, []);

  const [moreReadButtonDisabled, setMoreReadButtonDisabled] = useState(false);
  useEffect(() => {
    if (queryResult?.last_evaluated_key) {
      setMoreReadButtonDisabled(true);
    } else {
      setMoreReadButtonDisabled(false);
    }
  }, [queryResult]);
  const [queryResult2, setQueryResult2] =
    useState<InquiryItemQueryResponse | null>(null);
  const getMoreReadInquiryItems = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: InquiryItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        res = await getInquiryItemsByMonthId({
          monthId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
      case "user_id":
        res = await getInquiryItemsByUserId({
          userId: queryId,
          lastEvaluatedKey: queryResult?.last_evaluated_key,
        });
        break;
    }
    if (res === undefined) return;
    if (queryResult === null) return;
    queryResult.items.push(...res.items);
    queryResult.last_evaluated_key = res.last_evaluated_key;
    if (!res.last_evaluated_key) {
      setMoreReadButtonDisabled(false);
    }
    setQueryResult2(queryResult);
  };
  useEffect(() => {
    setQueryResult(queryResult);
  }, [queryResult2]);

  return (
    <Template title="お問い合わせ管理">
      <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs value={"inquiries"} textColor="inherit">
          <Tab label="お問い合わせ一覧" onClick={() => {}} value="inquiries" />
        </Tabs>
      </AppBar>
      <Box component="main" sx={{ flex: 1, px: 2, bgcolor: "#eaeff1" }}>
        <Stack height="100%" direction="row" spacing={2} pt={2}>
          <Stack width={400} spacing={1}>
            <Stack border={(theme) => `1px solid ${theme.palette.divider}`}>
              <Stack
                p={1}
                bgcolor={(theme) => theme.palette.grey[100]}
                spacing={1}
                divider={<Divider />}
              >
                <Stack p={1} spacing={1}>
                  <Typography>検索</Typography>
                  <Stack direction="row" spacing={1}>
                    {["month_id", "user_id"].map((partition, pkey) => (
                      <Chip
                        key={pkey}
                        label={partition}
                        onClick={() => {
                          setQueryPartition(partition);
                        }}
                        size="small"
                        variant={
                          queryPartition === partition ? "filled" : "outlined"
                        }
                        icon={
                          queryPartition === partition ? <Check /> : undefined
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Stack p={1} spacing={1}>
                    <TextField
                      label="ID"
                      placeholder={`${queryPartition} を入力...`}
                      value={queryId}
                      onChange={(e) => setQueryId(e.target.value)}
                      disabled={isLoading}
                    />
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        disabled={isLoading}
                      >
                        IDに該当するデータを取得
                      </Button>
                    </Box>
                  </Stack>
                </form>
                <Typography>お問い合わせ一覧</Typography>
              </Stack>
              <Stack
                pt={1}
                bgcolor={(theme) => theme.palette.background.default}
                divider={<Divider />}
              >
                {isLoading && <CircularProgress />}
                {queryResult?.items.length === 0 && (
                  <Box p={1}>お問い合わせはありません</Box>
                )}
                {queryResult?.items.map((inquiry, nkey) => (
                  <Stack
                    onClick={() => {
                      navigate(`/inquiries/${inquiry.inquiry_id}`);
                    }}
                    key={nkey}
                    p={1}
                    sx={{ cursor: "pointer" }}
                    direction="row"
                  >
                    <Box>
                      <Radio checked={inquiryId === inquiry.inquiry_id} />
                    </Box>
                    <Stack>
                      <Typography fontWeight="bold">
                        {inquiry.subject.length > 0
                          ? inquiry.subject
                          : "タイトル未設定"}
                      </Typography>
                      {inquiry.status && (
                        <Box>
                          <Chip
                            label={InquiryStatusJapanese[inquiry.status]}
                            size="small"
                          />
                        </Box>
                      )}
                      <Typography>
                        {getJstFromUnixtime(inquiry.timestamp_created / 1000)}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
                {moreReadButtonDisabled && (
                  <Button onClick={() => getMoreReadInquiryItems()}>
                    続きを読み込む
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack width="100%">
            <Stack
              border={(theme) => `1px solid ${theme.palette.divider}`}
              bgcolor={(theme) => theme.palette.background.default}
            >
              {!inquiryId && "お問い合わせを選択してください"}
              {inquiryId && (
                <InquiryDetailPanel
                  inquiryId={inquiryId}
                  handleSubmit={handleSubmit}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Template>
  );
};

export default InquiryManagerPage;
