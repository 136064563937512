/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SpotSearchQueryParams } from '../models/SpotSearchQueryParams';
import type { SpotSearchQueryResult } from '../models/SpotSearchQueryResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }

    /**
     * 検索結果を返します。
     * @returns SpotSearchQueryResult Successful Response
     * @throws ApiError
     */
    public searchDraftSpots({
        requestBody,
    }: {
        requestBody?: (SpotSearchQueryParams | null),
    }): CancelablePromise<SpotSearchQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/spots/draft/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
