/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserRequestItem } from '../models/UserRequestItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * リクエストを削除します。
     * リクエストを削除します。
     * @returns UserRequestItem Successful Response
     * @throws ApiError
     */
    public adminDeleteUserRequestItem({
        requestId,
    }: {
        requestId: string,
    }): CancelablePromise<UserRequestItem> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/user_requests/{request_id}',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * リクエストをマージします。
     * リクエストをマージします。
     * @returns UserRequestItem Successful Response
     * @throws ApiError
     */
    public adminMergeUserRequestItem({
        requestId,
    }: {
        requestId: string,
    }): CancelablePromise<UserRequestItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user_requests/{request_id}/merge',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * リクエストを却下します。
     * リクエストを却下します。
     * @returns UserRequestItem Successful Response
     * @throws ApiError
     */
    public adminCloseUserRequestItem({
        requestId,
    }: {
        requestId: string,
    }): CancelablePromise<UserRequestItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user_requests/{request_id}/close',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * リクエストを再オープンします。
     * リクエストを再オープンします。
     * @returns UserRequestItem Successful Response
     * @throws ApiError
     */
    public adminReopenUserRequestItem({
        requestId,
    }: {
        requestId: string,
    }): CancelablePromise<UserRequestItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/user_requests/{request_id}/reopen',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
