import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Home from "./components/HomePage";
import InquiryManagerPage from "./components/InquiryManagerPage";
import MediaManagerPage from "./components/MediaManagerPage";
import NotFoundPage from "./components/NotFoundPage";
import NotificationManagerPage from "./components/NotificationManagerPage";
import SpotItemPage from "./components/SpotItemPage";
import UserDataImporterPage from "./components/UserDataImporterPage";
import UserInteractionLoggerPage from "./components/UserInteractionLoggerPage";
import UserManagerPage from "./components/UserManagerPage";
import UserRequestManagerPage from "./components/UserRequestManagerPage";
import params from "./constants/authenticatorParams";
import ViolationReportManagerPage from "./components/ViolationReportManagerPage";
import RecommendationMockManagerPage from "./components/RecommendationMockupManagerPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="*" element={<NotFoundPage />} />
      <Route index element={<Home />} />
      <Route path="spots">
        <Route index element={<SpotItemPage />} />
        <Route path="public" element={<SpotItemPage />} />
        <Route path="create" element={<SpotItemPage />} />
        <Route path=":spotId">
          <Route index element={<SpotItemPage />} />
          <Route path="edit" element={<SpotItemPage />} />
          <Route path="interactions" element={<SpotItemPage />} />
          <Route path="revisions">
            <Route index element={<SpotItemPage />} />
            <Route path=":revisionId" element={<SpotItemPage />} />
          </Route>
          <Route path="user_requests">
            <Route index element={<SpotItemPage />} />
          </Route>
          <Route path="media">
            <Route index element={<SpotItemPage />} />
          </Route>
          <Route path="media_upload">
            <Route index element={<SpotItemPage />} />
          </Route>
        </Route>
      </Route>
      <Route path="notifications">
        <Route index element={<NotificationManagerPage />} />
        <Route
          path=":notificationId"
          element={<NotificationManagerPage />}
        />
      </Route>
      <Route path="inquiries">
        <Route index element={<InquiryManagerPage />} />
        <Route path=":inquiryId" element={<InquiryManagerPage />} />
      </Route>
      RecommendationMockManagerPage
      <Route path="recommendation_mockup">
        <Route index element={<RecommendationMockManagerPage />} />
      </Route>
      <Route path="users">
        <Route index element={<UserManagerPage />} />
        <Route path=":userId" element={<UserManagerPage />} />
      </Route>
      <Route path="user_requests">
        <Route index element={<UserRequestManagerPage />} />
        <Route path=":request_id" element={<UserRequestManagerPage />} />
      </Route>
      <Route path="interactions">
        <Route index element={<UserInteractionLoggerPage />} />
        <Route path=":interactionId" element={<UserInteractionLoggerPage />} />
      </Route>
      <Route path="user_data_importer">
        <Route index element={<UserDataImporterPage />} />
        <Route path="upload" element={<UserDataImporterPage />} />
        <Route path=":jobId" element={<UserDataImporterPage />} />
      </Route>
      <Route path="media">
        <Route index element={<MediaManagerPage />} />
        <Route path="upload" element={<MediaManagerPage />} />
        <Route path=":mediaId" element={<MediaManagerPage />} />
      </Route>
      <Route path="violation_reports">
        <Route index element={<ViolationReportManagerPage />} />
        <Route path=":violationReportId" element={<ViolationReportManagerPage />} />
      </Route>
    </Route>
  )
);

const App = () => {
  return (
    <Authenticator {...params}>
      <RouterProvider router={router} />
    </Authenticator>
  );
};

export default App;
