/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * スポットのソート対象フィールド
 */
export enum SpotSearchQuerySortableField {
    DISTANCE = 'distance',
    SCORE = 'score',
    TIMESTAMP_PUBLISHED = 'timestamp_published',
    TIMESTAMP_CREATED = 'timestamp_created',
}
