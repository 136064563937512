import { Check, ContentCopy, ErrorOutline } from "@mui/icons-material";
import { Box } from "@mui/joy";
import {
  Button,
  Checkbox,
  InputBase,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  colors,
} from "@mui/material";
import { useDebounce, useList } from "@uidotdev/usehooks";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  SpotBusinessStatus,
  ValidationErrorItem,
} from "../../generatedApi/spotManagerApi";

const SpotBusinessStatusJapanese = {
  [SpotBusinessStatus.OPERATIONAL]: "営業中",
  [SpotBusinessStatus.CLOSED_TEMPORARILY]: "休業中",
  [SpotBusinessStatus.CLOSED_PERMANENTLY]: "閉店",
};

type ClipboardCopyButtonProps = { value: string };
const ClipboardCopyButton = (props: ClipboardCopyButtonProps) => {
  const [copied, setCopied] = useState(false);
  const handleClick = async () => {
    await global.navigator.clipboard.writeText(props.value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <>
      <Tooltip title={copied ? "コピーしました" : "コピー"}>
        {copied ? (
          <Check
            sx={{
              fontSize: (theme) => theme.typography.body2,
              color: (theme) => theme.palette.success.main,
            }}
          />
        ) : (
          <ContentCopy
            sx={{
              fontSize: (theme) => theme.typography.body2,
              cursor: "pointer",
            }}
            onClick={() => handleClick()}
          />
        )}
      </Tooltip>
    </>
  );
};

type CellItemProps = { children: React.ReactNode; longText?: boolean };
const CellItem = ({ longText = false, children }: CellItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      {longText ? (
        <Stack
          direction="row"
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => {
            setIsHovered(false);
          }}
          padding={0.25}
          sx={{
            background: isHovered ? colors.grey[100] : "transparent",
          }}
        >
          <Box
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            title={children as string}
          >
            {children}
          </Box>
          <Box sx={{ opacity: isHovered ? 1 : 0 }}>
            <ClipboardCopyButton value={children as string} />
          </Box>
        </Stack>
      ) : (
        <Box>{children}</Box>
      )}
    </>
  );
};
export type SpotItemValueType = string | number | undefined | null | string[];
export type InputType = "value" | "valueArray" | "multiSelect" | "select";

export type SpotEditorTableRowProps<T extends SpotItemValueType> = {
  name?: string;
  title: string;
  subtitle?: string;
  editable?: boolean;
  immutable?: boolean;
  baseValue?: T;
  comparingValue?: T;
  inputType?: InputType;
  longText?: boolean;
  selectableItems?: string[];
  setResultingValue?: (value: NonNullable<T> | undefined) => void;
  validationErrorItems: ValidationErrorItem[];
};

export const SpotEditorTableRow = <T extends SpotItemValueType>({
  name,
  comparingValue,
  baseValue,
  setResultingValue,
  selectableItems,
  validationErrorItems,
  editable = true,
  immutable = false,
  inputType = "value",
  longText = false,
  ...props
}: SpotEditorTableRowProps<T>) => {
  const isExistingValueNull = baseValue == null;
  const [edittedValue, setEdittedValue] = useState<T | undefined>(
    comparingValue ?? baseValue
  );

  const validationError = useMemo(() => {
    if (validationErrorItems) {
      const res = validationErrorItems.filter(
        (item) => item.loc.join(".") === name
      );
      if (res.length > 0) {
        switch (res[0].type) {
          case "missing":
            return "必須です";
          case "value_error":
            return "不正な値です";
          default:
            return "エラーが発生しました";
        }
      }
    }
    return undefined;
  }, [validationErrorItems]);

  useEffect(() => {
    setEdittedValue(comparingValue ?? baseValue);
  }, [
    // NOTE: comparingValue を比較に含めると、編集中に値が変わってしまうので、含めない
    baseValue,
  ]);
  const [
    edittedValues,
    {
      updateAt: edittedValuesUpdateAt,
      set: setEdittedValues,
      push: pushEdittedValues,
      removeAt: removeEdittedValuesAt,
    },
  ] = useList(
    (Array.isArray(comparingValue) ? comparingValue : undefined) ??
      (Array.isArray(baseValue) ? baseValue : undefined) ??
      undefined
  );
  const debouncedEdittedValue = useDebounce(edittedValue, 500);
  const debouncedEdittedValues = useDebounce(edittedValues, 500);

  const edittedResultingValue = useMemo(() => {
    if (inputType === "valueArray") {
      if (debouncedEdittedValues.length === 0) {
        return null;
      }
      return debouncedEdittedValues as unknown as T;
    } else {
      if (debouncedEdittedValue === "") {
        return null;
      } else if (debouncedEdittedValue != null) {
        if (typeof baseValue === "number") {
          if (isNaN(Number(debouncedEdittedValue))) {
            return null;
          }
          return Number(debouncedEdittedValue) as unknown as T;
        } else {
          return debouncedEdittedValue;
        }
      }
      return null;
    }
  }, [debouncedEdittedValue, debouncedEdittedValues, inputType]);

  const resultingValue = useMemo<T | null>(() => {
    if (edittedResultingValue != null) {
      return edittedResultingValue;
    }
    return null;
  }, [edittedResultingValue, baseValue]);

  useEffect(() => {
    setResultingValue && setResultingValue(resultingValue ?? undefined);
  }, [resultingValue, setResultingValue]);

  const valueDiff = useMemo(() => {
    if (baseValue == null && resultingValue == null) {
      return "no change";
    } else if (JSON.stringify(baseValue) === JSON.stringify(resultingValue)) {
      return "no change";
    } else if (baseValue == null && resultingValue != null) {
      return "added";
    } else if (baseValue != null && resultingValue == null) {
      return "removed";
    } else {
      return "modified";
    }
  }, [baseValue, resultingValue]);

  const diffColor = useMemo(() => {
    switch (valueDiff) {
      case "added":
        return colors.green[50];
      case "removed":
        return colors.red[50];
      case "modified":
        return colors.yellow[50];
      default:
        return "inherit";
    }
  }, [valueDiff]);

  const diffText = useMemo(() => {
    switch (valueDiff) {
      case "added":
        return "追加";
      case "removed":
        return "削除";
      case "modified":
        return "変更";
      default:
        return "変更なし";
    }
  }, [valueDiff]);

  return (
    <>
      <TableRow sx={{ bgcolor: diffColor }}>
        <TableCell colSpan={props.subtitle ? 1 : 2}>
          <Tooltip title={validationError ?? ""}>
            <Stack direction="row" spacing={0.5}>
              <Typography color={validationError ? "error" : "inherit"}>
                {props.title}
              </Typography>
              {validationError && <ErrorOutline color="error" />}
            </Stack>
          </Tooltip>
        </TableCell>
        {props.subtitle && <TableCell>{props.subtitle}</TableCell>}
        <TableCell
          sx={{
            borderLeft: (theme) => `1px dashed ${theme.palette.divider}`,
          }}
        >
          <Switch checked={!isExistingValueNull} disabled size="small" />
        </TableCell>
        <TableCell>
          <Box maxWidth={200}>
            {(inputType === "valueArray" || inputType === "multiSelect") &&
            Array.isArray(baseValue) ? (
              <Stack spacing={0.5} direction="column">
                {baseValue?.map((value, index) => (
                  <CellItem key={index} longText={longText}>
                    {value}
                  </CellItem>
                ))}
              </Stack>
            ) : inputType === "select" ? (
              <CellItem longText={longText}>
                {baseValue
                  ? SpotBusinessStatusJapanese[baseValue as SpotBusinessStatus]
                  : "[なし]"}
              </CellItem>
            ) : baseValue == null ? (
              "[なし]"
            ) : (
              <CellItem longText={longText}>{baseValue}</CellItem>
            )}
          </Box>
        </TableCell>
        <TableCell
          sx={{
            borderLeft: (theme) => `1px dashed ${theme.palette.divider}`,
          }}
        >
          {immutable ? (
            <Switch checked={!isExistingValueNull} disabled size="small" />
          ) : (
            <Switch
              onChange={(e, checked) => {
                if (Array.isArray(baseValue)) {
                  checked ? setEdittedValues(baseValue) : setEdittedValues([]);
                } else {
                  checked
                    ? setEdittedValue(baseValue)
                    : setEdittedValue(undefined);
                }
              }}
              checked={edittedResultingValue != null}
              disabled={!editable}
              size="small"
            />
          )}
        </TableCell>
        <TableCell>
          <Box maxWidth={300} minWidth={250}>
            {immutable || !editable ? (
              <>
                {inputType === "valueArray" || inputType === "multiSelect" ? (
                  <Stack spacing={0.5}>
                    {Array.isArray(comparingValue) &&
                      comparingValue.map((val, key) => (
                        <CellItem key={key} longText={longText}>
                          {val}
                        </CellItem>
                      ))}
                  </Stack>
                ) : (
                  <CellItem longText={longText}>
                    {inputType === "select"
                      ? SpotBusinessStatusJapanese[
                          comparingValue as SpotBusinessStatus
                        ]
                      : comparingValue ?? "[なし]"}
                  </CellItem>
                )}
              </>
            ) : (
              <Box minWidth={250}>
                {inputType === "valueArray" && (
                  <Stack spacing={1}>
                    {edittedValues.map((val, vkey) => (
                      <InputBase
                        key={vkey}
                        type={"text"}
                        defaultValue={
                          edittedValues[vkey] ??
                          (Array.isArray(comparingValue)
                            ? comparingValue[vkey]
                            : undefined) ??
                          (Array.isArray(baseValue)
                            ? baseValue[vkey]
                            : undefined)
                        }
                        sx={{
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderRadius: "4px",
                          padding: "4px 8px",
                          backgroundColor: (theme) =>
                            theme.palette.background.paper,
                        }}
                        onChange={(e) => {
                          edittedValuesUpdateAt(vkey, e.target.value as T);
                        }}
                      />
                    ))}
                    <Stack direction="row" spacing={1}>
                      <Button
                        onClick={() => pushEdittedValues("")}
                        color="success"
                        variant="outlined"
                      >
                        追加
                      </Button>
                      <Button
                        onClick={() =>
                          removeEdittedValuesAt(edittedValues.length - 1)
                        }
                        color="error"
                        variant="outlined"
                      >
                        削除
                      </Button>
                    </Stack>
                  </Stack>
                )}
                {inputType === "multiSelect" && (
                  <>
                    <Select
                      renderValue={(selected) =>
                        Array.isArray(selected) ? selected.join(", ") : "なし"
                      }
                      value={Array.isArray(edittedValue) ? edittedValue : []}
                      multiple
                      onChange={(e) => {
                        const value = e.target.value as T;
                        if (Array.isArray(value)) {
                          setEdittedValue(value);
                        }
                      }}
                      input={
                        <InputBase
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme.palette.divider}`,
                            borderRadius: "4px",
                            padding: "4px 8px",
                            backgroundColor: (theme) =>
                              theme.palette.background.paper,
                          }}
                          value={undefined}
                          fullWidth
                        />
                      }
                    >
                      {selectableItems?.map((value) => (
                        <MenuItem key={value} value={value}>
                          <Checkbox
                            checked={
                              Array.isArray(edittedValue) &&
                              edittedValue.indexOf(value) > -1
                            }
                          />
                          <ListItemText primary={value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {inputType === "select" && (
                  <>
                    <Select
                      renderValue={(selected) =>
                        selected
                          ? SpotBusinessStatusJapanese[
                              selected as SpotBusinessStatus
                            ]
                          : "なし"
                      }
                      value={edittedValue ? edittedValue : ""}
                      onChange={(e) => {
                        const value = e.target.value as T;
                        if (value) {
                          setEdittedValue(value);
                        }
                      }}
                      input={
                        <InputBase
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme.palette.divider}`,
                            borderRadius: "4px",
                            padding: "4px 8px",
                            backgroundColor: (theme) =>
                              theme.palette.background.paper,
                          }}
                          value={undefined}
                          fullWidth
                        />
                      }
                    >
                      {selectableItems?.map((value) => (
                        <MenuItem key={value} value={value}>
                          <Radio checked={edittedValue === value} />
                          <ListItemText
                            primary={
                              SpotBusinessStatusJapanese[
                                value as SpotBusinessStatus
                              ]
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {inputType === "value" && (
                  <InputBase
                    type="text"
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                      borderRadius: "4px",
                      padding: "4px 8px",
                      backgroundColor: (theme) =>
                        theme.palette.background.paper,
                    }}
                    multiline
                    fullWidth
                    defaultValue={
                      edittedValue ?? comparingValue ?? baseValue ?? undefined
                    }
                    value={edittedValue ?? undefined}
                    onChange={(e) => {
                      setEdittedValue(e.target.value as T);
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell
          sx={{
            borderLeft: (theme) => `3px double ${theme.palette.divider}`,
          }}
        >
          <Switch checked={resultingValue != null} disabled size="small" />
        </TableCell>
        <TableCell>
          <Box maxWidth={200}>
            {Array.isArray(resultingValue) ? (
              <Stack spacing={0.5}>
                {resultingValue?.map((value, index) => (
                  <CellItem key={index} longText={longText}>
                    {value}
                  </CellItem>
                ))}
              </Stack>
            ) : inputType === "select" ? (
              <CellItem longText={longText}>
                {resultingValue
                  ? SpotBusinessStatusJapanese[
                      resultingValue as SpotBusinessStatus
                    ]
                  : "[なし]"}
              </CellItem>
            ) : resultingValue == null ? (
              "[なし]"
            ) : (
              <CellItem longText={longText}>{resultingValue}</CellItem>
            )}
          </Box>
        </TableCell>
        <TableCell>{diffText}</TableCell>
      </TableRow>
    </>
  );
};
