import { Check } from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Chip,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAdminSender } from "../api";
import Template from "./Template";
import {
  MediaItemQueryResponse,
  MediaStatus,
} from "../generatedApi/mediaManagerApi";
import MediaUploadTab from "./MediaUploadTab";
import MediaListTab from "./MediaListTab";

export const MediaStatusJapanese = {
  [MediaStatus.PREPARED]: "送信待ち",
  [MediaStatus.PROCESSING]: "処理中",
  [MediaStatus.FAILED]: "処理失敗",
  [MediaStatus.READY]: "公開状態",
  [MediaStatus.DELETED]: "削除済",
  [MediaStatus.ARCHIVED]: "アーカイブ済",
  [MediaStatus.REPORTED]: "不適切報告",
  [MediaStatus.BANNED]: "公開停止中",
};

export const StatusOptions = [
  { title: "送信待ち", value: MediaStatus.PREPARED },
  { title: "処理中", value: MediaStatus.PROCESSING },
  { title: "処理失敗", value: MediaStatus.FAILED },
  { title: "公開状態", value: MediaStatus.READY },
  { title: "削除済", value: MediaStatus.DELETED },
  { title: "アーカイブ済", value: MediaStatus.ARCHIVED },
  { title: "不適切報告", value: MediaStatus.REPORTED },
  { title: "公開停止中", value: MediaStatus.BANNED },
];

export const MediaManagerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { media_id: mediaId } = useParams();
  const [queryParams, setSearchParams] = useSearchParams();

  const [statasfilterActive, setStatusFilterActive] = useState<boolean>(false);
  const handleStatusFilterActive = () => {
    setStatusFilterActive(!statasfilterActive);
  };
  const [statasfilter, setStatusFilter] = useState<MediaStatus[]>([]);
  const [requestStatus, setRequestStatus] = useState<MediaStatus[]>([]);
  useEffect(() => {
    const active = statasfilterActive;
    if (active) {
      const newStatus = statasfilter;
      setRequestStatus(newStatus);
    } else {
      setRequestStatus([]);
    }
  }, [statasfilterActive, statasfilter]);

  const { sender: getMediaItemsMonthId, isLoading: isLoading1 } =
    useAdminSender({
      apiName: "media_manager",
      serviceName: "default",
      operationId: "getAdminMediaItemsByMonthId",
    });

  const { sender: getMediaItemsUserId, isLoading: isLoading2 } = useAdminSender(
    {
      apiName: "media_manager",
      serviceName: "default",
      operationId: "getAdminMediaItemsByUserId",
    }
  );

  const { sender: getMediaItemsSpotId, isLoading: isLoading3 } = useAdminSender(
    {
      apiName: "media_manager",
      serviceName: "default",
      operationId: "getAdminMediaItemsBySpotId",
    }
  );

  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const [queryResult, setQueryResult] =
    useState<MediaItemQueryResponse | null>(null);

  const handleSubmit = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: MediaItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        if (requestStatus.length === 0) {
          res = await getMediaItemsMonthId({ monthId: queryId, limit: 100 });
        } else {
          res = await getMediaItemsMonthId({
            monthId: queryId,
            limit: 100,
            requestBody: requestStatus,
          });
        }
        break;
      case "user_id":
        if (requestStatus.length === 0) {
          res = await getMediaItemsUserId({ userId: queryId, limit: 100 });
        } else {
          res = await getMediaItemsUserId({
            userId: queryId,
            limit: 100,
            requestBody: requestStatus,
          });
        }
        break;
      case "spot_id":
        if (requestStatus.length === 0) {
          res = await getMediaItemsSpotId({ spotId: queryId, limit: 100 });
        } else {
          res = await getMediaItemsSpotId({
            spotId: queryId,
            limit: 100,
            requestBody: requestStatus,
          });
        }
        break;
    }
    if (res === undefined) return;
    setQueryResult(res);
  };

  const [queryPartition, setQueryPartition] = useState<string>("month_id");
  const [queryId, setQueryId] = useState<string | null>(null);

  useEffect(() => {
    setQueryId(queryParams.get("id") ?? new Date().toISOString().slice(0, 7));
    setQueryPartition(queryParams.get("partition") ?? "month_id");
  }, []);

  const [moreReadButtonDisabled, setMoreReadButtonDisabled] = useState(false);
  useEffect(() => {
    if (queryResult?.last_evaluated_key) {
      setMoreReadButtonDisabled(true);
    } else {
      setMoreReadButtonDisabled(false);
    }
  }, [queryResult]);
  const [queryResult2, setQueryResult2] =
    useState<MediaItemQueryResponse | null>(null);
  const getMoreMediaItems = async () => {
    if (queryId === null) return;
    setSearchParams((prev) => {
      return { id: queryId, partition: queryPartition };
    });
    let res: MediaItemQueryResponse | undefined = undefined;
    switch (queryPartition) {
      case "month_id":
        if (requestStatus.length === 0) {
          res = await getMediaItemsMonthId({
            monthId: queryId,
            limit: 100,
            lastEvaluatedKey: queryResult?.last_evaluated_key,
          });
        } else {
          res = await getMediaItemsMonthId({
            monthId: queryId,
            limit: 100,
            lastEvaluatedKey: queryResult?.last_evaluated_key,
            requestBody: requestStatus,
          });
        }
        break;
      case "user_id":
        if (requestStatus.length === 0) {
          res = await getMediaItemsUserId({
            userId: queryId,
            limit: 100,
            lastEvaluatedKey: queryResult?.last_evaluated_key,
          });
        } else {
          res = await getMediaItemsUserId({
            userId: queryId,
            limit: 100,
            lastEvaluatedKey: queryResult?.last_evaluated_key,
            requestBody: requestStatus,
          });
        }
        break;
      case "spot_id":
        if (requestStatus.length === 0) {
          res = await getMediaItemsSpotId({
            spotId: queryId,
            limit: 100,
            lastEvaluatedKey: queryResult?.last_evaluated_key,
          });
        } else {
          res = await getMediaItemsSpotId({
            spotId: queryId,
            limit: 100,
            lastEvaluatedKey: queryResult?.last_evaluated_key,
            requestBody: requestStatus,
          });
        }
        break;
    }
    if (res === undefined) return;
    if (queryResult === null) return;
    const totalQueryResult = queryResult;
    totalQueryResult["items"] = [...queryResult.items, ...res.items];
    totalQueryResult["last_evaluated_key"] = res.last_evaluated_key;
    if (!res.last_evaluated_key) {
      setMoreReadButtonDisabled(false);
    }
    setQueryResult2(totalQueryResult);
  };
  useEffect(() => {
    if (queryResult2 === null) return;
    const newResult: MediaItemQueryResponse = { ...queryResult2 };
    setQueryResult(newResult);
  }, [queryResult2]);

  const isMediaPage = location.pathname.split("/").slice(-1).includes("media");

  const isUploadPage = location.pathname
    .split("/")
    .slice(-1)
    .includes("upload");

  return (
    <>
      <Template title="メディア管理">
        <AppBar
          component="div"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Tabs
            value={isMediaPage ? "media" : isUploadPage ? "upload" : ""}
            textColor="inherit"
          >
            <Tab
              label="メディア一覧"
              onClick={() => navigate("/media")}
              value="media"
            />
            <Tab
              label="登録"
              onClick={() => navigate("/media/upload")}
              value="upload"
            />
          </Tabs>
        </AppBar>
        <Box component="main" sx={{ flex: 1, p: 2, bgcolor: "#eaeff1" }}>
          {isUploadPage && <MediaUploadTab />}
          {isMediaPage && (
            <Stack direction="row" spacing={1}>
              <Stack
                border={(theme) => `1px solid ${theme.palette.divider}`}
                minWidth={300}
                maxWidth={300}
                bgcolor={(theme) => theme.palette.background.default}
              >
                <Stack divider={<Divider />}>
                  <Stack p={1} spacing={1}>
                    <Typography>検索</Typography>
                    <Stack direction="row" spacing={1}>
                      {["month_id", "user_id", "spot_id"].map(
                        (partition, pkey) => (
                          <Chip
                            key={pkey}
                            label={partition}
                            onClick={() => {
                              setQueryPartition(partition);
                            }}
                            size="small"
                            variant={
                              queryPartition === partition
                                ? "filled"
                                : "outlined"
                            }
                            icon={
                              queryPartition === partition ? (
                                <Check />
                              ) : undefined
                            }
                          />
                        )
                      )}
                    </Stack>
                  </Stack>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <Stack p={1} spacing={1}>
                      <TextField
                        label="ID"
                        placeholder={`${queryPartition} を入力...`}
                        value={queryId}
                        onChange={(e) => setQueryId(e.target.value)}
                        disabled={isLoading}
                      />
                      <Box>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          disabled={isLoading}
                        >
                          IDに該当するデータを取得
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                  <Box p={1}>
                    <Typography fontSize="h2">フィルター</Typography>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="フィルターを有効にする"
                      sx={{ pl: 1 }}
                      onChange={handleStatusFilterActive}
                    />
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={StatusOptions}
                      getOptionLabel={(option) => option.title}
                      filterSelectedOptions
                      disabled={!statasfilterActive}
                      sx={{ pt: 1 }}
                      onChange={(e, v) => {
                        setStatusFilter(v.map((v) => v.value));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ステータスフィルター"
                          placeholder="ステータスを選択..."
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Stack>
              <Stack
                bgcolor={(theme) => theme.palette.background.default}
                divider={<Divider />}
                width="100%"
              >
                {!isLoading && !queryResult && (
                  <Box p={1}>メディアを検索してください</Box>
                )}
                {isLoading && <CircularProgress />}
                {queryResult?.items.length === 0 && (
                  <Box p={1}>メディアがありません</Box>
                )}
                {queryResult?.items && (
                  <Box>
                    <MediaListTab
                      MediaItems={queryResult.items}
                      handleSubmit={handleSubmit}
                      isLoading={isLoading}
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      p={1}
                    >
                      {moreReadButtonDisabled &&
                        (isLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            size="small"
                            onClick={() => {
                              getMoreMediaItems();
                            }}
                          >
                            続きを読み込む
                          </Button>
                        ))}
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </Template>
    </>
  );
};
export default MediaManagerPage;
