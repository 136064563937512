/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ImportJobStatus {
    PREPARED = 'prepared',
    UPLOADED = 'uploaded',
    PROCESSING = 'processing',
    COMPLETED = 'completed',
    FAILED = 'failed',
}
