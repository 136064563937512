import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  MoodCategory,
  SpotBusinessStatus,
  SpotItemRevisionCreate,
  ValidationErrorItem,
} from "../../generatedApi/spotManagerApi";
import { SpotEditorTableRow } from "./SpotEditorTableRow";

function removeNodiffProps(obj: any): any {
  if (typeof obj !== "object" || obj === 'nodiff') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeNodiffProps);
  }

  const newObj: { [key: string]: any } = {};

  for (const key in obj) {
    const value = removeNodiffProps(obj[key]);
    if (value !== 'nodiff') {
      newObj[key] = value;
    }
  }

  return newObj;
}
function sortedJsonStringify(
  obj: { [key: string]: any },
  indent: number = 2
): string {
  const sortedObject: { [key: string]: any } = {};
  Object.keys(obj)
    .sort()
    .forEach((key) => {
      sortedObject[key] = obj[key];
    });

  return JSON.stringify(sortedObject, null, indent);
}

type SpotEditorProps = {
  spotId: string;
  editable?: boolean;
  baseData?: SpotItemRevisionCreate;
  comparingData?: SpotItemRevisionCreate;
  baseTitle?: string;
  comparingTitle?: string;
  resultingTitle?: string;
  validationErrorItems?: ValidationErrorItem[];
  setResultingValue?: (value: SpotItemRevisionCreate | undefined) => void;
  setResultingValueDiff?: (value: SpotItemRevisionCreate | undefined) => void;
};
export const SpotEditor = ({
  spotId,
  setResultingValue,
  setResultingValueDiff,
  baseData = undefined,
  comparingData = undefined,
  editable = true,
  baseTitle = "比較対象",
  comparingTitle = "更新内容",
  resultingTitle = "更新内容反映結果",
  ...props
}: SpotEditorProps) => {
  useEffect(() => {
    console.log("mounted");
  }, []);
  const [name, setName] = useState<string | undefined>();
  const [phoneticName, setPhoneticName] = useState<string | undefined>();
  const [lat, setLat] = useState<number | undefined>();
  const [lon, setLon] = useState<number | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [address, setAddress] = useState<string | undefined>();
  const [addressPref, setAddressPref] = useState<string | undefined>();
  const [addressCity, setAddressCity] = useState<string | undefined>();
  const [addressPostalCode, setAddressPostalCode] = useState<
    string | undefined
  >();
  const [xlinks, setXlinks] = useState<string[] | undefined>();
  const [socialLinks, setSocialLinks] = useState<string[] | undefined>();
  const [access, setAccess] = useState<string | undefined>();
  const [openingHours, setOpeningHours] = useState<string | undefined>();
  const [holiday, setHoliday] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [businessStatus, setBusinessStatus] = useState<SpotBusinessStatus | undefined>();
  const [parking, setParking] = useState<string | undefined>();
  const [googlePlaceId, setGooglePlaceId] = useState<string | undefined>();
  const [googleCid, setGoogleCid] = useState<string | undefined>();

  const [genre, setGenre] = useState<string | undefined>();
  const [subgenre, setSubgenre] = useState<string | undefined>();
  const [mood_categories, setMoodCategories] = useState<
    MoodCategory[] | undefined
  >();

  const resultingData: SpotItemRevisionCreate = useMemo(() => {
    return {
      ...baseData,
      spot_id: spotId,
      name: name,
      phonetic_name: phoneticName,
      location: lat && lon ? { lat, lon } : undefined,
      description: description,
      address: address,
      address_component: {
        pref: addressPref ?? null,
        city: addressCity ?? null,
        postal_code: addressPostalCode ?? null,
      },
      genre: genre,
      subgenre: subgenre,
      mood_categories: mood_categories,
      xlinks: xlinks,
      social_links: socialLinks,
      access: access,
      opening_hours: openingHours,
      holiday: holiday,
      phone_number: phoneNumber,
      business_status: businessStatus,
      parking: parking,
      google_place_id: googlePlaceId,
      google_cid: googleCid,
    };
  }, [
    baseData,
    name,
    phoneticName,
    lat,
    lon,
    description,
    address,
    addressPref,
    addressCity,
    addressPostalCode,
    genre,
    subgenre,
    mood_categories,
    xlinks,
    socialLinks,
    access,
    openingHours,
    holiday,
    phoneNumber,
    businessStatus,
    parking,
    googlePlaceId,
  ]);

  const resultingDataDiff = useMemo<SpotItemRevisionCreate | undefined>(() => {
    if (!setResultingValueDiff) {
      return undefined;
    }
    const res = {
      spot_id: spotId,
      name:
        baseData?.name !== resultingData?.name ? resultingData?.name : "nodiff",
      phonetic_name:
        baseData?.phonetic_name !== resultingData?.phonetic_name
          ? resultingData?.phonetic_name
          : "nodiff",
      location:
        baseData?.location?.lat !== resultingData?.location?.lat &&
        baseData?.location?.lon !== resultingData?.location?.lon
          ? resultingData?.location
          : "nodiff",
      description:
        baseData?.description !== resultingData?.description
          ? resultingData?.description
          : "nodiff",
      address:
        baseData?.address !== resultingData?.address
          ? resultingData?.address
          : "nodiff",
      address_component:
        sortedJsonStringify(baseData?.address_component ?? {}) !==
        sortedJsonStringify(resultingData?.address_component ?? {})
          ? {
              pref:
                baseData?.address_component?.pref !==
                resultingData?.address_component?.pref
                  ? resultingData?.address_component?.pref
                  : undefined,
              city:
                baseData?.address_component?.city !==
                resultingData?.address_component?.city
                  ? resultingData?.address_component?.city
                  : undefined,
              postal_code:
                baseData?.address_component?.postal_code !==
                resultingData?.address_component?.postal_code
                  ? resultingData?.address_component?.postal_code
                  : undefined,
            }
          : "nodiff",
      genre:
        baseData?.genre !== resultingData?.genre
          ? resultingData?.genre
          : "nodiff",
      subgenre:
        baseData?.subgenre !== resultingData?.subgenre
          ? resultingData?.subgenre
          : "nodiff",
      mood_categories:
        baseData?.mood_categories !== resultingData?.mood_categories
          ? resultingData?.mood_categories
          : "nodiff",
      xlinks:
        baseData?.xlinks !== resultingData?.xlinks
          ? resultingData?.xlinks
          : "nodiff",
      social_links:
        baseData?.social_links !== resultingData?.social_links
          ? resultingData?.social_links
          : "nodiff",
      access:
        baseData?.access !== resultingData?.access
          ? resultingData?.access
          : "nodiff",
      opening_hours:
        baseData?.opening_hours !== resultingData?.opening_hours
          ? resultingData?.opening_hours
          : "nodiff",
      holiday:
        baseData?.holiday !== resultingData?.holiday
          ? resultingData?.holiday
          : "nodiff",
      phone_number:
        baseData?.phone_number !== resultingData?.phone_number
          ? resultingData?.phone_number
          : "nodiff",
      business_status:
        baseData?.business_status !== resultingData?.business_status
          ? resultingData?.business_status
          : "nodiff",
      parking:
        baseData?.parking !== resultingData?.parking
          ? resultingData?.parking
          : "nodiff",
      google_place_id:
        baseData?.google_place_id !== resultingData?.google_place_id
          ? resultingData?.google_place_id
          : "nodiff",
      google_cid:
        baseData?.google_cid !== resultingData?.google_cid
          ? resultingData?.google_cid
          : "nodiff",
    };
    return removeNodiffProps(res);
  }, [resultingData, baseData, spotId, setResultingValueDiff]);

  useEffect(() => {
    setResultingValueDiff &&
      setResultingValueDiff(resultingDataDiff ?? undefined);
  }, [resultingDataDiff, setResultingValueDiff]);

  useEffect(() => {
    setResultingValue && setResultingValue(resultingData ?? undefined);
  }, [resultingData, setResultingValue]);

  return (
    <Table
      size="small"
      stickyHeader
      sx={{
        "* td, * th": { p: (theme) => theme.spacing(0.25) },
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{ minWidth: 200 }}>
            項目名
          </TableCell>
          <TableCell colSpan={2}>{baseTitle}</TableCell>
          <TableCell colSpan={2}>{comparingTitle}</TableCell>
          <TableCell colSpan={2}>{resultingTitle}</TableCell>
          <TableCell sx={{ minWidth: 50 }}>差分</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <SpotEditorTableRow
          name="name"
          title="スポット名"
          editable={editable}
          baseValue={baseData?.name}
          comparingValue={comparingData?.name}
          setResultingValue={setName}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="phonetic_name"
          title="ふりがな"
          editable={editable}
          baseValue={baseData?.phonetic_name}
          comparingValue={comparingData?.phonetic_name}
          setResultingValue={setPhoneticName}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="location"
          title="位置情報"
          subtitle="緯度"
          editable={editable}
          baseValue={baseData?.location?.lat}
          comparingValue={comparingData?.location?.lat}
          setResultingValue={setLat}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="location"
          title="位置情報"
          subtitle="経度"
          editable={editable}
          baseValue={baseData?.location?.lon}
          comparingValue={comparingData?.location?.lon}
          setResultingValue={setLon}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="genre"
          title="ジャンル"
          subtitle="NICメイン"
          editable={editable}
          baseValue={baseData?.genre}
          comparingValue={comparingData?.genre}
          setResultingValue={setGenre}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="subgenre"
          title="ジャンル"
          subtitle="NICサブ"
          editable={editable}
          baseValue={baseData?.subgenre}
          comparingValue={comparingData?.subgenre}
          setResultingValue={setSubgenre}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="mood_categories"
          title="目的ジャンル"
          editable={editable}
          baseValue={baseData?.mood_categories}
          comparingValue={comparingData?.mood_categories}
          setResultingValue={setMoodCategories}
          inputType="multiSelect"
          selectableItems={Object.values(MoodCategory)}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="description"
          title="説明"
          editable={editable}
          baseValue={baseData?.description}
          comparingValue={comparingData?.description}
          setResultingValue={setDescription}
          validationErrorItems={props.validationErrorItems ?? []}
        />

        <SpotEditorTableRow
          name="address"
          title="住所"
          editable={editable}
          subtitle="住所（全部）"
          baseValue={baseData?.address}
          comparingValue={comparingData?.address}
          setResultingValue={setAddress}
          validationErrorItems={props.validationErrorItems ?? []}
        />

        <SpotEditorTableRow
          name="address_component"
          title="住所"
          editable={editable}
          subtitle="都道府県"
          baseValue={baseData?.address_component?.pref}
          comparingValue={comparingData?.address_component?.pref}
          setResultingValue={setAddressPref}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="address_component"
          title="住所"
          editable={editable}
          subtitle="市町区村"
          baseValue={baseData?.address_component?.city}
          comparingValue={comparingData?.address_component?.city}
          setResultingValue={setAddressCity}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="address_component.postal_code"
          title="住所"
          editable={editable}
          subtitle="郵便番号"
          baseValue={baseData?.address_component?.postal_code}
          comparingValue={comparingData?.address_component?.postal_code}
          setResultingValue={setAddressPostalCode}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="xlinks"
          title="外部リンク"
          inputType="valueArray"
          editable={editable}
          baseValue={baseData?.xlinks}
          comparingValue={comparingData?.xlinks}
          setResultingValue={setXlinks}
          longText
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="social_links"
          title="SNS"
          inputType="valueArray"
          editable={editable}
          baseValue={baseData?.social_links}
          comparingValue={comparingData?.social_links}
          setResultingValue={setSocialLinks}
          longText
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="access"
          title="アクセス"
          editable={editable}
          baseValue={baseData?.access}
          comparingValue={comparingData?.access}
          setResultingValue={setAccess}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="opening_hours"
          title="営業時間"
          editable={editable}
          baseValue={baseData?.opening_hours}
          comparingValue={comparingData?.opening_hours}
          setResultingValue={setOpeningHours}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="holiday"
          title="定休日"
          editable={editable}
          baseValue={baseData?.holiday}
          comparingValue={comparingData?.holiday}
          setResultingValue={setHoliday}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="phone_number"
          title="電話番号"
          editable={editable}
          baseValue={baseData?.phone_number}
          comparingValue={comparingData?.phone_number}
          setResultingValue={setPhoneNumber}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="business_status"
          title="営業状態"
          editable={editable}
          baseValue={baseData?.business_status}
          comparingValue={comparingData?.business_status}
          setResultingValue={setBusinessStatus}
          inputType="select"
          selectableItems={Object.values(SpotBusinessStatus)}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="parking"
          title="駐車場"
          editable={editable}
          baseValue={baseData?.parking}
          comparingValue={comparingData?.parking}
          setResultingValue={setParking}
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="google_place_id"
          title="Google PlaceID"
          editable={editable}
          baseValue={baseData?.google_place_id}
          comparingValue={comparingData?.google_place_id}
          setResultingValue={setGooglePlaceId}
          longText
          validationErrorItems={props.validationErrorItems ?? []}
        />
        <SpotEditorTableRow
          name="google_cid"
          title="Google CID"
          editable={editable}
          baseValue={baseData?.google_cid}
          comparingValue={comparingData?.google_cid}
          setResultingValue={setGoogleCid}
          longText
          validationErrorItems={props.validationErrorItems ?? []}
        />
      </TableBody>
    </Table>
  );
};
