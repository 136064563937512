import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  Radio,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";

import { Add, Clear, History } from "@mui/icons-material";
import { Dispatch, DispatchWithoutAction, useEffect, useState } from "react";
import { useNavigate, useParams, useBlocker } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";
import { useAdminFetcherWithSwr, useAdminSender } from "../api";
import {
  NotificationItem,
  NotificationItemCreate,
  NotificationStatus,
} from "../generatedApi/notificationManagerApi";
import { getJstFromUnixtime } from "../utils";
import Template from "./Template";
import { Auth } from "aws-amplify";
import * as React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAtom, useSetAtom } from "jotai/react";
import { editingNotificationItemAtom } from "../atom";
import { RESET } from "jotai/utils";

const NotificationEditCaption = {
  notification_type: "お知らせの種類",
  sent_to: "送信先",
  send_from: "送信者",
  title: "タイトル",
  message: "本文",
};
const NotificationEditPlaceholderText = {
  notification_type: "お知らせの種類を入力してください",
  sent_to: "お知らせの受信者のユーザーIDを入力してください",
  send_from: "送信者の名前を入力してください",
  title: "お知らせのタイトルを入力してください",
  message: "お知らせの本文を入力してください",
};
const NotificationStatusJapanese = {
  draft: "下書き",
  ready: "送信待ち",
  fetched: "送信中",
  sent: "送信完了",
};

type InputBaseButtonProps = {
  notification: NotificationItem;
  notificationItemKey:
  | "notification_type"
  | "sent_to"
  | "send_from"
  | "title"
  | "message";
  notificationUpdateItem: NotificationItem;
  setNotificationUpdateItem: Dispatch<React.SetStateAction<NotificationItem | undefined>>;
};
const InputBaseButton = (props: InputBaseButtonProps) => {
  const {
    notification,
    notificationItemKey,
    notificationUpdateItem,
    setNotificationUpdateItem,
  } = props;

  return (
    <>
      <Stack p={1}>
        <Typography variant="caption" fontWeight="bold">
          {NotificationEditCaption[notificationItemKey]}
        </Typography>
        <InputBase
          placeholder={NotificationEditPlaceholderText[notificationItemKey]}
          onChange={(e) => {
            setNotificationUpdateItem({
              ...notificationUpdateItem,
              [notificationItemKey]: e.target.value,
            });
          }}
          value={notificationUpdateItem[notificationItemKey]}
          sx={
            notificationItemKey === "message"
              ? { pl: 2, minHeight: 80 }
              : { pl: 2 }
          }
          multiline={notificationItemKey === "message"}
          disabled={notification.status !== NotificationStatus.DRAFT}
          endAdornment={
            <>
              {notificationItemKey === "sent_to" && (
                <>
                  <Tooltip title={"送信先を全てのユーザーにします"}>
                    <div>
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (
                            window.confirm(
                              "本当に全てのユーザーに送信しますか？"
                            ) === false
                          )
                            return;
                          setNotificationUpdateItem({
                            ...notificationUpdateItem,
                            sent_to: "ALL_USERS",
                          });
                        }}
                        sx={{ width: 120 }}
                        disabled={
                          notification.status !== NotificationStatus.DRAFT
                        }
                      >
                        <Typography variant="caption">
                          全てのユーザー
                        </Typography>
                      </IconButton>
                    </div>
                  </Tooltip>
                </>
              )}
              <Tooltip title={"変更前の内容に戻します"}>
                <div>
                  <IconButton
                    onClick={() => {
                      if (
                        window.confirm("本当に更新前に戻しますか？") === false
                      )
                        return;
                      setNotificationUpdateItem({
                        ...notificationUpdateItem,
                        [notificationItemKey]:
                          notification[notificationItemKey],
                      });
                    }}
                    disabled={notification.status !== NotificationStatus.DRAFT}
                  >
                    <History />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip
                title={`"${NotificationEditCaption[notificationItemKey]}" の内容を削除します。`}
                aria-multiline
              >
                <div>
                  <IconButton
                    onClick={() => {
                      if (window.confirm("本当に削除しますか？") === false)
                        return;
                      setNotificationUpdateItem({
                        ...notificationUpdateItem,
                        [notificationItemKey]: "",
                      });
                    }}
                    disabled={notification.status !== NotificationStatus.DRAFT}
                  >
                    <Clear />
                  </IconButton>
                </div>
              </Tooltip>
            </>
          }
        />
      </Stack>
    </>
  );
};

type NotificationItemTabProps = {
  notification: NotificationItem;
  notificationUpdateItem: NotificationItem;
  setNotificationUpdateItem: Dispatch<React.SetStateAction<NotificationItem | undefined>>;
};
const NotificationEditTab = (props: NotificationItemTabProps) => {
  const { notification, notificationUpdateItem, setNotificationUpdateItem } =
    props;

  const editItems: [
    "notification_type",
    "sent_to",
    "send_from",
    "title",
    "message"
  ] = ["notification_type", "sent_to", "send_from", "title", "message"];
  return (
    <>
      <Stack>
        <Typography variant="body1" fontWeight="bold">
          お知らせ編集
        </Typography>
        <Stack
          border={(theme) => `1px solid ${theme.palette.divider}`}
          divider={<Divider />}
        >
          {editItems.map((item, key) => (
            <InputBaseButton
              key={key}
              notification={notification}
              notificationItemKey={item}
              notificationUpdateItem={notificationUpdateItem}
              setNotificationUpdateItem={setNotificationUpdateItem}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};

type NotificationDetailPanelProps = {
  notificationId: string;
  reloadNotifications?: DispatchWithoutAction;
  setIsEditing: Dispatch<React.SetStateAction<boolean>>;
};
const NotificationDetailPanel = (props: NotificationDetailPanelProps) => {
  const { notificationId } = props;
  const {
    data: notification,
    isLoading: isNotificationLoading,
    mutate: reloadNotification,
    error: notificationError,
  } = useAdminFetcherWithSwr({
    apiName: "notification_manager",
    serviceName: "default",
    operationId: "getNotificationItem",
    requestParameters: { notificationId: notificationId },
  });

  const {
    sender: deleteAdminNotificationItem,
    isLoading: isNotificationDeleting,
  } = useAdminSender({
    apiName: "notification_manager",
    serviceName: "admin",
    operationId: "deleteAdminNotificationItem",
  });
  const deleteNotification = async (notificationId: string) => {
    if (window.confirm("本当に削除しますか？") === false) return;
    await deleteAdminNotificationItem({
      notificationId: notificationId,
    });
    await reloadNotification();
    props.reloadNotifications && props.reloadNotifications();
  };

  const {
    sender: updateAdminNotificationItemStatus,
    isLoading: isNotificationItemStatusUpdating,
  } = useAdminSender({
    apiName: "notification_manager",
    serviceName: "admin",
    operationId: "updateAdminNotificationItemStatus",
  });
  const updateAdminNotificationStatus = async (
    notificationId: string,
    notificationStatus: NotificationStatus
  ) => {
    await updateAdminNotificationItemStatus({
      notificationId: notificationId,
      status: notificationStatus,
    });
    await reloadNotification();
    props.reloadNotifications && props.reloadNotifications();
  };

  const [editingNotificationItem, setEditingNotificationItem] = useAtom(editingNotificationItemAtom)

  const [notificationUpdateItem, setNotificationUpdateItem] =
    useState<NotificationItem | undefined>(() => {
      if (editingNotificationItem?.notification_id === notificationId) {
        return editingNotificationItem;
      }
    }
    );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  useEffect(() => {
    setNotificationUpdateItem(() => {
      if (editingNotificationItem?.notification_id === notificationId) {
        return editingNotificationItem;
      }
      return notification;
    }
    );
    setIsEditing(false);
    props.setIsEditing(false);
  }, [notification]);
  useEffect(() => {
    if (!notificationUpdateItem) return;
    if (
      notificationUpdateItem.notification_type ===
      notification?.notification_type &&
      notificationUpdateItem.sent_to === notification?.sent_to &&
      notificationUpdateItem.send_from === notification?.send_from &&
      notificationUpdateItem.title === notification?.title &&
      notificationUpdateItem.message === notification?.message
    ) {
      setIsEditing(false);
      props.setIsEditing(false);
      setEditingNotificationItem(notificationUpdateItem);
      return;
    }
    setIsEditing(true);
    props.setIsEditing(true);
    setEditingNotificationItem(notificationUpdateItem);
  }, [notificationUpdateItem]);
  const {
    sender: updateNotificationItem,
    isLoading: isNotificationItemUpdating,
  } = useAdminSender({
    apiName: "notification_manager",
    serviceName: "admin",
    operationId: "updateNotificationItem",
  });
  const updateNotification = async (
    notificationId: string,
    notificationItem: NotificationItemCreate
  ) => {
    await updateNotificationItem({
      notificationId: notificationId,
      requestBody: notificationItem,
    });
    await reloadNotification();
    props.reloadNotifications && props.reloadNotifications();
  };

  return (
    <>
      {notificationError}
      {isNotificationLoading && <CircularProgress sx={{ m: 1 }} />}
      {!isNotificationLoading && !notification && (
        <Box p={1}>
          お知らせは削除されています。(お知らせID: {props.notificationId})
        </Box>
      )}
      {notification && (
        <Stack>
          <Stack
            bgcolor={(theme) => theme.palette.grey[100]}
            p={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography fontWeight="bold">
              {notification.title}
              {notification.title.length < 1 && "タイトル未設定"}
            </Typography>
          </Stack>
          <Stack divider={<Divider />}>
            <Stack p={1}>
              <Typography variant="body1" fontWeight="bold">
                お知らせ詳細
              </Typography>
              <Box>
                <Chip label="お知らせ ID" size="small" />
                <Typography pl={1} pt={0.5}>
                  {props.notificationId}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="お知らせの種類" size="small" />
                <Typography pl={1} pt={0.5}>
                  {notification.notification_type}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="送信先" size="small" />
                <Typography pl={1} pt={0.5}>
                  {notification.sent_to}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="送信者" size="small" />
                <Typography pl={1} pt={0.5}>
                  {notification.send_from}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="タイトル" size="small" />
                <Typography pl={1} pt={0.5}>
                  {notification.title}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="本文" size="small" />
                <Typography pl={1} pt={0.5}>
                  {notification.message}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="ステータス" size="small" />
                <Typography pl={1} pt={0.5}>
                  {NotificationStatusJapanese[notification.status]}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="作成日時" size="small" />
                <Typography pl={1} pt={0.5}>
                  {getJstFromUnixtime(notification.timestamp_created / 1000)}
                </Typography>
              </Box>
              <Box pt={1}>
                <Chip label="更新日時" size="small" />
                <Typography pl={1} pt={0.5}>
                  {getJstFromUnixtime(notification.timestamp_updated / 1000)}
                </Typography>
              </Box>
            </Stack>
            <Stack p={1}>
              {notificationUpdateItem && (
                <NotificationEditTab
                  notification={notification}
                  notificationUpdateItem={notificationUpdateItem}
                  setNotificationUpdateItem={setNotificationUpdateItem}
                />
              )}
            </Stack>
            <Stack
              p={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Stack direction="row" spacing={1}>
                <Tooltip
                  title={
                    notification.status === NotificationStatus.DRAFT
                      ? !isEditing
                        ? "変更箇所がありません"
                        : ""
                      : "送信済の状態では更新できません"
                  }
                >
                  <div>
                    <Button
                      onClick={() =>
                        updateNotification(
                          notification.notification_id,
                          notificationUpdateItem ?? notification
                        )
                      }
                      variant="contained"
                      size="small"
                      disabled={
                        notification.status !== NotificationStatus.DRAFT ||
                        isNotificationItemUpdating ||
                        !isEditing
                      }
                    >
                      下書きを更新
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    notification.status === NotificationStatus.DRAFT
                      ? isEditing
                        ? "編集内容がありますので、下書きを更新してください"
                        : ""
                      : "送信済です"
                  }
                >
                  <div>
                    <Button
                      onClick={() =>
                        updateAdminNotificationStatus(
                          notification.notification_id,
                          NotificationStatus.READY
                        )
                      }
                      variant="contained"
                      size="small"
                      disabled={
                        notification.status !== NotificationStatus.DRAFT ||
                        isNotificationItemStatusUpdating ||
                        isEditing
                      }
                    >
                      送信
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    notification.status === NotificationStatus.READY
                      ? ""
                      : "送信を取り消すにはREADY状態でなければなりません"
                  }
                >
                  <div>
                    <Button
                      onClick={() =>
                        updateAdminNotificationStatus(
                          notification.notification_id,
                          NotificationStatus.DRAFT
                        )
                      }
                      variant="contained"
                      size="small"
                      disabled={
                        notification.status !== NotificationStatus.READY ||
                        isNotificationItemStatusUpdating
                      }
                    >
                      送信取り消し
                    </Button>
                  </div>
                </Tooltip>
              </Stack>
              <Box>
                <Button
                  onClick={() =>
                    deleteNotification(notification.notification_id)
                  }
                  variant="contained"
                  disabled={isNotificationDeleting}
                  size="small"
                  color="error"
                >
                  削除
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

const NotificationManagerPage = () => {
  const { notificationId } = useParams();
  const [email, setEmail] = React.useState<string | undefined>();
  const { user } = useAuthenticator();
  const getEmail = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const { email } = currentUser.attributes;
      setEmail(email);
    } catch (error) {
      console.error("Error getting user email:", error);
    }
  };

  React.useEffect(() => {
    getEmail();
  }, [user]);

  const { sender: createNotification, isLoading: isNotificationCreating } =
    useAdminSender({
      apiName: "notification_manager",
      serviceName: "admin",
      operationId: "createAdminNotificationItem",
    });

  const createEmptyNotification = async () => {
    const newNotificationId = uuidv4();
    await createNotification({
      requestBody: {
        title: "",
        message: "",
        // get uuid as notification_id
        notification_id: newNotificationId,
        notification_type: "",
        send_from: email ?? "",
        sent_to: "",
        status: NotificationStatus.DRAFT,
      },
    });
    await reloadNotifications();
    await navigate(`/notifications/${newNotificationId}`);
  };

  const [queryStatus, setQueryStatus] = useState<NotificationStatus>(
    NotificationStatus.DRAFT
  );

  const debouncedQueryStatus = useDebounce(queryStatus, 1000);

  const {
    data: notifications,
    isLoading: isNotificationLoading,
    mutate: reloadNotifications,
  } = useAdminFetcherWithSwr({
    apiName: "notification_manager",
    serviceName: "default",
    operationId: "getNotificationItems",
    requestParameters: { status: debouncedQueryStatus },
  });

  const { sender: getNotificationItems } = useAdminSender({
    apiName: "notification_manager",
    serviceName: "default",
    operationId: "getNotificationItems",
  });
  const getMoreNotificationsItems = async () => {
    if (!notifications?.last_evaluated_key) return;
    const res = await getNotificationItems({
      status: debouncedQueryStatus,
      lastEvaluatedKey: notifications.last_evaluated_key,
    });
    if (!res) return;
    notifications.items.push(...res.items);
    notifications.last_evaluated_key = res.last_evaluated_key;
    reloadNotifications();
  };
  const navigate = useNavigate();

  const setEditingNotificationItem = useSetAtom(editingNotificationItemAtom)

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      setEditingNotificationItem(RESET);
    };

    if (!isEditing) return;
    window.addEventListener("beforeunload", handleBeforeUnloadEvent, true);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnloadEvent, true);
  }, [isEditing]);

  const blocker = useBlocker(isEditing);
  useEffect(() => {
    if (isEditing && blocker.state === "blocked") {
      if (
        window.confirm("編集中ですが、移動しますか？\n移動すると編集中のデータは破棄されます。") === false
      ) return;
    }
    blocker.proceed?.();
    setEditingNotificationItem(RESET);
  }, [blocker]);

  return (
    <Template title="お知らせ管理">
      <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs value={"notifications"} textColor="inherit">
          <Tab label="お知らせ一覧" onClick={() => { }} value="notifications" />
        </Tabs>
      </AppBar>
      <Box component="main" sx={{ flex: 1, px: 2, bgcolor: "#eaeff1" }}>
        <Stack height="100%" direction="row" spacing={2} pt={2}>
          <Stack width={400} spacing={1}>
            <Stack border={(theme) => `1px solid ${theme.palette.divider}`}>
              <Stack
                p={1}
                bgcolor={(theme) => theme.palette.grey[100]}
                spacing={1}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>お知らせ一覧</Typography>
                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    startIcon={<Add />}
                    onClick={() => createEmptyNotification()}
                    disabled={isNotificationCreating}
                  >
                    新規
                  </Button>
                </Stack>
                <Select
                  labelId="filter-label"
                  label="フィルター"
                  variant="filled"
                  value={queryStatus === null ? "" : queryStatus}
                  size="small"
                  onChange={(e) => setQueryStatus(e.target.value as any)}
                  fullWidth
                >
                  {Object.values(NotificationStatus).map((status, key) => (
                    <MenuItem value={status} key={key}>
                      {NotificationStatusJapanese[status]}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack
                bgcolor={(theme) => theme.palette.background.default}
                divider={<Divider />}
              >
                {isNotificationLoading && <CircularProgress />}
                {notifications?.items.length === 0 && (
                  <Box p={1}>お知らせはありません</Box>
                )}
                {notifications?.items.map((notification, nkey) => (
                  <Stack
                    onClick={() => {
                      navigate(
                        `/notifications/${notification.notification_id}`
                      );
                    }}
                    key={nkey}
                    p={1}
                    sx={{ cursor: "pointer" }}
                    direction="row"
                  >
                    <Box>
                      <Radio
                        checked={
                          notificationId === notification.notification_id
                        }
                      />
                    </Box>
                    <Stack>
                      <Typography fontWeight="bold">
                        {notification.title.length > 0
                          ? notification.title
                          : "タイトル未設定"}
                      </Typography>
                      <Box>
                        <Chip
                          label={
                            NotificationStatusJapanese[notification.status]
                          }
                          size="small"
                        />
                      </Box>
                      <Typography>
                        {getJstFromUnixtime(
                          notification.timestamp_created / 1000
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
                {notifications?.last_evaluated_key && (
                  <Button onClick={() => getMoreNotificationsItems()}>
                    続きを読み込む
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack width="100%">
            <Stack
              border={(theme) => `1px solid ${theme.palette.divider}`}
              bgcolor={(theme) => theme.palette.background.default}
            >
              {!notificationId && "お知らせを選択してください"}
              {notificationId && (
                <NotificationDetailPanel
                  notificationId={notificationId}
                  reloadNotifications={reloadNotifications}
                  setIsEditing={setIsEditing}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Template>
  );
};

export default NotificationManagerPage;
