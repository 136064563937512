/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RequestStatus {
    DRAFT = 'draft',
    OPENED = 'opened',
    CLOSED = 'closed',
    MERGED = 'merged',
}
